import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import WidgetsIcon from "@mui/icons-material/WidgetsOutlined";
import Link from "next/link";
import { useEffect, useState } from "react";
import { List } from "@mui/material";
import { useRouter } from "next/router";
import Avatar from "@mui/material/Avatar";

import { useProducts } from "contexts/products";
import { getProductCompletionRate, ProgressIcon } from "utils/product";
import { ProgressIcon as CaseProgressIcon } from "utils/case";
import { useProject } from "contexts/project";
import { Case, Product } from "API";

interface ProductProps {
  toggleDrawer: () => void;
}

interface CaseProps {
  product: Product;
  toggleDrawer: () => void;
}

const CaseItems = ({ toggleDrawer, product }: CaseProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState("project");
  const { project } = useProject();
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (!!query.case) {
      setCurrentPage(`${query.productId}-${query.case}`);
      if (query.productId === product?.id) setOpen(true);
    } else if (!!query.productId) {
      setCurrentPage(query.productId as string);
    } else {
      setCurrentPage("");
    }
  }, [query, product]);

  if (!project || !product) return null;

  const handleClick = () => {
    toggleDrawer();
    router.push(
      `/projects/${project.id}?page=products&productId=${product.id}`
    );
  };

  return (
    <>
      <div className="flex justify-between">
        <ListItemButton
          sx={{ pl: 4 }}
          selected={currentPage === product.id}
          onClick={handleClick}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                width: 30,
                height: 30,
                fontSize: 16,
                bgcolor: product.readyToPack ? "transparent" : "gray",
              }}
              aria-label="status"
            >
              {product.readyToPack ? (
                <ProgressIcon
                  progress={getProductCompletionRate(product) * 100}
                  size={30}
                />
              ) : (
                "仮"
              )}
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={product.name}
            secondary={product.packageTypeName}
          />
        </ListItemButton>
        {product.readyToPack && (
          <div className="cursor-pointer flex items-center mr-4">
            {open ? (
              <ExpandLess onClick={() => setOpen(false)} />
            ) : (
              <ExpandMore onClick={() => setOpen(true)} />
            )}
          </div>
        )}
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {product.cases &&
          product.cases.length > 0 &&
          product.cases.map((cs) => (
            <Link
              key={`product_${product.id}_case_${cs?.order}`}
              href={`/projects/${project.id}?page=products&productId=${product.id}&case=${cs?.order}`}
              prefetch={false}
            >
              <ListItemButton
                sx={{ pl: 8 }}
                selected={currentPage === `${product.id}-${cs?.order}`}
              >
                <ListItemIcon>
                  <CaseProgressIcon cs={cs as Case} className="w-14" />
                </ListItemIcon>
                <ListItemText primary={`${product.name}-${cs?.order}`} />
              </ListItemButton>
            </Link>
          ))}
      </Collapse>
    </>
  );
};

const ProductItems = ({ toggleDrawer }: ProductProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState("project");
  const { products, loading } = useProducts();
  const { project } = useProject();
  const router = useRouter();

  const { query } = router;

  useEffect(() => {
    if (!query.productId && !query.case) {
      setCurrentPage(query.page as string);
    } else if (!!query.productId && query.page === "products") {
      setCurrentPage("");
      setOpen(true);
    } else if (!!query.productId && !!query) {
      setCurrentPage("");
      setOpen(true);
    } else {
      setCurrentPage("");
    }
  }, [query]);

  if (!project || !products) return null;

  const handleClick = () => {
    toggleDrawer();
    router.push(`/projects/${project.id}?page=products`);
  };

  return (
    <>
      <div className="flex justify-between">
        <ListItemButton
          onClick={handleClick}
          selected={currentPage === "products"}
        >
          <ListItemIcon>
            <WidgetsIcon />
          </ListItemIcon>
          <ListItemText primary="梱包" />
        </ListItemButton>
        <div className="cursor-pointer flex items-center mr-4">
          {open ? (
            <ExpandLess onClick={() => setOpen(false)} />
          ) : (
            <ExpandMore onClick={() => setOpen(true)} />
          )}
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {loading && products.length === 0 && <LinearProgress />}
          {products.length > 0 &&
            products.map((product) => (
              <CaseItems
                key={product.id}
                product={product}
                toggleDrawer={toggleDrawer}
              />
            ))}
        </List>
      </Collapse>
    </>
  );
};

export default ProductItems;
