import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout"; // 追加

import { useExternalShareInfo } from "contexts/externalShareInfo";

export function ExternalProjectAppBar() {
  const { project } = useExternalShareInfo();
  const { logout } = useExternalShareInfo();

  return (
    <>
      <AppBar>
        <Toolbar variant="dense" className="flex justify-between">
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className="flex-grow hidden sm:block"
          >
            {project?.name}
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => logout(project?.id ?? "")}
            className="ml-auto"
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}
