import { useState } from "react";
import { useRouter } from "next/router";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountIcon from "@mui/icons-material/AccountCircle";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SettingIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";

import { useAuth } from "contexts/auth";
import QrScanner from "components/QrScanner";

interface AppBarProps {
  open?: boolean;
  toggleDrawer: () => void;
}

export function HomeAppBar({ toggleDrawer }: AppBarProps) {
  const { currentGroup, user } = useAuth();
  const [showQrScan, setShowQr] = useState(false);
  const router = useRouter();
  const { permissions } = useAuth();

  if (!currentGroup)
    return (
      <div className="h-screen flex justify-center items-center">
        グループに所属されていません
      </div>
    );

  return (
    <>
      <QrScanner open={showQrScan} setOpen={setShowQr} />
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "1rem",
            }}
          >
            <AccountIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {currentGroup.name}
          </Typography>
          {permissions.GroupInfo.canRead && (
            <Tooltip title="事業所設定">
              <IconButton
                aria-label="Group Setting"
                color="inherit"
                onClick={() => router.push(`/group/settings`)}
              >
                <SettingIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="QRコードスキャン">
            <IconButton
              aria-label="scan QR code"
              color="inherit"
              onClick={() => setShowQr(true)}
            >
              <QrCodeScannerIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </>
  );
}
