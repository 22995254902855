import type { ElementInput } from "./types";

import { Element, Product } from "API";

interface ComponentElementsForCalculation {
  [id: string]: ElementsForCalculation;
}

export interface ElementsForCalculation {
  [slug: string]: Element;
}

export const extractProductElements = (p: ElementInput | undefined) => {
  if (!p) return {};
  return p.elements?.reduce((r: ElementsForCalculation, c) => {
    r[c.slug] = c;
    return r;
  }, {});
};

export const extractComponentsElements = (
  cps: ElementInput[] | undefined | Product[]
) => {
  if (!cps) return {};
  const results: ComponentElementsForCalculation = {};
  cps?.forEach((component) => {
    const elements = component.elements as Element[];
    results[component.id] = elements.reduce((r: ElementsForCalculation, c) => {
      r[c.slug] = c;
      return r;
    }, {});
  });
  return results;
};

export const extractDecorationsElements = (
  deco: ElementInput[] | undefined
) => {
  if (!deco) return {};
  const results: ComponentElementsForCalculation = {};
  deco?.forEach((d) => {
    const elements = d.elements as Element[];
    results[d.id] = elements.reduce((r: ElementsForCalculation, c) => {
      r[c.slug] = c;
      return r;
    }, {});
  });
  return results;
};

export const extractElementValue = (elements: any, targetSlug: string) => {
  if (elements === null) return {};
  return elements.reduce((result: any, e: Element) => {
    let slug = e.slug;
    if (slug.includes(".")) {
      slug = slug.split(".")[1];
    }

    if (slug === targetSlug) result = parseFloat(e.value || "0");
    return result;
  }, 0);
};
