import shortUUID, { uuid } from "short-uuid";
import { API } from "aws-amplify";

import { createEmptyElements } from "./bom/elements";

import {
  createBaseArea,
  createBaseComponent,
  createBaseDecorationComponent,
  deleteBaseDecorationComponent,
  deleteBaseArea as deleteBaseAreaG,
  deleteBaseComponent as deleteBaseComponentG,
} from "graphql/mutations";
import { BaseComponent, PackageType } from "API";
import { baseComponentsByBaseAreaId, getPackageType } from "graphql/queries";

// 指定した梱包仕様にComponent(部材)を追加する
export async function addBaseComponent(
  target: "component" | "interior" | "exterior",
  order: number,
  areaId?: string,
  packageTypeId?: string
) {
  const slug = shortUUID().generate().toString();
  const componentId = uuid();
  if (target === "component") {
    if (!areaId) return;
    const input = {
      id: componentId,
      baseAreaId: areaId,
      name: "新部材",
      slug,
      order,
      elements: createEmptyElements("component", slug),
    };
    await API.graphql({
      query: createBaseComponent,
      variables: { input: input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  }
  if (target === "exterior") {
    if (!packageTypeId) return;
    const input = {
      id: componentId,
      packageTypeId,
      name: "新外装",
      type: "EXTERIOR",
      slug,
      order,
      elements: createEmptyElements("decoration", slug),
    };
    await API.graphql({
      query: createBaseDecorationComponent,
      variables: { input: input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  }
  if (target === "interior") {
    if (!packageTypeId) return;
    const input = {
      id: componentId,
      packageTypeId,
      name: "新内装",
      type: "INTERIOR",
      slug,
      order,
      elements: createEmptyElements("decoration", slug),
    };
    await API.graphql({
      query: createBaseDecorationComponent,
      variables: { input: input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  }
}

export async function deleteBaseComponent(
  target: "component" | "decoration",
  id: string
) {
  if (target === "component") {
    await API.graphql({
      query: deleteBaseComponentG,
      variables: {
        input: {
          id,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  } else {
    await API.graphql({
      query: deleteBaseDecorationComponent,
      variables: {
        input: {
          id,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  }
}

// 指定したareaId以下のcomponentを取得する
export async function getBaseComponentsByAreaId(
  areaId: string
): Promise<BaseComponent[]> {
  const components = (
    await API.graphql({
      query: baseComponentsByBaseAreaId,
      variables: { baseAreaId: areaId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.baseComponentsByBaseAreaId.items;
  return components;
}

export async function addBaseArea(
  packageTypeId: string,
  order: number
): Promise<string> {
  const input = {
    id: uuid(),
    packageTypeId,
    name: "新区分",
    order,
    areaType: "COMPONENT",
  };
  const areaId = (
    await API.graphql({
      query: createBaseArea,
      variables: { input: input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.createBaseArea.id;
  return areaId;
}

export async function deleteBaseArea(id: string) {
  await API.graphql({
    query: deleteBaseAreaG,
    variables: {
      input: {
        id: id,
      },
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
}

export function getAreaComponents(pkg: any) {
  if (pkg && pkg.areas) {
    return pkg.areas.items
      .sort((a: any, b: any) => a.order - b.order)
      .reduce((r: any, c: any) => {
        r[c.id] = c.components?.items?.sort(
          (a: any, b: any) => a.order - b.order
        );
        return r;
      }, {});
  }
  return {};
}

export function getInteriors(pkg: any) {
  if (pkg && pkg.decorations) {
    return pkg.decorations.items?.filter((d: any) => d.type === "INTERIOR");
  }
  return [];
}

export function getExteriors(pkg: any) {
  if (pkg && pkg.decorations) {
    return pkg.decorations.items?.filter((d: any) => d.type === "EXTERIOR");
  }
  return [];
}

export const getPackageById = async (
  packageId: string
): Promise<PackageType> => {
  const p = (
    await API.graphql({
      query: getPackageType,
      variables: { id: packageId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getPackageType;
  return p;
};
