export function calcCombinationSign(
  nw: number,
  sideStrutsCenterSpace: number,
  signCalcOuterWidth: number,
  innerHeight: number
): number | string {
  if (!nw || !sideStrutsCenterSpace || !signCalcOuterWidth || !innerHeight) {
    return 999;
  }
  const firstKeys = Object.keys(MATRIX);
  const closestOuterWidth = findClosestKey(signCalcOuterWidth, firstKeys, 0);
  const closestInnerHeight = findClosestKey(innerHeight, firstKeys, 1);

  const selectedKey = `${closestOuterWidth}x${closestInnerHeight}`;

  if (!MATRIX[selectedKey]) {
    return 999;
  }

  const nwSpacingKeys = Object.keys(MATRIX[selectedKey]);
  const closestNW = findClosestKey(nw, nwSpacingKeys, 0);
  const closestSpacing = findClosestKey(
    sideStrutsCenterSpace,
    nwSpacingKeys,
    1
  );

  const weightSpacingKey = `${closestNW}x${closestSpacing}`;

  if (MATRIX[selectedKey][weightSpacingKey] !== undefined) {
    return MATRIX[selectedKey][weightSpacingKey];
  } else {
    return 999;
  }
}

function findClosestKey(value: number, keys: string[], index: number): string {
  const numericKeys = keys
    .map((key) => parseInt(key.split("x")[index]))
    .filter((key) => key >= value);
  if (numericKeys.length === 0) return "No matching key found.";
  const closestKey = Math.min(...numericKeys);
  return closestKey.toString();
}

// 枠組部材の組み合わせ符号選択表
// 1階層目のkeyは外寸幅mm x 内寸高さmm
// 2階層目のkeyは重量kg x 支柱中心間隔mm
interface MatrixData {
  [key: string]: {
    [key: string]: number;
  };
}

const MATRIX: MatrixData = {
  "1000x1000": {
    "1000x750": 0,
    "1000x950": 0,
    "1000x1050": 0,
    "1000x1250": 0,
    "2000x750": 0,
    "2000x950": 0,
    "2000x1050": 0,
    "2000x1250": 0,
    "3000x750": 0,
    "3000x950": 0,
    "3000x1050": 0,
    "3000x1250": 0,
    "4000x750": 0,
    "4000x950": 0,
    "4000x1050": 0,
    "4000x1250": 0,
    "5000x750": 0,
    "5000x950": 0,
    "5000x1050": 0,
    "5000x1250": 0,
  },
  "1000x1500": {
    "1000x750": 0,
    "1000x950": 0,
    "1000x1050": 0,
    "1000x1250": 0,
    "2000x750": 0,
    "2000x950": 0,
    "2000x1050": 0,
    "2000x1250": 0,
    "3000x750": 0,
    "3000x950": 0,
    "3000x1050": 0,
    "3000x1250": 0,
    "4000x750": 0,
    "4000x950": 0,
    "4000x1050": 0,
    "4000x1250": 0,
    "5000x750": 0,
    "5000x950": 0,
    "5000x1050": 0,
    "5000x1250": 0,
  },
  "1000x2000": {
    "1000x750": 2,
    "1000x950": 2,
    "1000x1050": 2,
    "1000x1250": 2,
    "2000x750": 2,
    "2000x950": 2,
    "2000x1050": 2,
    "2000x1250": 2,
    "3000x750": 2,
    "3000x950": 2,
    "3000x1050": 2,
    "3000x1250": 2,
    "4000x750": 2,
    "4000x950": 2,
    "4000x1050": 2,
    "4000x1250": 2,
    "5000x750": 2,
    "5000x950": 2,
    "5000x1050": 2,
    "5000x1250": 2,
  },
  "1000x2200": {
    "1000x750": 2,
    "1000x950": 2,
    "1000x1050": 2,
    "1000x1250": 2,
    "2000x750": 2,
    "2000x950": 2,
    "2000x1050": 2,
    "2000x1250": 2,
    "3000x750": 2,
    "3000x950": 2,
    "3000x1050": 2,
    "3000x1250": 2,
    "4000x750": 2,
    "4000x950": 2,
    "4000x1050": 2,
    "4000x1250": 2,
    "5000x750": 2,
    "5000x950": 2,
    "5000x1050": 2,
    "5000x1250": 2,
  },
  "1500x1000": {
    "1000x750": 0,
    "1000x950": 0,
    "1000x1050": 0,
    "1000x1250": 0,
    "2000x750": 0,
    "2000x950": 0,
    "2000x1050": 0,
    "2000x1250": 0,
    "3000x750": 0,
    "3000x950": 0,
    "3000x1050": 0,
    "3000x1250": 0,
    "4000x750": 0,
    "4000x950": 0,
    "4000x1050": 0,
    "4000x1250": 0,
    "5000x750": 0,
    "5000x950": 0,
    "5000x1050": 0,
    "5000x1250": 0,
  },
  "1500x1500": {
    "1000x750": 0,
    "1000x950": 0,
    "1000x1050": 0,
    "1000x1250": 0,
    "2000x750": 0,
    "2000x950": 0,
    "2000x1050": 0,
    "2000x1250": 0,
    "3000x750": 0,
    "3000x950": 0,
    "3000x1050": 0,
    "3000x1250": 0,
    "4000x750": 0,
    "4000x950": 0,
    "4000x1050": 0,
    "4000x1250": 0,
    "5000x750": 0,
    "5000x950": 0,
    "5000x1050": 0,
    "5000x1250": 0,
  },
  "1500x2000": {
    "1000x750": 2,
    "1000x950": 2,
    "1000x1050": 2,
    "1000x1250": 2,
    "2000x750": 2,
    "2000x950": 2,
    "2000x1050": 2,
    "2000x1250": 2,
    "3000x750": 2,
    "3000x950": 2,
    "3000x1050": 2,
    "3000x1250": 2,
    "4000x750": 2,
    "4000x950": 2,
    "4000x1050": 2,
    "4000x1250": 2,
    "5000x750": 2,
    "5000x950": 2,
    "5000x1050": 2,
    "5000x1250": 2,
  },
  "1500x2200": {
    "1000x750": 2,
    "1000x950": 2,
    "1000x1050": 2,
    "1000x1250": 2,
    "2000x750": 2,
    "2000x950": 2,
    "2000x1050": 2,
    "2000x1250": 2,
    "3000x750": 2,
    "3000x950": 2,
    "3000x1050": 2,
    "3000x1250": 2,
    "4000x750": 2,
    "4000x950": 2,
    "4000x1050": 2,
    "4000x1250": 2,
    "5000x750": 2,
    "5000x950": 2,
    "5000x1050": 2,
    "5000x1250": 2,
  },
  "2000x1000": {
    "1000x750": 0,
    "1000x950": 0,
    "1000x1050": 0,
    "1000x1250": 0,
    "2000x750": 0,
    "2000x950": 0,
    "2000x1050": 0,
    "2000x1250": 0,
    "3000x750": 0,
    "3000x950": 0,
    "3000x1050": 0,
    "3000x1250": 0,
    "4000x750": 0,
    "4000x950": 0,
    "4000x1050": 0,
    "4000x1250": 0,
    "5000x750": 0,
    "5000x950": 0,
    "5000x1050": 0,
    "5000x1250": 0,
  },
  "2000x1500": {
    "1000x750": 0,
    "1000x950": 0,
    "1000x1050": 0,
    "1000x1250": 0,
    "2000x750": 0,
    "2000x950": 0,
    "2000x1050": 0,
    "2000x1250": 0,
    "3000x750": 0,
    "3000x950": 0,
    "3000x1050": 0,
    "3000x1250": 0,
    "4000x750": 0,
    "4000x950": 0,
    "4000x1050": 0,
    "4000x1250": 0,
    "5000x750": 0,
    "5000x950": 0,
    "5000x1050": 0,
    "5000x1250": 0,
  },
  "2000x2000": {
    "1000x750": 2,
    "1000x950": 2,
    "1000x1050": 2,
    "1000x1250": 2,
    "2000x750": 2,
    "2000x950": 2,
    "2000x1050": 2,
    "2000x1250": 2,
    "3000x750": 2,
    "3000x950": 2,
    "3000x1050": 2,
    "3000x1250": 2,
    "4000x750": 2,
    "4000x950": 2,
    "4000x1050": 2,
    "4000x1250": 2,
    "5000x750": 2,
    "5000x950": 2,
    "5000x1050": 2,
    "5000x1250": 2,
  },
  "2000x2200": {
    "1000x750": 2,
    "1000x950": 2,
    "1000x1050": 2,
    "1000x1250": 2,
    "2000x750": 2,
    "2000x950": 2,
    "2000x1050": 2,
    "2000x1250": 2,
    "3000x750": 2,
    "3000x950": 2,
    "3000x1050": 2,
    "3000x1250": 2,
    "4000x750": 2,
    "4000x950": 2,
    "4000x1050": 2,
    "4000x1250": 2,
    "5000x750": 2,
    "5000x950": 2,
    "5000x1050": 2,
    "5000x1250": 2,
  },
  "2300x1000": {
    "1000x750": 0,
    "1000x950": 0,
    "1000x1050": 0,
    "1000x1250": 0,
    "2000x750": 0,
    "2000x950": 0,
    "2000x1050": 0,
    "2000x1250": 0,
    "3000x750": 0,
    "3000x950": 0,
    "3000x1050": 0,
    "3000x1250": 0,
    "4000x750": 0,
    "4000x950": 0,
    "4000x1050": 0,
    "4000x1250": 0,
    "5000x750": 0,
    "5000x950": 0,
    "5000x1050": 0,
    "5000x1250": 0,
  },
  "2300x1500": {
    "1000x750": 0,
    "1000x950": 0,
    "1000x1050": 0,
    "1000x1250": 0,
    "2000x750": 0,
    "2000x950": 0,
    "2000x1050": 0,
    "2000x1250": 0,
    "3000x750": 0,
    "3000x950": 0,
    "3000x1050": 0,
    "3000x1250": 0,
    "4000x750": 0,
    "4000x950": 0,
    "4000x1050": 0,
    "4000x1250": 0,
    "5000x750": 0,
    "5000x950": 0,
    "5000x1050": 0,
    "5000x1250": 0,
  },
  "2300x2000": {
    "1000x750": 2,
    "1000x950": 2,
    "1000x1050": 2,
    "1000x1250": 2,
    "2000x750": 2,
    "2000x950": 2,
    "2000x1050": 2,
    "2000x1250": 2,
    "3000x750": 2,
    "3000x950": 2,
    "3000x1050": 2,
    "3000x1250": 2,
    "4000x750": 2,
    "4000x950": 2,
    "4000x1050": 2,
    "4000x1250": 2,
    "5000x750": 2,
    "5000x950": 2,
    "5000x1050": 2,
    "5000x1250": 2,
  },
};
