import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "./auth";

import type { Species } from "API";
import useDatalist from "hooks/datalist";
import { useDialog } from "contexts/dialog";
import { useAlerts } from "contexts/alerts";

interface SpeciesContextValue {
  loading: boolean;
  species: Species[];
  create: (input: any) => void;
  update: (input: any) => void;
  remove: (ids: string[]) => void;
}

interface SpeciesContextProps {
  children: ReactNode;
  variables?: { [key: string]: any };
}

const SpeciesContext = createContext<SpeciesContextValue>({
  species: [],
  loading: false,
  create: () => null,
  update: () => null,
  remove: () => null,
});

export const SpeciesProvider = ({
  children,
  variables,
}: SpeciesContextProps) => {
  const { currentGroup } = useAuth();
  const {
    data,
    loading,
    refetch,
    create,
    remove,
    update,
    nextToken,
    appendNext,
  } = useDatalist({
    query: "speciesByGroupId",
    variables: {
      groupId: currentGroup?.id,
    },
  });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken]);

  useEffect(() => {
    refetch({ groupId: currentGroup?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables, currentGroup?.id]);

  const { open } = useDialog();
  const { addAlert } = useAlerts();

  const createSpecies = async (input: any) => {
    // inputにcreatedAt, updatedAt, materialsがあったら消す
    delete input.createdAt;
    delete input.updatedAt;
    delete input.materials;
    await create("createSpecies", {
      ...input,
      groupId: currentGroup?.id,
    });
    addAlert({ message: "材質を作成しました", severity: "success" });
  };

  const updateSpecies = async (input: any) => {
    // inputにcreatedAt, updatedAt, materialsがあったら消す
    delete input.createdAt;
    delete input.updatedAt;
    delete input.materials;
    await update("updateSpecies", {
      ...input,
    });
    addAlert({ message: "材質を更新しました", severity: "success" });
  };

  const removeSpecies = async (ids: string[]) => {
    open({
      title: "材質を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => {
        const promises = ids.map((id) =>
          remove("deleteSpecies", {
            id,
          })
        );
        await Promise.all(promises);
        addAlert({ message: "材質を削除しました", severity: "success" });
      },
    });
  };

  return (
    <SpeciesContext.Provider
      value={{
        species: data,
        loading,
        create: createSpecies,
        update: updateSpecies,
        remove: removeSpecies,
      }}
    >
      {children}
    </SpeciesContext.Provider>
  );
};

export const useSpecies = () => {
  const speciesContext = useContext(SpeciesContext);

  if (speciesContext === undefined) {
    throw new Error("useSpecies must be within SpeciesProvider");
  }

  return speciesContext;
};
