import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import PhotoIcon from "@mui/icons-material/PhotoOutlined";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import SummarizeIcon from "@mui/icons-material/SummarizeOutlined";
import EventIcon from "@mui/icons-material/EventOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import QuoteItems from "./QuoteItems";
import PackProductItems from "./PackProductItems";
import NonPackProductItems from "./NonPackProductItems";
import InvoiceItems from "./InvoiceItems";

import { useProject } from "contexts/project";
import { useAuth } from "contexts/auth";

interface MenuListProps {
  toggleDrawer: () => void;
}

export const MainListItems = ({ toggleDrawer }: MenuListProps) => {
  const { project } = useProject();
  const [currentPage, setCurrentPage] = useState("project");
  const { permissions } = useAuth();
  const router = useRouter();

  const { query } = router;

  useEffect(() => {
    if (!query.page) return setCurrentPage("project");
    setCurrentPage(query.page as string);
  }, [query]);

  if (!project) return <div />;

  return (
    <>
      <Link href={`/projects/${project.id}`} prefetch={false}>
        <ListItemButton
          onClick={toggleDrawer}
          selected={currentPage === "project"}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="案件" />
        </ListItemButton>
      </Link>
      {permissions.ProjectPhoto.canRead && (
        <Link href={`/projects/${project.id}?page=photo`} prefetch={false}>
          <ListItemButton
            onClick={toggleDrawer}
            selected={currentPage === "photo"}
          >
            <ListItemIcon>
              <PhotoIcon />
            </ListItemIcon>
            <ListItemText primary="写真" />
          </ListItemButton>
        </Link>
      )}
      {permissions.ProjectFile.canRead && (
        <Link href={`/projects/${project.id}?page=files`} prefetch={false}>
          <ListItemButton
            onClick={toggleDrawer}
            selected={currentPage === "files"}
          >
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary="ファイル" />
          </ListItemButton>
        </Link>
      )}
      {permissions.ProjectReport.canRead && (
        <Link href={`/projects/${project.id}?page=reports`} prefetch={false}>
          <ListItemButton
            onClick={toggleDrawer}
            selected={currentPage === "reports"}
          >
            <ListItemIcon>
              <SummarizeIcon />
            </ListItemIcon>
            <ListItemText primary="帳票" />
          </ListItemButton>
        </Link>
      )}
    </>
  );
};

export const SecondaryListItems = ({ toggleDrawer }: MenuListProps) => {
  const { project } = useProject();
  const [currentPage, setCurrentPage] = useState("project");
  const { permissions } = useAuth();
  const router = useRouter();

  const { query } = router;

  useEffect(() => {
    if (!!query.quoteId) return setCurrentPage(query.quoteId as string);
    if (!!query.productId) return setCurrentPage(query.productId as string);
    setCurrentPage(query.page as string);
  }, [query]);

  if (!project) return <div />;

  return (
    <>
      <ListSubheader component="div" inset>
        データ入力
      </ListSubheader>
      {permissions.ProjectSchedule.canRead && (
        <Link href={`/projects/${project.id}?page=schedules`} prefetch={false}>
          <ListItemButton
            onClick={toggleDrawer}
            selected={currentPage === "schedules"}
          >
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="スケジュール" />
          </ListItemButton>
        </Link>
      )}
      {permissions.ProjectQuote.canRead && (
        <QuoteItems toggleDrawer={toggleDrawer} />
      )}
      {permissions.ProjectProduct.canRead && (
        <>
          {project.type === "OTHER" ? (
            <NonPackProductItems toggleDrawer={toggleDrawer} />
          ) : (
            <PackProductItems toggleDrawer={toggleDrawer} />
          )}
        </>
      )}
      {permissions.ProjectInvoice.canRead && (
        <InvoiceItems toggleDrawer={toggleDrawer} />
      )}
      {permissions.Project.canWrite && (
        <Link href={`/projects/${project.id}?page=settings`} prefetch={false}>
          <ListItemButton
            onClick={toggleDrawer}
            selected={currentPage === "settings"}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="設定" />
          </ListItemButton>
        </Link>
      )}
    </>
  );
};
