// 等分布荷重
export const EqualDistribution = (
  fb: number,
  l: number,
  l1: number,
  l2: number,
  b: number,
  h: number
) => {
  if (!l) {
    return 0;
  }
  return (4 * b * h ** 2 * fb) / (3 * l);
};

// 中央集中荷重
export const Centralization = (
  fb: number,
  l: number,
  l1: number,
  l2: number,
  b: number,
  h: number
) => {
  if (!l) {
    return 0;
  }
  return (2 * b * h ** 2 * fb) / (3 * l);
};

// 偏荷重(集中)
export const Partial = (
  fb: number,
  l: number,
  l1: number,
  l2: number,
  b: number,
  h: number
) => {
  if (!l1 || !l2 || l1 <= l2) {
    return 0;
  }
  return (b * h ** 2 * l * fb) / (6 * l1 * l2);
};

// 2点集中荷重
export const TwoPointsConcentrated = (
  fb: number,
  l: number,
  l1: number,
  l2: number,
  b: number,
  h: number
) => {
  if (!l1 || !l2 || l1 < l2) {
    return 0;
  }
  // 等分布荷重で計算した結果の2.5倍までを許容し、それより大きい場合は等分布荷重の2.5倍を返す
  const equal = EqualDistribution(fb, l, l1, l2, b, h) * 2.5; // 等分布
  const twoPoint = (b * h ** 2 * l * fb) / (3 * (l - l1 + l2) * l1); // 2点集中

  return equal < twoPoint ? equal : twoPoint;
};

export const Calculate: { [key: string]: any } = {
  EqualDistribution: EqualDistribution,
  Centralization: Centralization,
  Partial: Partial,
  TwoPointsConcentrated: TwoPointsConcentrated,
};
