import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WidgetsIcon from "@mui/icons-material/WidgetsOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LinearProgress from "@mui/material/LinearProgress";
import Collapse from "@mui/material/Collapse";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { List } from "@mui/material";

import { useProducts } from "contexts/products";
import { useProject } from "contexts/project";
import { Product } from "API";

interface NonProductProps {
  toggleDrawer: () => void;
}

interface NonProductItemProps {
  product: Product;
  toggleDrawer: () => void;
}

const ProductItems = ({ toggleDrawer, product }: NonProductItemProps) => {
  const [currentPage, setCurrentPage] = useState("project");
  const { project } = useProject();
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (!!query.productId) {
      setCurrentPage(query.productId as string);
    } else {
      setCurrentPage("");
    }
  }, [query, product]);

  if (!project || !product) return null;

  const handleClick = () => {
    toggleDrawer();
    router.push(
      `/projects/${project.id}?page=products&productId=${product.id}`
    );
  };

  return (
    <div className="flex justify-between">
      <ListItemButton
        sx={{ pl: 4 }}
        selected={currentPage === product.id}
        onClick={handleClick}
      >
        <ListItemText primary={product.name} />
      </ListItemButton>
    </div>
  );
};

const NonProductNav = ({ toggleDrawer }: NonProductProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState("project");
  const { products, loading } = useProducts();
  const { project } = useProject();
  const router = useRouter();

  const { query } = router;

  useEffect(() => {
    if (!query.productId && !query.case) {
      setCurrentPage(query.page as string);
    } else if (!!query.productId && query.page === "products") {
      setCurrentPage("");
      setOpen(true);
    } else if (!!query.productId && !!query) {
      setCurrentPage("");
      setOpen(true);
    } else {
      setCurrentPage("");
    }
  }, [query]);

  if (!project || !products) return null;

  const handleClick = () => {
    toggleDrawer();
    router.push(`/projects/${project.id}?page=products`);
  };

  return (
    <>
      <div className="flex justify-between">
        <ListItemButton
          onClick={handleClick}
          selected={currentPage === "products"}
        >
          <ListItemIcon>
            <WidgetsIcon />
          </ListItemIcon>
          <ListItemText primary="製品サービス" />
        </ListItemButton>
        <div className="cursor-pointer flex items-center mr-4">
          {open ? (
            <ExpandLess onClick={() => setOpen(false)} />
          ) : (
            <ExpandMore onClick={() => setOpen(true)} />
          )}
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {loading && products.length === 0 && <LinearProgress />}
          {products.length > 0 &&
            products.map((product) => (
              <ProductItems
                key={product.id}
                product={product}
                toggleDrawer={toggleDrawer}
              />
            ))}
        </List>
      </Collapse>
    </>
  );
};

export default NonProductNav;
