import { Material } from "API";

// はり資材の方向を返す関数
export function JISBeamEdge(
  ceilingLoad: number | null,
  beamLength: number | null,
  beamCenterSpace: number | null,
  beamSpeciesId: string | null,
  mainSpeciesId: string | null,
  mainSpeciesEdgeMPa: number | null,
  mainSpeciesFaceMPa: number | null,
  materials: any,
  species: any
): string {
  return JISBeamMaterialEdge(
    ceilingLoad,
    beamLength,
    beamCenterSpace,
    beamSpeciesId,
    mainSpeciesId,
    mainSpeciesEdgeMPa,
    mainSpeciesFaceMPa,
    materials,
    species
  ).beamEdge;
}

// はり資材の選定を行う関数
export function JISBeamMaterial(
  ceilingLoad: number | null,
  beamLength: number | null,
  beamCenterSpace: number | null,
  beamSpeciesId: string | null,
  mainSpeciesId: string | null,
  mainSpeciesEdgeMPa: number | null,
  mainSpeciesFaceMPa: number | null,
  materials: any,
  species: any
): string {
  return JISBeamMaterialEdge(
    ceilingLoad,
    beamLength,
    beamCenterSpace,
    beamSpeciesId,
    mainSpeciesId,
    mainSpeciesEdgeMPa,
    mainSpeciesFaceMPa,
    materials,
    species
  ).materialId;
}

// ここでは、事前の以下の処理を行い実際に該当する部材を計算する関数を呼び出す
// - 必要なパラメータの確認(ceilingLoad, beamLength, beamCenterSpace, materials)
// - 定数の計算…ceil(400 / (天井荷重(ceilingLoad) / 10 x 3))を定数とする
// - 許容強さ…beamSpeciesIdの{EdgeMPa/faceMPa} > mainSpeciesIdの{EdgeMPa/faceMPa} > {mainSpeciesEdgeMPa/mainSpeciesFaceMPa} > 8.1、の優先度
// - 木材のみに絞る…materialsの中で木材のもののみを対象とする
function JISBeamMaterialEdge(
  ceilingLoad: number | null,
  beamLength: number | null,
  beamCenterSpace: number | null,
  beamSpeciesId: string | null,
  mainSpeciesId: string | null,
  mainSpeciesEdgeMPa: number | null,
  mainSpeciesFaceMPa: number | null,
  materials: any,
  species: any
): {
  materialId: string;
  beamEdge: string;
} {
  // 必要なパラメータの確認
  if (!ceilingLoad || !beamLength || !beamCenterSpace || !materials) {
    return {
      materialId: "",
      beamEdge: "",
    };
  }

  // 定数の計算
  const constant = Math.ceil(400 / ((ceilingLoad / 10) * 3));

  // 木端方向許容強さ
  let edgeMPa = 8.1;
  let faceMPa = 8.1;
  if (beamSpeciesId && beamSpeciesId !== "null") {
    const beamSpecies = species.find((s: any) => s.id === beamSpeciesId);
    if (beamSpecies?.edgeMPa && beamSpecies?.faceMPa) {
      edgeMPa = beamSpecies.edgeMPa;
      faceMPa = beamSpecies.faceMPa;
    } else {
      return {
        materialId: "",
        beamEdge: "",
      };
    }
  } else if (mainSpeciesId) {
    const mainSpecies = species.find((s: any) => s.id === mainSpeciesId);
    if (mainSpecies?.edgeMPa && mainSpecies?.faceMPa) {
      edgeMPa = mainSpecies.edgeMPa;
      faceMPa = mainSpecies.faceMPa;
    } else if (mainSpeciesEdgeMPa && mainSpeciesFaceMPa) {
      edgeMPa = mainSpeciesEdgeMPa;
      faceMPa = mainSpeciesFaceMPa;
    }
  }

  // 木材のみに絞る
  const woodMaterials = materials.filter(
    (m: Material) => m.materialType?.isWood ?? false
  );

  return selectBeamMaterial(
    constant,
    beamLength,
    beamCenterSpace,
    edgeMPa,
    faceMPa,
    woodMaterials
  );
}

// はり部材の選定を行う関数
// 以下の式を満たすmaterialIdと方向(beamEdge)を返す
// はりの中心間隔(beamCenterSpace) <= (定数(constant) * はりの幅 * はりの高さ^2 * 許容強さ) / はりの長さ(beamLength)
// ここではりの幅, はりの高さ, 許容強さには以下の注意点があります。
// - 「はりの幅」と「はりの高さ」は部材を平方向で比較する場合はそれぞれ部材の「呼び寸巾」と「呼び寸高さ」になり、木端方向の時は逆になる
// - 「許容強さ」は平方向の場合は「EdgeMPa」、木端方向の場合は「FaceMPa」になる
// materialの検索順序は以下の通り
// - materialsの要素に一つでも「はり検索順(木端方向)(=z1403BeamEdgeOrder)」と「はり検索順(平方向)(=z1403BeamFaceOrder)」に値があったら、値があるものの中で値の昇順で確認していく
//   - ここでedgeかfaceかはフラグとして保持しておく(比較のため)
// - 一つもなかったらrefBhhEdgeの小さい順に確認していく
function selectBeamMaterial(
  constant: number,
  beamLength: number,
  beamCenterSpace: number,
  edgeMPa: number,
  faceMPa: number,
  materials: Material[]
): {
  materialId: string;
  beamEdge: string;
} {
  let targetMaterials: Material[] = [];

  // z1403BeamEdgeOrderまたはz1403BeamFaceOrderが設定されているMaterialを抽出
  const z1403BeamOrderMaterials = materials.filter(
    (m) => m.z1403BeamEdgeOrder != null || m.z1403BeamFaceOrder != null
  );

  // 比較順で以下のObjectをMaterialから生成
  const targetParameters: {
    materialId: string;
    beamWidth: number;
    beamHeight: number;
    mpa: number;
    edge: boolean;
  }[] = [];

  // 全くなかったらrefBhhEdgeの設定されているものを抽出
  if (z1403BeamOrderMaterials.length === 0) {
    targetMaterials = materials.filter((m) => m.refBhhEdge != null);
    // refBhhEdgeが小さい順にソート
    targetMaterials.sort((a, b) => {
      return (a.refBhhEdge ?? 0) - (b.refBhhEdge ?? 0);
    });
    // targetParametersに設定
    targetMaterials.forEach((m) => {
      targetParameters.push({
        materialId: m.id,
        beamHeight: m.heightNominal ?? 0,
        beamWidth: m.widthNominal ?? 0,
        mpa: faceMPa,
        edge: false,
      });
    });
  } else {
    // 対象があったらz1403BeamSupportOrderから{materialId, order, edge(edgeなら1, faceなら0)}を引き出してくる
    const targetOrders: {
      materialId: string;
      order: number;
      edge: boolean;
    }[] = [];

    z1403BeamOrderMaterials.forEach((m) => {
      if (m.z1403BeamEdgeOrder != null) {
        targetOrders.push({
          materialId: m.id,
          order: m.z1403BeamEdgeOrder,
          edge: true,
        });
      }
      if (m.z1403BeamFaceOrder != null) {
        targetOrders.push({
          materialId: m.id,
          order: m.z1403BeamFaceOrder,
          edge: false,
        });
      }
    });

    // それを使ってtargetParametersに追加
    targetOrders.sort((a, b) => a.order - b.order);
    targetOrders.forEach((t) => {
      const material = materials.find((m) => m.id === t.materialId);
      if (material) {
        // はり長さ、はり幅、許容強さを設定
        // edge or faceで変わってくる
        const beamWidth = t.edge
          ? material.heightNominal
          : material.widthNominal;
        const beamHeight = t.edge
          ? material.widthNominal
          : material.heightNominal;
        const mpa = t.edge ? edgeMPa : faceMPa;
        targetParameters.push({
          materialId: t.materialId,
          beamWidth: beamWidth ?? 0,
          beamHeight: beamHeight ?? 0,
          mpa: mpa,
          edge: t.edge,
        });
      }
    });
  }

  // 資材の選定
  for (let i = 0; i < targetParameters.length; i++) {
    const param = targetParameters[i];
    if (
      check(
        constant,
        beamLength,
        beamCenterSpace,
        param.mpa,
        param.beamWidth,
        param.beamHeight
      )
    ) {
      return {
        materialId: param.materialId,
        beamEdge: param.edge ? "1" : "0",
      };
    }
  }
  return {
    materialId: "",
    beamEdge: "",
  };
}

// 実際に計算する関数
// 以下の式を満たすかどうかを確認する
// はりの中心間隔(beamCenterSpace) <= (定数(constant) * はりの幅(beamWidth) * はりの高さ(beamHeight)^2 * 許容強さ(mpa)) / はりの長さ(beamLength)
function check(
  constant: number,
  beamLength: number,
  beamCenterSpace: number,
  mpa: number,
  beamWidth: number,
  beamHeight: number
): boolean {
  const leftHand = beamCenterSpace;
  const rightHand =
    (constant * beamWidth * Math.pow(beamHeight ?? 0, 2) * mpa) /
    Math.pow(beamLength ?? 0, 2);
  return leftHand <= rightHand;
}
