import { useState } from "react";
import Link from "next/link";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import { useProject } from "contexts/project";
import QrScanner from "components/QrScanner";

interface AppBarProps {
  open?: boolean;
  toggleDrawer: () => void;
}

export function ProjectAppBar({ toggleDrawer }: AppBarProps) {
  const { project } = useProject();
  const [showQrScan, setShowQr] = useState(false);

  return (
    <>
      <QrScanner open={showQrScan} setOpen={setShowQr} />
      <AppBar>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              mr: 2,
              display: { sm: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{
                flexGrow: 1,
                display: { sm: "none" },
              }}
            >
              {project?.name}
            </Typography>
          </Box>
          <Link href="/" prefetch={false}>
            <IconButton color="inherit">
              <HomeIcon />
            </IconButton>
          </Link>
          <IconButton
            color="inherit"
            aria-label="scan QR code"
            onClick={() => setShowQr(true)}
          >
            <QrCodeScannerIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}
