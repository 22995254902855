import { API } from "aws-amplify";

import { getPackageBom } from "graphql/queries";

export const getPackageBomByID = async (
  packageTypeId: string
): Promise<any> => {
  const p = (
    await API.graphql({
      query: getPackageBom,
      variables: { input: { packageTypeId } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getPackageBom;
  return p;
};
