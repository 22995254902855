import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { API } from "aws-amplify";
import shortUUID, { uuid } from "short-uuid";

import { Bom } from "./bom/update";
import { createEmptyElements } from "./bom/elements";
import { CustomError } from "./error";

import type { Product, Case, CaseInput, Component, QuoteProduct } from "API";
import { getProductsByProjectId as getProductsByProjectIdG } from "graphql/product";
import {
  createArea,
  createComponent,
  createDecorationComponent,
  deleteArea as deleteAreaG,
  deleteDecorationComponent,
  deleteComponent as deleteComponentG,
} from "graphql/mutations";
import { componentsByAreaId, getProduct } from "graphql/queries";
import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";
import { GraphQLInput } from "hooks/datalist";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; label: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.label)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const getTotalCases = (products: (Product | null)[]) => {
  let totalCases = 0;
  if (!products || products.length === 0) return 0;
  products.forEach((p) => {
    if (!p || !p.cases) return;
    totalCases += p.cases.length;
  });
  return totalCases;
};

export const getProductCompletionRate = (
  product: QuoteProduct | Product | null
) => {
  if (!product || !product.cases || product.cases.length === 0) return 0;
  let packedCases = 0;
  product.cases.forEach((c: Case | null) => {
    if (!c || !c.phaseId) return;
    if (c.phaseId !== "complete") return;
    packedCases += 1;
  });
  return packedCases / product.cases.length;
};

export const getProductsCompletionRate = (
  products: (Product | null)[] | undefined
) => {
  if (!products || products.length === 0) return 0;
  const totalCases = getTotalCases(products);
  return (
    products.reduce((r: number, p: Product | null) => {
      if (!p || !p.cases || p.cases.length === 0) return r;
      r += p.cases.filter((c) => c?.isPacked).length;
      return r;
    }, 0) / totalCases
  );
};

export const ProgressIcon = ({
  progress,
  ...props
}: {
  progress: number;
  [prop: string]: any;
}) => {
  if (progress === 0)
    return (
      <CircularProgressWithLabel
        sx={{ color: "#999" }}
        variant="determinate"
        value={100}
        label={0}
        {...props}
      />
    );

  if (progress === 100)
    return (
      <div className="relative flex items-center justify-center">
        <CircularProgress
          color="success"
          variant="determinate"
          value={100}
          {...props}
        />
        <CheckCircleIcon color="success" className="absolute" />
      </div>
    );

  return (
    <CircularProgressWithLabel
      variant="determinate"
      value={progress}
      label={progress}
      {...props}
    />
  );
};

//
export const getUpdatedCaseInputByProductElements = (
  product: Product | QuoteProduct
): CaseInput[] | null => {
  if (product.cases === undefined || product.cases === null) return [];
  if (product.elements === undefined || product.elements === null) return null;
  const outerLengthElem = product.elements.find(
    (e) => e !== null && e.slug === "outerLength"
  );
  const outerWidthElem = product.elements.find(
    (e) => e !== null && e.slug === "outerWidth"
  );
  const outerHeightElem = product.elements.find(
    (e) => e !== null && e.slug === "outerHeight"
  );
  const outerLength = parseInt(outerLengthElem?.value || "", 10) || 0;
  const outerWidth = parseInt(outerWidthElem?.value || "", 10) || 0;
  const outerHeight = parseInt(outerHeightElem?.value || "", 10) || 0;
  const newCs: CaseInput[] = product.cases.map((c) => ({
    ...c,
    order: c?.order as number,
    outerLength: outerLength,
    outerWidth: outerWidth,
    outerHeight: outerHeight,
    // m3は小数点3桁まで
    m3: calcM3(outerLength, outerWidth, outerHeight),
  }));
  return newCs;
};

export const extractElements = (elements: any[]) => {
  if (elements == null) return {};
  const extractedElements = elements.reduce(
    (result: { [key: string]: any }, e: { slug: string }) => {
      let slug = e.slug;
      if (slug.includes(".")) {
        slug = slug.split(".")[1];
      }
      result[slug] = e;
      return result;
    },
    {}
  );
  if (!extractedElements["speciesId"]) {
    extractedElements["speciesId"] = {
      cutting: null,
      description: null,
      expr: null,
      overwriteValue: null,
      value: null,
      type: "string",
      name: "speciesId",
      slug: `${elements[0].slug.split(".")[0]}.speciesId`,
    };
  }
  return extractedElements;
};

export function createCases(product: Product, bom?: Bom | undefined) {
  // PAX-698 暫定措置：外寸の取得元をproductからbomに変更、getCasesからcreateCasesに関数変更
  // 指示書一覧画面からは product、指示書編集画面からはbomで外寸を取得します。
  let outerLength = 0;
  let outerWidth = 0;
  let outerHeight = 0;
  if (bom) {
    outerLength = parseFloat(bom?.product?.elements.outerLength.value || "0");
    outerWidth = parseFloat(bom?.product?.elements.outerWidth.value || "0");
    outerHeight = parseFloat(bom?.product?.elements.outerHeight.value || "0");
  } else {
    const elements = extractElements(product.elements || []);
    outerLength = parseFloat(elements.outerLength?.value || "0");
    outerWidth = parseFloat(elements.outerWidth?.value || "0");
    outerHeight = parseFloat(elements.outerHeight?.value || "0");
  }
  const quantity = product.quantity ? product.quantity.toString() : "0"; // デフォルト値を '0' に設定
  const cases = Array.from(Array(parseInt(quantity, 10)).keys()).map((o) => ({
    order: o + 1,
    name: `${product.productNumber ?? product.name}-${o + 1}`,
    packPhases: product.packPhases || [],
    phaseId: null,
    isPacked: false,
    netWeight: (product?.grossWeight || 0) - (product?.tareWeight || 0),
    grossWeight: 0,
    outerLength: outerLength,
    outerWidth: outerWidth,
    outerHeight: outerHeight,
    m3: product?.m3,
    thumbnail: null,
    managementNumber: null,
    isBilled: false,
    invoiceLineId: null,
  }));
  return cases;
}

export const getUpdatedCaseInputByProductNumber = (
  products: Product[],
  input: GraphQLInput
) => {
  const product = products.find((p) => p.id === input.id);
  const newCs = product?.cases?.map((c, index) => ({
    ...c,
    name: input.productNumber + "-" + (index + 1),
  }));
  return newCs;
};

export const calcM3 = (
  outerLength: number,
  outerWidth: number,
  outerHeight: number
) => {
  const CM_TO_M3 = 1000000;
  return (
    Math.round(((outerLength * outerWidth * outerHeight) / CM_TO_M3) * 1000) /
    1000
  );
};

// ProductsProviderで同じようなことをしているが、これはある案件内で別の案件の梱包を確認したい場合に使う
export const getProductsByProjectId = async (
  projectId: string
): Promise<Product[]> => {
  const p = (
    await API.graphql({
      query: getProductsByProjectIdG,
      variables: { id: projectId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.productsByProjectId.items;
  return p;
};

export const getProductById = async (productId: string): Promise<Product> => {
  const p = (
    await API.graphql({
      query: getProduct,
      variables: { id: productId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getProduct;
  return p;
};

export const updateProduct = async (input: GraphQLInput) => {
  const product = (
    await API.graphql({
      query: mutations.updateProduct,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.updateProduct;
  return product;
};

// 指定した梱包にComponent(部材)を追加する
export async function addComponent(
  target: "component" | "interior" | "exterior",
  order: number,
  areaId?: string,
  productId?: string
) {
  const slug = shortUUID().generate().toString();
  const componentId = uuid();
  if (target === "component") {
    if (!areaId) return;
    const input = {
      id: componentId,
      areaId,
      name: "新部材",
      slug,
      order,
      elements: createEmptyElements("component", slug),
    };
    await API.graphql({
      query: createComponent,
      variables: { input: input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  }
  if (target === "exterior") {
    if (!productId) return;
    const input = {
      id: componentId,
      productId,
      name: "新外装",
      type: "EXTERIOR",
      slug,
      order,
      elements: createEmptyElements("decoration", slug),
    };
    await API.graphql({
      query: createDecorationComponent,
      variables: { input: input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  }
  if (target === "interior") {
    if (!productId) return;
    const input = {
      id: componentId,
      productId,
      name: "新内装",
      type: "INTERIOR",
      slug,
      order,
      elements: createEmptyElements("decoration", slug),
    };
    await API.graphql({
      query: createDecorationComponent,
      variables: { input: input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  }
}

export async function deleteComponent(
  target: "component" | "decoration",
  id: string
) {
  if (target === "component") {
    await API.graphql({
      query: deleteComponentG,
      variables: {
        input: {
          id,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  } else {
    await API.graphql({
      query: deleteDecorationComponent,
      variables: {
        input: {
          id,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return;
  }
}

// 指定したareaId以下のcomponentを取得する
export async function getComponentsByAreaId(
  areaId: string
): Promise<Component[]> {
  const components = (
    await API.graphql({
      query: componentsByAreaId,
      variables: { areaId: areaId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.componentsByAreaId.items;
  return components;
}

export async function addArea(
  productId: string,
  order: number
): Promise<string> {
  const input = {
    id: uuid(),
    productId,
    name: "新区分",
    order,
    areaType: "COMPONENT",
  };
  const areaId = (
    await API.graphql({
      query: createArea,
      variables: { input: input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.createArea.id;
  return areaId;
}

export async function deleteArea(id: string) {
  await API.graphql({
    query: deleteAreaG,
    variables: {
      input: {
        id,
      },
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
}

// 数字に,を入れていく(3桁ごと)
export function addCommaToNum(target: string): string | null {
  try {
    const num = parseInt(target, 10);
    if (isNaN(num)) return null;
    return num.toLocaleString(); // 3桁ごとにカンマ入れる
  } catch (e) {
    return null;
  }
}

export const updateCaseWithOptimisticLock = async (input: GraphQLInput) => {
  try {
    const res = await API.graphql({
      query: mutations.updateCaseWithOptimisticLock,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (res.errors) {
      throw new Error(res.errors);
    }

    return res.data.updateCaseWithOptimisticLock;
  } catch (err) {
    throw new CustomError(err, "update");
  }
};

export const checkCaseVersion = async (input: GraphQLInput) => {
  try {
    const res = await API.graphql({
      query: queries.checkCaseVersion,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (res.errors) {
      throw new Error(res.errors);
    }

    return res.data.checkCaseVersion;
  } catch (err) {
    throw new CustomError(err, "get");
  }
};
