import { Material } from "API";

// はり受け資材の選定を行う関数
// ここでは、事前の以下の処理を行い実際に該当する部材を計算する関数を呼び出す
// - 必要なパラメータの確認(ceilingLoad, beamLength, sideStrutsCenterSpace, materials)
// - 定数の計算…((天井荷重(ceilingLoad) x 615 / 1000) x 3)/4を定数とする
// - 木端方向許容強さ…beamSupportSpeciesIdのEdgeMPa > mainSpeciesIdのEdgeMPa > mainSpeciesEdgeMPa > 8.1、の優先度
// - 木材のみに絞る…materialsの中で木材のもののみを対象とする
export function JISBeamSupportMaterial(
  ceilingLoad: number | null,
  beamLength: number | null,
  sideStrutsCenterSpace: number | null,
  beamSupportSpeciesId: string | null,
  mainSpeciesId: string | null,
  mainSpeciesEdgeMPa: number | null,
  materials: any,
  species: any
): string {
  // 必要なパラメータの確認
  if (!ceilingLoad || !beamLength || !sideStrutsCenterSpace || !materials) {
    return "";
  }

  // 定数の計算
  const constant = ((((ceilingLoad ?? 0) * 615) / 1000) * 3) / 4;

  // 木端方向許容強さ
  let edgeMPa = 8.1;
  if (beamSupportSpeciesId && beamSupportSpeciesId !== "null") {
    const beamSupportSpecies = species.find(
      (s: any) => s.id === beamSupportSpeciesId
    );
    if (beamSupportSpecies?.edgeMPa) {
      edgeMPa = beamSupportSpecies.edgeMPa;
    } else {
      return "";
    }
  } else if (mainSpeciesId) {
    const mainSpecies = species.find((s: any) => s.id === mainSpeciesId);
    if (mainSpecies?.edgeMPa) {
      edgeMPa = mainSpecies.edgeMPa;
    } else if (mainSpeciesEdgeMPa) {
      edgeMPa = mainSpeciesEdgeMPa;
    }
  }

  // 木材のみに絞る
  const woodMaterials = materials.filter(
    (m: Material) => m.materialType?.isWood ?? false
  );

  return selectBeamSupportMaterial(
    constant,
    beamLength,
    sideStrutsCenterSpace,
    edgeMPa,
    woodMaterials
  );
}

// はり受け部材の選定を行う関数
// 以下の式を満たすmaterialIdを返す
// material.heightNominal * material.widthNominal ^ 2 >= (constant * beamLength * sideStrutsCenterSpace) / edgeMPa
// materialの検索順序は以下の通り
// - materialsの要素に一つでも「はり受け検索順(z1403BeamSupportOrder)」に値があったら、値があるものの中で値の昇順で確認していく
// - 一つもなかったらrefBhhEdgeの小さい順に確認していく
function selectBeamSupportMaterial(
  constant: number,
  beamLength: number,
  sideStrutsCenterSpace: number,
  edgeMPa: number,
  materials: Material[]
): string {
  let targetMaterials: Material[] = [];

  // z1403BeamSupportOrderが設定されているMaterialを抽出
  const z1403BeamSupportOrderMaterials = materials.filter(
    (m) => m.z1403BeamSupportOrder != null
  );

  // 全くなかったらrefBhhEdgeの設定されているものを抽出
  if (z1403BeamSupportOrderMaterials.length === 0) {
    targetMaterials = materials.filter((m) => m.refBhhEdge != null);
    // refBhhEdgeが小さい順にソート
    targetMaterials.sort((a, b) => {
      return (a.refBhhEdge ?? 0) - (b.refBhhEdge ?? 0);
    });
  } else {
    // 対象があったらz1403BeamSupportOrderの昇順に並び替え
    z1403BeamSupportOrderMaterials.sort((a, b) => {
      return (a.z1403BeamSupportOrder ?? 0) - (b.z1403BeamSupportOrder ?? 0);
    });
    targetMaterials = z1403BeamSupportOrderMaterials;
  }

  // 資材の選定
  for (let i = 0; i < targetMaterials.length; i++) {
    const material = targetMaterials[i];
    if (check(constant, beamLength, sideStrutsCenterSpace, edgeMPa, material)) {
      return material.id;
    }
  }
  return "";
}

// 実際に計算する関数
// 以下の式を満たすかどうかを確認する
// material.heightNominal * material.widthNominal ^ 2 >= (constant * beamLength * sideStrutsCenterSpace) / edgeMPa
function check(
  constant: number,
  beamLength: number,
  sideStrutsCenterSpace: number,
  edgeMPa: number,
  materials: Material
): boolean {
  const leftHand =
    (materials.heightNominal ?? 0) * Math.pow(materials.widthNominal ?? 0, 2);
  const rightHand = (constant * beamLength * sideStrutsCenterSpace) / edgeMPa;
  return leftHand >= rightHand;
}
