import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "./auth";
import { useMaterialTypes } from "./materialTypes";

import type { Material } from "API";
import useDatalist from "hooks/datalist";
import { useDialog } from "contexts/dialog";
import { useAlerts } from "contexts/alerts";
import { calcRefBhh } from "utils/material";

interface MaterialsContextValue {
  loading: boolean;
  materials: Material[]; //有効な資材
  allMaterials: Material[]; //全ての資材
  packagingMaterials: Material[];
  secondaryMaterials: Material[];
  create: (input: any) => void;
  update: (input: any) => void;
  remove: (ids: string[]) => void;
  refetch: (variables?: { [key: string]: any }) => Promise<void>;
}

interface MaterialsContextProps {
  children: ReactNode;
  variables?: { [key: string]: any };
}

const MaterialsContext = createContext<MaterialsContextValue>({
  materials: [],
  allMaterials: [],
  packagingMaterials: [],
  secondaryMaterials: [],
  loading: false,
  create: () => null,
  update: () => null,
  remove: () => null,
  refetch: () => Promise.resolve(),
});

export const MaterialsProvider = ({
  children,
  variables,
}: MaterialsContextProps) => {
  const { currentGroup } = useAuth();
  const {
    data,
    loading,
    refetch,
    create,
    remove,
    update,
    nextToken,
    appendNext,
  } = useDatalist({
    query: "materialsByGroupId",
    variables: {
      groupId: currentGroup?.id,
    },
    sort: (a, b) => {
      if (a.width && b.width) return a.width - b.width || a.height - b.height;
      if (a.height && b.height) return a.height - b.height;
      return a.name.localeCompare(b.name);
    },
  });
  const { materialTypes } = useMaterialTypes();
  // materialのrefBhh[Face|Edge]がnullだったら計算してセットする
  const materials = data.map((d: Material) => {
    if (!d.materialType?.isWood) return d;
    if (d.refBhhFace === null || d.refBhhEdge === null) {
      const [refBhhFace, refBhhEdge] = calcRefBhh(
        d.widthNominal,
        d.heightNominal
      );
      d.refBhhFace = refBhhFace;
      d.refBhhEdge = refBhhEdge;
    }
    return d;
  });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken]);

  useEffect(() => {
    refetch({
      groupId: currentGroup?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables, currentGroup?.id]);

  const { open } = useDialog();
  const { addAlert } = useAlerts();

  const createMaterial = async (input: any) => {
    const materialType = materialTypes.find(
      (t) => t.id === input.materialTypeId
    );
    // 該当するmaterialTypeがあったらRefBhhを計算する
    // 作成時にないのはmasterDataのimportくらいなので、ここはスキップしてもOK(元データの方でちゃんと設定されているはず)
    if (materialType && materialType.isWood) {
      const [refBhhFace, refBhhEdge] = calcRefBhh(
        input.widthNominal,
        input.heightNominal
      );
      input.refBhhFace = refBhhFace;
      input.refBhhEdge = refBhhEdge;
    }
    await create("createMaterial", {
      ...input,
      groupId: currentGroup?.id,
    });
    addAlert({ message: "資材を作成しました", severity: "success" });
  };

  const updateMaterial = async (input: any) => {
    // refBhhFaceとrefBhhEdgeを計算して値をセットする
    if (!input.id) return;
    const material: Material = data.find((m: Material) => m.id === input.id);
    if (!material) return;
    if (material?.materialType?.isWood) {
      const [refBhhFace, refBhhEdge] = calcRefBhh(
        input.widthNominal || material.widthNominal,
        input.heightNominal || material.heightNominal
      );
      input.refBhhFace = refBhhFace;
      input.refBhhEdge = refBhhEdge;
    }
    await update("updateMaterial", {
      ...input,
    });
    addAlert({ message: "資材を更新しました", severity: "success" });
  };

  const removeMaterial = async (ids: string[]) => {
    open({
      title: "資材を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => {
        const promises = ids.map((id) =>
          remove("deleteMaterial", {
            id,
          })
        );
        await Promise.all(promises);
        addAlert({ message: "資材を削除しました", severity: "success" });
      },
    });
  };

  return (
    <MaterialsContext.Provider
      value={{
        allMaterials: materials,
        materials: materials && materials.filter((d: Material) => !!d.active),
        packagingMaterials:
          materials &&
          materials.filter(
            (d: Material) => d.materialType?.type === "packaging" && !!d.active
          ),
        secondaryMaterials:
          materials &&
          materials.filter(
            (d: Material) => d.materialType?.type === "secondary" && !!d.active
          ),
        loading,
        create: createMaterial,
        update: updateMaterial,
        remove: removeMaterial,
        refetch: () =>
          refetch({
            groupId: currentGroup?.id,
          }),
      }}
    >
      {children}
    </MaterialsContext.Provider>
  );
};

export const useMaterials = () => {
  const materialsContext = useContext(MaterialsContext);

  if (materialsContext === undefined) {
    throw new Error("useMaterials must be within MaterialsProvider");
  }

  return materialsContext;
};
