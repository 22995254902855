/* eslint-disable react-hooks/exhaustive-deps */
import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect as useReactEffect,
} from "react";
import useEffect from "use-deep-compare-effect";

import type { Quote } from "API";
import useDatalist from "hooks/datalist";
import { GraphQLInput } from "hooks/datalist";
import { useProject } from "contexts/project";
import { useDialog } from "contexts/dialog";

interface QuotesContextValue {
  loading: boolean;
  quotes: Quote[];
  create: (input: GraphQLInput) => Promise<void>;
  remove: (id: string) => Promise<void>;
  refetch: () => Promise<void>;
  copy: (quoteId: string, name: string, quoteNumber: number) => Promise<void>;
}

interface QuotesContextProps {
  children: ReactNode;
  variables: { [key: string]: any };
  by?: "ProjectId";
}

const QuotesContext = createContext<QuotesContextValue>({
  quotes: [],
  loading: false,
  create: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
  copy: () => Promise.resolve(),
});

export const QuotesProvider = ({
  variables,
  children,
  by,
}: QuotesContextProps) => {
  const { data, loading, refetch, create, remove, nextToken, appendNext } =
    useDatalist({
      query: `quotesBy${by || "ProjectId"}`,
      variables: variables,
      sort: "order",
    });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useReactEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
  }, [nextToken]);

  const { open } = useDialog();

  const { project, update } = useProject();

  useEffect(() => {
    refetch(variables);
  }, [variables]);

  const createQuote = async (input: GraphQLInput) => {
    await create("generateQuote", {
      ...input,
      projectId: project?.id,
      order: data.length,
    });
    // 関連するProjectのupdatedを更新
    if (project !== undefined) {
      update({ id: project.id });
    }
  };

  const removeQuote = async (id: string) => {
    open({
      title: "見積を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => await remove("deleteQuote", { id }),
    });
  };

  const copyQuote = async (
    quoteId: string,
    name: string,
    quoteNumber: number
  ) => {
    await create("copyQuote", {
      quoteId: quoteId,
      name: name,
      projectId: project?.id,
      quoteNumber: quoteNumber,
      order: data.length,
    });
    await refetchQuotes();
    // 関連するProjectのupdatedを更新
    if (project !== undefined) {
      update({ id: project.id });
    }
  };

  const refetchQuotes = async () => {
    refetch(variables);
  };

  return (
    <QuotesContext.Provider
      value={{
        quotes: data,
        loading,
        create: createQuote,
        remove: removeQuote,
        refetch: refetchQuotes,
        copy: copyQuote,
      }}
    >
      {children}
    </QuotesContext.Provider>
  );
};

export const useQuotes = () => {
  const quotesContext = useContext(QuotesContext);

  if (quotesContext === undefined) {
    throw new Error("useQuotes must be within QuotesProvider");
  }

  return quotesContext;
};
