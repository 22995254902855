export function calcCombinationSign(
  sideStrutsCenterSpace: number,
  signCalcOuterWidth: number,
  z1402SideBattenLength: number
): number | string {
  if (!sideStrutsCenterSpace || !signCalcOuterWidth || !z1402SideBattenLength) {
    return 999;
  }
  const firstKeys = Object.keys(MATRIX);
  const closestOuterWidth = findClosestKey(signCalcOuterWidth, firstKeys, 0);
  const closestSideBattenLength = findClosestKey(
    z1402SideBattenLength,
    firstKeys,
    1
  );

  const selectedKey = `${closestOuterWidth}x${closestSideBattenLength}`;

  if (!MATRIX[selectedKey]) {
    return 999;
  }

  const sideStructsCenterSpaceKeys = Object.keys(MATRIX[selectedKey]);
  const closestSpacing = findClosestKey(
    sideStrutsCenterSpace,
    sideStructsCenterSpaceKeys,
    0
  );

  const spacingKey = `${closestSpacing}`;

  if (MATRIX[selectedKey][spacingKey] !== undefined) {
    return MATRIX[selectedKey][spacingKey];
  } else {
    return 999;
  }
}

function findClosestKey(value: number, keys: string[], index: number): string {
  const numericKeys = keys
    .map((key) => parseInt(key.split("x")[index]))
    .filter((key) => key >= value);
  if (numericKeys.length === 0) return "No matching key found.";
  const closestKey = Math.min(...numericKeys);
  return closestKey.toString();
}

// 枠組部材の組み合わせ符号選択表
// 1階層目のkeyは外寸幅mm x 側桟の長さmm
// 2階層目のkeyは側支柱の中心間隔mm
interface MatrixData {
  [key: string]: {
    [key: string]: number;
  };
}

const MATRIX: MatrixData = {
  "500x500": {
    "600": 1,
    "750": 1,
    "910": 1,
    "1050": 1,
    "1200": 1,
  },
  "500x750": {
    "600": 2,
    "750": 3,
    "910": 3,
    "1050": 3,
    "1200": 3,
  },
  "500x1000": {
    "600": 3,
    "750": 4,
    "910": 5,
    "1050": 5,
    "1200": 5,
  },
  "500x1250": {
    "600": 5,
    "750": 5,
    "910": 5,
    "1050": 5,
    "1200": 6,
  },
  "500x1500": {
    "600": 6,
    "750": 6,
    "910": 6,
    "1050": 6,
    "1200": 6,
  },
  "500x1750": {
    "600": 7,
    "750": 7,
    "910": 7,
    "1050": 7,
    "1200": 7,
  },
  "500x2000": {
    "600": 8,
    "750": 8,
    "910": 8,
    "1050": 8,
    "1200": 8,
  },
  "500x2250": {
    "600": 10,
    "750": 10,
    "910": 10,
    "1050": 10,
    "1200": 10,
  },
  "750x500": {
    "600": 1,
    "750": 1,
    "910": 2,
    "1050": 3,
    "1200": 3,
  },
  "750x750": {
    "600": 3,
    "750": 3,
    "910": 4,
    "1050": 4,
    "1200": 5,
  },
  "750x1000": {
    "600": 5,
    "750": 5,
    "910": 5,
    "1050": 5,
    "1200": 6,
  },
  "750x1250": {
    "600": 5,
    "750": 6,
    "910": 6,
    "1050": 6,
    "1200": 6,
  },
  "750x1500": {
    "600": 6,
    "750": 6,
    "910": 7,
    "1050": 7,
    "1200": 7,
  },
  "750x1750": {
    "600": 7,
    "750": 7,
    "910": 7,
    "1050": 8,
    "1200": 8,
  },
  "750x2000": {
    "600": 8,
    "750": 8,
    "910": 8,
    "1050": 8,
    "1200": 8,
  },
  "750x2250": {
    "600": 10,
    "750": 10,
    "910": 10,
    "1050": 10,
    "1200": 10,
  },
  "1000x500": {
    "600": 1,
    "750": 2,
    "910": 3,
    "1050": 3,
    "1200": 3,
  },
  "1000x750": {
    "600": 3,
    "750": 4,
    "910": 5,
    "1050": 5,
    "1200": 5,
  },
  "1000x1000": {
    "600": 5,
    "750": 5,
    "910": 6,
    "1050": 6,
    "1200": 6,
  },
  "1000x1250": {
    "600": 6,
    "750": 6,
    "910": 6,
    "1050": 7,
    "1200": 7,
  },
  "1000x1500": {
    "600": 6,
    "750": 7,
    "910": 7,
    "1050": 7,
    "1200": 8,
  },
  "1000x1750": {
    "600": 7,
    "750": 7,
    "910": 9,
    "1050": 9,
    "1200": 10,
  },
  "1000x2000": {
    "600": 9,
    "750": 9,
    "910": 10,
    "1050": 10,
    "1200": 10,
  },
  "1000x2250": {
    "600": 10,
    "750": 10,
    "910": 11,
    "1050": 11,
    "1200": 11,
  },
  "1250x500": {
    "600": 2,
    "750": 3,
    "910": 3,
    "1050": 4,
    "1200": 4,
  },
  "1250x750": {
    "600": 4,
    "750": 5,
    "910": 5,
    "1050": 5,
    "1200": 5,
  },
  "1250x1000": {
    "600": 5,
    "750": 6,
    "910": 6,
    "1050": 6,
    "1200": 6,
  },
  "1250x1250": {
    "600": 6,
    "750": 6,
    "910": 7,
    "1050": 7,
    "1200": 7,
  },
  "1250x1500": {
    "600": 7,
    "750": 7,
    "910": 8,
    "1050": 8,
    "1200": 8,
  },
  "1250x1750": {
    "600": 8,
    "750": 8,
    "910": 10,
    "1050": 10,
    "1200": 10,
  },
  "1250x2000": {
    "600": 10,
    "750": 10,
    "910": 10,
    "1050": 11,
    "1200": 11,
  },
  "1250x2250": {
    "600": 10,
    "750": 10,
    "910": 11,
    "1050": 11,
    "1200": 11,
  },
  "1500x500": {
    "600": 3,
    "750": 3,
    "910": 4,
    "1050": 5,
    "1200": 5,
  },
  "1500x750": {
    "600": 5,
    "750": 5,
    "910": 5,
    "1050": 6,
    "1200": 6,
  },
  "1500x1000": {
    "600": 6,
    "750": 6,
    "910": 6,
    "1050": 7,
    "1200": 7,
  },
  "1500x1250": {
    "600": 6,
    "750": 7,
    "910": 7,
    "1050": 9,
    "1200": 9,
  },
  "1500x1500": {
    "600": 8,
    "750": 9,
    "910": 10,
    "1050": 10,
    "1200": 10,
  },
  "1500x1750": {
    "600": 8,
    "750": 9,
    "910": 10,
    "1050": 10,
    "1200": 11,
  },
  "1500x2000": {
    "600": 10,
    "750": 10,
    "910": 11,
    "1050": 11,
    "1200": 11,
  },
  "1500x2250": {
    "600": 10,
    "750": 11,
    "910": 11,
    "1050": 11,
    "1200": 11,
  },
};
