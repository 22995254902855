import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "./auth";

import type { MaterialType } from "API";
import useDatalist from "hooks/datalist";
import { useDialog } from "contexts/dialog";
import { useAlerts } from "contexts/alerts";

interface MaterialTypesContextValue {
  loading: boolean;
  materialTypes: MaterialType[];
  packagingMaterialTypes: MaterialType[];
  secondaryMaterialTypes: MaterialType[];
  create: (input: any) => void;
  update: (input: any) => void;
  remove: (ids: string[]) => void;
}

interface MaterialTypesContextProps {
  children: ReactNode;
  variables?: { [key: string]: any };
}

const MaterialTypesContext = createContext<MaterialTypesContextValue>({
  materialTypes: [],
  packagingMaterialTypes: [],
  secondaryMaterialTypes: [],
  loading: false,
  create: () => null,
  update: () => null,
  remove: () => null,
});

export const MaterialTypesProvider = ({
  children,
  variables,
}: MaterialTypesContextProps) => {
  const { currentGroup } = useAuth();
  const {
    data,
    loading,
    refetch,
    create,
    remove,
    update,
    nextToken,
    appendNext,
  } = useDatalist({
    query: "materialTypesByGroupId",
    variables: {
      groupId: currentGroup?.id,
    },
    sort: (a, b) => {
      if (a.width && b.width) return a.width - b.width || a.height - b.height;
      if (a.height && b.height) return a.height - b.height;
      return a.name.localeCompare(b.name);
    },
  });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken]);

  useEffect(() => {
    refetch({ groupId: currentGroup?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables, currentGroup?.id]);

  const { open } = useDialog();
  const { addAlert } = useAlerts();

  const createMaterialType = async (input: any) => {
    // inputにcreatedAt, updatedAt, materialsがあったら消す
    delete input.createdAt;
    delete input.updatedAt;
    delete input.materials;
    if (input.customFields) {
      input.customFields.forEach((c: any) => {
        delete c.__typename;
      });
    }
    await create("createMaterialType", {
      ...input,
      groupId: currentGroup?.id,
    });
    addAlert({ message: "資材分類を作成しました", severity: "success" });
  };

  const updateMaterialType = async (input: any) => {
    // inputにcreatedAt, updatedAt, materialsがあったら消す
    delete input.createdAt;
    delete input.updatedAt;
    delete input.materials;
    await update("updateMaterialType", {
      ...input,
    });
    addAlert({ message: "資材分類を更新しました", severity: "success" });
  };

  const removeMaterialType = async (ids: string[]) => {
    open({
      title: "資材分類を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => {
        const promises = ids.map((id) =>
          remove("deleteMaterialType", {
            id,
          })
        );
        await Promise.all(promises);
        addAlert({ message: "資材分類を削除しました", severity: "success" });
      },
    });
  };

  return (
    <MaterialTypesContext.Provider
      value={{
        materialTypes: data,
        packagingMaterialTypes:
          data && data.filter((d: MaterialType) => d.type === "packaging"),
        secondaryMaterialTypes:
          data && data.filter((d: MaterialType) => d.type === "secondary"),
        loading,
        create: createMaterialType,
        update: updateMaterialType,
        remove: removeMaterialType,
      }}
    >
      {children}
    </MaterialTypesContext.Provider>
  );
};

export const useMaterialTypes = () => {
  const materialTypesContext = useContext(MaterialTypesContext);

  if (materialTypesContext === undefined) {
    throw new Error("useMaterialTypes must be within MaterialTypesProvider");
  }

  return materialTypesContext;
};
