import { GraphQLResult } from "@aws-amplify/api-graphql";

function isGraphQLResult(error: unknown): error is GraphQLResult {
  return (
    typeof error === "object" &&
    error !== null &&
    "errors" in error &&
    Array.isArray((error as any).errors) &&
    (error as any).errors.every((e: any) => typeof e.message === "string")
  );
}

const errorMapper = {
  delete: "削除",
  update: "更新",
  get: "取得",
  create: "作成",
} as const;

export class CustomError extends Error {
  constructor(err: unknown, type: keyof typeof errorMapper) {
    // 詳細エラーメッセージは開発コンソールに表示
    // eslint-disable-next-line no-console
    console.error(err);

    super(`データの${errorMapper[type]}に失敗しました。`);
    const customMessage = this.getCustomErrorMessage(err);
    if (!customMessage) return;
    this.setErrorMessage(customMessage);
  }

  private getCustomErrorMessage(err: unknown) {
    let message = "";
    if (isGraphQLResult(err)) {
      if (err.errors?.some((error) => error.message.match(/locking error/)))
        message +=
          "データが他のユーザーによって変更されています。リロードして再試行してください。\n";
    }

    return message;
  }

  private setErrorMessage(customMessage: string) {
    this.message = customMessage;
  }
}
