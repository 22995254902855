export const getAllProduct = /* GraphQL */ `
  query GetAllProduct($id: ID!) {
    getProduct(id: $id) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      packageTypeName
      quoteLineId
      name
      order
      productNumber
      quantity
      material
      tareWeight
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      panelM2
      woodM3
      note
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        thumbnail
        note
      }
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        isShipped
        createdAt
        updatedAt
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
              elements {
                type
                name
                slug
                value
                expr
                description
                overwriteValue
                cutting
              }
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getProductsByProjectId = /* GraphQL */ `
  query GetProductsByProjectId($id: ID!) {
    productsByProjectId(projectId: $id) {
      items {
        id
        projectId
        packageTypeId
        scheduleId
        userId
        pricingId
        packageTypeName
        quoteLineId
        name
        order
        productNumber
        quantity
        material
        tareWeight
        stamp
        destination
        packagingStart
        packagingEnd
        packageMethod
        description
        readyToPack
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        cases {
          order
          name
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          phaseId
          isPacked
          netWeight
          grossWeight
          outerLength
          outerWidth
          outerHeight
          thumbnail
        }
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        schedule {
          id
          projectId
          name
          m3
          case
          shipType
          stockingDate
          packagingDate
          shippingDate
          cutDate
          numImgs
          isShipped
          createdAt
          updatedAt
        }
        areas {
          items {
            id
            productId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              items {
                id
                areaId
                name
                slug
                order
                createdAt
                updatedAt
                elements {
                  type
                  name
                  slug
                  value
                  expr
                  description
                  overwriteValue
                  cutting
                }
              }
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            productId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getProductIncludeComponentElements = /* GraphQL */ `
  query GetProductIncludeComponentElements($id: ID!) {
    getProduct(id: $id) {
      id
      pricingId
      unit
      name
      order
      quantity
      tareWeight
      material
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              elements {
                type
                name
                slug
                value
                expr
                description
                overwriteValue
                cutting
              }
            }
          }
        }
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
