import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Link from "next/link";
import { useEffect, useState } from "react";
import { List } from "@mui/material";
import { useRouter } from "next/router";
import Avatar from "@mui/material/Avatar";
import { green } from "@mui/material/colors";

import { useInvoices } from "contexts/invoices";
import { useProject } from "contexts/project";

interface Props {
  toggleDrawer: () => void;
}

const InvoiceItems = ({ toggleDrawer }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState("project");
  const router = useRouter();
  const { project } = useProject();
  const { invoices, loading } = useInvoices();

  const { query } = router;

  useEffect(() => {
    if (!!query.invoiceId) {
      setCurrentPage(query.invoiceId as string);
      return setOpen(true);
    }
    setCurrentPage(query.page as string);
  }, [query]);

  if (!project || !invoices) return null;

  const handleClick = () => {
    toggleDrawer();
    router.push(`/projects/${project.id}?page=invoice`);
  };

  return (
    <>
      <div className="flex justify-between">
        <ListItemButton
          onClick={handleClick}
          selected={currentPage === "invoice"}
        >
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="請求" />
        </ListItemButton>
        <div className="cursor-pointer flex items-center mr-4">
          {open ? (
            <ExpandLess onClick={() => setOpen(false)} />
          ) : (
            <ExpandMore onClick={() => setOpen(true)} />
          )}
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {loading && invoices.length === 0 && <LinearProgress />}
          {invoices.map((invoice) => (
            <Link
              key={`item_${invoice.id}`}
              href={`/projects/${project.id}?page=invoices&invoiceId=${invoice.id}`}
              prefetch={false}
            >
              <ListItemButton
                sx={{ pl: 4 }}
                selected={currentPage === invoice.id}
              >
                <ListItemIcon>
                  <Avatar
                    sx={{
                      bgcolor: invoice.billed ? green[800] : "gray",
                      width: 30,
                      height: 30,
                      fontSize: 15,
                    }}
                    aria-label="status"
                  >
                    {invoice.billed ? "済" : "未"}
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={invoice.name} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default InvoiceItems;
