import type { Bom } from "./update";

export function parseBom(data: any): Bom | undefined {
  if (!data) return undefined;
  const bom = {
    product: {
      ...data.product,
      elements: JSON.parse(data.product?.elements),
    },
    components: data.components.map((component: any) => ({
      ...component,
      elements: JSON.parse(component.elements),
    })),
    interiors:
      data.interiors?.map((interior: any) => ({
        ...interior,
        elements: JSON.parse(interior.elements),
      })) ?? [],
    exteriors:
      data.exteriors?.map((exterior: any) => ({
        ...exterior,
        elements: JSON.parse(exterior.elements),
      })) ?? [],
  } as Bom;
  return bom;
}

// useBomで取得できるbomをupdateに流し込める形式に変換する
// getProductBomで取得したbomを流し込みたい場合はparseBomの結果を入れてください
export function makeUpdataleBom(bom: Bom): any {
  const updatableBom = {
    product: {
      ...bom?.product,
      elements: Object.values(bom?.product?.elements ?? {}),
    },
    components: bom?.components?.map((component) => ({
      ...component,
      elements: Object.values(component.elements),
    })),
    decorations: [
      ...(bom?.interiors?.map((interior) => ({
        ...interior,
        elements: Object.values(interior.elements),
      })) ?? []),
      ...(bom?.exteriors?.map((exterior) => ({
        ...exterior,
        elements: Object.values(exterior.elements),
      })) ?? []),
    ],
  };
  return updatableBom;
}
