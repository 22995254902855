import { Material, MaterialType } from "API";

export const getMaterialName = (
  materials: Material[],
  materialId: string | null | undefined
): string => {
  if (!materialId) return "----";
  const material = materials.filter((m) => m.id === materialId)[0];
  if (!material) return "NOT FOUND";
  return material.name;
};

// materialとmaterialTypeを利用して、materialのdropdownのitemsを作成する
export const getMaterialDropdownItems = (
  materials: Material[],
  materialTypes: MaterialType[]
): { value: string; label: string }[] => {
  if (materialTypes.length === 0) return [];
  if (materials.length === 0) return [];
  const sorted: Map<string, Material[]> = groupAndSortMaterial(
    materials,
    materialTypes
  );

  // ソートしたものを平坦化する、materialTypeNameも追加する
  const materialsWithPrefix = Array.from(sorted.entries()).reduce(
    (acc, [key, value]) => {
      const materialType = materialTypes.filter((m) => m.id === key)[0];
      acc.push({
        value: "INVALID_ID",
        label: `== 梱包分類: ${materialType?.name} ==`,
      });
      value.forEach((m) => {
        acc.push({ value: m.id, label: m.name });
      });
      return acc;
    },
    [] as { value: string; label: string }[]
  );
  return materialsWithPrefix;
};

// withが小さい順(widthが一緒ならheightが小さい順)にする
// with/heightがnullの場合は名前順
export const sortMaterialsBySize = (materials: Material[]): Material[] => {
  return materials.sort((a, b) => {
    // widthが小さい順(widthが一緒だったらheightが小さい順)
    const aWith = a.width ?? -1;
    const bWith = b.width ?? -1;
    if (aWith !== bWith) return aWith < bWith ? -1 : 1;
    const aHeight = a.height ?? -1;
    const bHeight = b.height ?? -1;
    if (aHeight !== bHeight) return aHeight < bHeight ? -1 : 1;
    // 名前順
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
};

// materialをmaterialTypesでgroupingし、sizeでソートする
export const groupAndSortMaterial = (
  materials: Material[],
  materialTypes: MaterialType[]
): Map<string, Material[]> => {
  // materialTypeIdでgroup化
  const grouped: Map<string, Material[]> = materials.reduce((acc, cur) => {
    const key = cur.materialTypeId;
    const existing = acc.get(key) || [];
    existing.push(cur);
    acc.set(key, existing);
    return acc;
  }, new Map<string, Material[]>());

  // それぞれのgroup内でmaterialをnameでsort
  // withが小さい順(widthが一緒ならheightが小さい順)にする
  // 保存はmapのまま
  const sorted: Map<string, Material[]> = Array.from(grouped.entries()).reduce(
    (acc, [key, value]) => {
      const sorted = sortMaterialsBySize(value);
      acc.set(key, sorted);
      return acc;
    },
    new Map<string, Material[]>()
  );

  return sorted;
};

// refBhhEdge(参考用bhh(木端方向))とrefBhhFace(参考用bhh(平方向))の計算をする
// refBhhFace = 呼び寸巾(widthNominal)ｘ呼び寸高さ(heightNominal)^2
// refBhhEdge = 呼び寸高さ(heightNominal)ｘ呼び寸巾(widthNominal)^2
// 返り値は[refBhhFace, refBhhEdge]
export const calcRefBhh = (
  widthNominal?: number | null,
  heightNominal?: number | null
): [number, number] => {
  return [
    (widthNominal ?? 0) * (heightNominal ?? 0) ** 2,
    (heightNominal ?? 0) * (widthNominal ?? 0) ** 2,
  ];
};

const _getElement = (elements: any, slug: string) => {
  if (!elements) return 0;
  return (
    (
      Object.values(elements).filter(
        (el: any) => el?.slug && el?.slug?.includes(`.${slug}`)
      )[0] as any
    ).value || 0
  );
};

const _getMaterialAmount = (els: any, materialUnit: string) => {
  let amount = 0;

  try {
    // materialUnitに対応するslugがelsにない場合はcatchのquantityを取得することになる
    switch (materialUnit) {
      case "length":
        amount = _getElement(els, "length");
        break;
      case "width":
        amount = _getElement(els, "width");
        break;
      case "height":
        amount = _getElement(els, "height");
        break;
      case "m2":
        amount = _getElement(els, "m2");
        break;
      case "m3":
        amount = _getElement(els, "m3");
        break;
      default:
        amount = _getElement(els, "quantity");
        break;
    }
  } catch (e) {
    amount = _getElement(els, "quantity");
  }
  return amount;
};

export const estimateComponentCost = (
  components: any,
  elements: any,
  materials: any
) =>
  Object.entries(elements).reduce((result: any, [id, els]: [string, any]) => {
    const materialId = _getElement(els, "materialId");
    const material = materials.filter((m: any) => m.id === materialId)[0];
    const component = components.filter((c: any) => c.id === id)[0];

    if (!material) {
      // console.log("material not found", component);
      result.push({
        ...els,
        name: component.name,
        materialName: "-",
        materialUnit: "-",
        defaultUnitCost: 0,
        lossRate: 0,
        amount: 0,
        cost: 0,
      });
      return result;
    }

    const { name, lossRate, defaultUnitCost } = material;

    const amount = _getMaterialAmount(els, material.unit);

    const cost = amount * (1 + lossRate) * defaultUnitCost;

    // console.log("material found", { name, defaultUnitCost, lossRate, amount });

    result.push({
      ...els,
      name: component.name,
      materialName: name,
      materialUnit: material.unit,
      defaultUnitCost,
      lossRate,
      amount,
      cost,
    });

    return result;
  }, []);

export const estimateCost = (
  elements: any,
  materials: any,
  materialTypeName: string = ""
) =>
  Object.values(elements).reduce((result: any, els) => {
    const materialId = _getElement(els, "materialId");
    const material = materials.filter((m: any) => m.id === materialId)[0];

    if (!material) return result;

    // materialのmaterialTypeが設定されてなかったらスキップ
    if (!material.materialType) return result;

    // materialTypeNameが指定されていて(空文字以外)、materialTypeが一致しない場合はスキップ
    if (
      materialTypeName !== "" &&
      material?.materialType?.name !== materialTypeName
    ) {
      return result;
    }
    const { lossRate, defaultUnitCost } = material;

    const amount = _getMaterialAmount(els, material.unit);

    const cost = defaultUnitCost * (amount * (1 + lossRate));

    return result + Math.round(cost);
  }, 0);
