import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useAuth } from "./auth";

import type { Group, UsersGroups } from "API";
import useData, { GraphQLInput } from "hooks/data";

interface GroupContextValue {
  loading: boolean;
  group: Group | undefined;
  users: UsersGroups[] | [];
  update: (input: GraphQLInput) => void;
  refetch: () => Promise<void>;
  set: (input: GraphQLInput) => void;
}

interface GroupContextProps {
  caseOrder?: string | null | undefined;
  children: ReactNode;
}

const GroupContext = createContext<GroupContextValue>({
  group: undefined,
  users: [],
  loading: false,
  update: () => null,
  refetch: () => Promise.resolve(),
  set: () => Promise.resolve,
});

export const GroupProvider = ({ children }: GroupContextProps) => {
  const { currentGroup } = useAuth();
  const [users, setUsers] = useState<UsersGroups[]>([]);
  const { data, loading, refetch, update, set } = useData({
    object: "group",
    variables: {
      id: currentGroup?.id,
    },
  });

  useEffect(() => {
    refetch({
      id: currentGroup?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroup]);
  useEffect(() => {
    if (!data) return setUsers([]);
    setUsers(data.users.items);
  }, [data]);

  const values = useMemo(
    () => ({
      group: data,
      users,
      update,
      loading,
      refetch: () => refetch({ id: currentGroup?.id }),
      set,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, update, loading, users, set]
  );

  return (
    <GroupContext.Provider value={values}>{children}</GroupContext.Provider>
  );
};

export const useGroup = () => {
  const groupContext = useContext(GroupContext);

  if (groupContext === undefined) {
    throw new Error("useGroup must be within GroupProvider");
  }

  return groupContext;
};
