/* eslint-disable react-hooks/exhaustive-deps */
import useEffect from "use-deep-compare-effect";
import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect as useReactEffect,
} from "react";

import { useMaterials } from "./materials";
import { useSpecies } from "./species";
import { useAuth } from "./auth";

import type { Product } from "API";
import useDatalist from "hooks/datalist";
import { GraphQLInput, LoadOptions } from "hooks/datalist";
import { useProject } from "contexts/project";
import { useDialog } from "contexts/dialog";
import { getUpdatedCaseInputByProductNumber } from "utils/product";
import { updateArbitraryProductBomElement } from "utils/bom";

interface ProductsContextValue {
  loading: boolean;
  creating: boolean;
  copying: boolean;
  products: Product[];
  create: (input: GraphQLInput) => Promise<void>;
  createNonPack: (input: GraphQLInput) => Promise<void>;
  copy: (input: GraphQLInput) => Promise<void>;
  update: (input: GraphQLInput) => Promise<void>;
  remove: (id: string) => Promise<void>;
  link: (productId: string, quoteLineId: string) => Promise<void>;
  unlink: (productId: string, quoteLineId: string) => Promise<void>;
  refetch: (options?: LoadOptions) => Promise<void>;
}

interface ProductsContextProps {
  children: ReactNode;
  variables: { [key: string]: any };
  by?: "ProjectId" | "ScheduleId" | "PackageTypeId";
}

const ProductsContext = createContext<ProductsContextValue>({
  products: [],
  loading: false,
  creating: false,
  copying: false,
  create: () => Promise.resolve(),
  createNonPack: () => Promise.resolve(),
  copy: () => Promise.resolve(),
  update: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  link: () => Promise.resolve(),
  unlink: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
});

export const ProductsProvider = ({
  variables,
  children,
  by,
}: ProductsContextProps) => {
  const {
    data,
    loading,
    creating,
    refetch,
    create,
    remove,
    update,
    appendNext,
    nextToken,
  } = useDatalist({
    query: `productsBy${by || "ProjectId"}`,
    variables,
    sort: "order",
  });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useReactEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
  }, [nextToken]);
  const [copying, setCopying] = useState(false);

  useEffect(() => {
    refetch(variables);
  }, [variables]);

  const { project, update: updateProject } = useProject();
  const { allMaterials } = useMaterials();
  const { species } = useSpecies();
  const { currentGroup } = useAuth();
  const { open } = useDialog();

  const createProduct = async (input: GraphQLInput) => {
    const { id: productId } = (await create("createProductBom", {
      ...input,
      projectId: project?.id,
      order: data.length,
    })) as unknown as { id: string };
    // 作成したProductのElementに空更新をかけてCost関連を設定させる
    if (currentGroup) {
      // 更新漏れを防ぐため2回更新を流す
      await updateArbitraryProductBomElement(
        productId,
        { id: productId },
        allMaterials,
        species,
        currentGroup,
        true
      );
      await updateArbitraryProductBomElement(
        productId,
        { id: productId },
        allMaterials,
        species,
        currentGroup,
        true
      );
    }
    // 関連するProjectのupdatedを更新
    if (project !== undefined) {
      updateProject({ id: project.id });
    }
  };

  const createNonPackProduct = async (input: GraphQLInput) => {
    await create("createProduct", {
      ...input,
      packageTypeId: "NonPack",
      packageTypeName: "NonPack",
      projectId: project?.id,
      order: data.length,
    });
    // 関連するProjectのupdatedを更新
    if (project !== undefined) {
      updateProject({ id: project.id });
    }
  };

  const copyProduct = async (input: GraphQLInput) => {
    setCopying(true);
    await create("copyProduct", {
      ...input,
      order: data.length,
    });
    setCopying(false);
    // 関連するProjectのupdatedを更新
    if (project !== undefined) {
      updateProject({ id: project.id });
    }
  };

  const updateProduct = async (input: GraphQLInput) => {
    if (!data) return;

    // 指示書Noが更新された時はケースの名前も一緒に更新
    if (input.productNumber) {
      const cases = getUpdatedCaseInputByProductNumber(data, input);
      input = {
        ...input,
        cases: cases,
      };
    }

    await update("updateProduct", {
      ...input,
    });
  };

  // TODO: delete photos of product
  const removeProduct = async (id: string) => {
    open({
      title: "貨物を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => await remove("deleteProductBom", { productId: id }),
    });
  };

  const linkProductToQuoteLine = async (
    productId: string,
    quoteLineId: string
  ) => {
    await update("linkProductToQuoteLine", {
      productId,
      quoteLineId,
    });
  };

  const unlinkProductFromQuoteLine = async (
    productId: string,
    quoteLineId: string
  ) => {
    await update("unlinkProductFromQuoteLine", {
      productId,
      quoteLineId,
    });
  };

  return (
    <ProductsContext.Provider
      value={{
        products: data,
        loading,
        creating,
        copying,
        create: createProduct,
        createNonPack: createNonPackProduct,
        copy: copyProduct,
        update: updateProduct,
        remove: removeProduct,
        link: linkProductToQuoteLine,
        unlink: unlinkProductFromQuoteLine,
        refetch: async (options) => await refetch(variables, options),
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = () => {
  const productsContext = useContext(ProductsContext);

  if (productsContext === undefined) {
    throw new Error("useProducts must be within ProductsProvider");
  }

  return productsContext;
};
