export function calcCombinationSign(
  nw: number,
  sideStrutsCenterSpace: number,
  signCalcOuterWidth: number,
  innerHeight: number
): number | string {
  if (!nw || !sideStrutsCenterSpace || !signCalcOuterWidth || !innerHeight) {
    return 999;
  }
  const firstKeys = Object.keys(MATRIX);
  const closestOuterWidth = findClosestKey(signCalcOuterWidth, firstKeys, 0);
  const closestInnerHeight = findClosestKey(innerHeight, firstKeys, 1);

  const selectedKey = `${closestOuterWidth}x${closestInnerHeight}`;

  if (!MATRIX[selectedKey]) {
    return 999;
  }

  const nwSpacingKeys = Object.keys(MATRIX[selectedKey]);
  const closestNW = findClosestKey(nw, nwSpacingKeys, 0);
  const closestSpacing = findClosestKey(
    sideStrutsCenterSpace,
    nwSpacingKeys,
    1
  );

  const weightSpacingKey = `${closestNW}x${closestSpacing}`;

  if (MATRIX[selectedKey][weightSpacingKey] !== undefined) {
    return MATRIX[selectedKey][weightSpacingKey];
  } else {
    return 999;
  }
}

function findClosestKey(value: number, keys: string[], index: number): string {
  const numericKeys = keys
    .map((key) => parseInt(key.split("x")[index]))
    .filter((key) => key >= value);
  if (numericKeys.length === 0) return "No matching key found.";
  const closestKey = Math.min(...numericKeys);
  return closestKey.toString();
}

// 枠組部材の組み合わせ符号選択表
// 1階層目のkeyは外寸幅mm x 内寸高さmm
// 2階層目のkeyは重量kg x 支柱中心間隔mm
interface MatrixData {
  [key: string]: {
    [key: string]: number;
  };
}
const MATRIX: MatrixData = {
  "1000x1000": {
    "1000x600": 1,
    "1000x750": 1,
    "1000x910": 1,
    "1000x1050": 1,
    "1000x1230": 1,
    "2000x600": 1,
    "2000x750": 1,
    "2000x910": 1,
    "2000x1050": 1,
    "2000x1230": 1,
    "3000x600": 11,
    "3000x750": 11,
    "3000x910": 11,
    "3000x1050": 11,
    "3000x1230": 11,
    "4000x600": 21,
    "4000x750": 21,
    "4000x910": 21,
    "4000x1050": 21,
    "4000x1230": 21,
    "5000x600": 21,
    "5000x750": 21,
    "5000x910": 21,
    "5000x1050": 21,
    "5000x1230": 21,
    "6500x600": 31,
    "6500x750": 31,
    "6500x910": 31,
    "6500x1050": 31,
    "6500x1230": 31,
    "8000x600": 31,
    "8000x750": 31,
    "8000x910": 31,
    "8000x1050": 31,
    "8000x1230": 31,
    "10000x600": 31,
    "10000x750": 31,
    "10000x910": 31,
    "10000x1050": 31,
    "10000x1230": 31,
    "12500x600": 31,
    "12500x750": 31,
    "12500x910": 31,
    "12500x1050": 31,
    "12500x1230": 31,
    "15500x600": 31,
    "15500x750": 31,
    "15500x910": 31,
    "15500x1050": 31,
    "15500x1230": 31,
    "20000x600": 41,
    "20000x750": 41,
    "20000x910": 41,
    "20000x1050": 41,
    "20000x1230": 41,
    "25000x600": 41,
    "25000x750": 41,
    "25000x910": 41,
    "25000x1050": 41,
    "25000x1230": 41,
    "30000x600": 51,
    "30000x750": 51,
    "30000x910": 51,
    "30000x1050": 51,
    "30000x1230": 51,
    "35000x600": 51,
    "35000x750": 51,
    "35000x910": 51,
    "35000x1050": 51,
    "35000x1230": 51,
    "40000x600": 51,
    "40000x750": 51,
    "40000x910": 51,
    "40000x1050": 51,
    "40000x1230": 51,
    "50000x600": 51,
    "50000x750": 51,
    "50000x910": 51,
    "50000x1050": 51,
    "50000x1230": 51,
    "60000x600": 61,
    "60000x750": 61,
    "60000x910": 61,
    "60000x1050": 61,
    "60000x1230": 61,
  },
  "1000x1500": {
    "1000x600": 1.0,
    "1000x750": 11.0,
    "1000x910": 2.0,
    "1000x1050": 2.0,
    "1000x1230": 2.0,
    "2000x600": 11.0,
    "2000x750": 11.0,
    "2000x910": 11.0,
    "2000x1050": 11.0,
    "2000x1230": 12.0,
    "3000x600": 11.0,
    "3000x750": 11.0,
    "3000x910": 11.0,
    "3000x1050": 11.0,
    "3000x1230": 12.0,
    "4000x600": 21.0,
    "4000x750": 21.0,
    "4000x910": 21.0,
    "4000x1050": 21.0,
    "4000x1230": 21.0,
    "5000x600": 21.0,
    "5000x750": 21.0,
    "5000x910": 21.0,
    "5000x1050": 21.0,
    "5000x1230": 21.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 31.0,
    "6500x1050": 31.0,
    "6500x1230": 31.0,
    "8000x600": 31.0,
    "8000x750": 31.0,
    "8000x910": 31.0,
    "8000x1050": 31.0,
    "8000x1230": 31.0,
    "10000x600": 31.0,
    "10000x750": 31.0,
    "10000x910": 31.0,
    "10000x1050": 31.0,
    "10000x1230": 31.0,
    "12500x600": 31.0,
    "12500x750": 31.0,
    "12500x910": 31.0,
    "12500x1050": 31.0,
    "12500x1230": 31.0,
    "15500x600": 31.0,
    "15500x750": 31.0,
    "15500x910": 31.0,
    "15500x1050": 31.0,
    "15500x1230": 31.0,
    "20000x600": 41.0,
    "20000x750": 41.0,
    "20000x910": 41.0,
    "20000x1050": 41.0,
    "20000x1230": 41.0,
    "25000x600": 41.0,
    "25000x750": 41.0,
    "25000x910": 41.0,
    "25000x1050": 41.0,
    "25000x1230": 41.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 51.0,
    "35000x1050": 51.0,
    "35000x1230": 51.0,
    "40000x600": 51.0,
    "40000x750": 51.0,
    "40000x910": 51.0,
    "40000x1050": 51.0,
    "40000x1230": 51.0,
    "50000x600": 51.0,
    "50000x750": 51.0,
    "50000x910": 51.0,
    "50000x1050": 51.0,
    "50000x1230": 51.0,
    "60000x600": 61.0,
    "60000x750": 61.0,
    "60000x910": 61.0,
    "60000x1050": 61.0,
    "60000x1230": 61.0,
  },
  "1000x2000": {
    "1000x600": 2.0,
    "1000x750": 2.0,
    "1000x910": 2.0,
    "1000x1050": 2.0,
    "1000x1230": 2.0,
    "2000x600": 12.0,
    "2000x750": 12.0,
    "2000x910": 12.0,
    "2000x1050": 12.0,
    "2000x1230": 12.0,
    "3000x600": 12.0,
    "3000x750": 12.0,
    "3000x910": 12.0,
    "3000x1050": 12.0,
    "3000x1230": 12.0,
    "4000x600": 21.0,
    "4000x750": 21.0,
    "4000x910": 22.0,
    "4000x1050": 22.0,
    "4000x1230": 22.0,
    "5000x600": 21.0,
    "5000x750": 21.0,
    "5000x910": 22.0,
    "5000x1050": 22.0,
    "5000x1230": 22.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 31.0,
    "6500x1050": 31.0,
    "6500x1230": 31.0,
    "8000x600": 31.0,
    "8000x750": 31.0,
    "8000x910": 31.0,
    "8000x1050": 31.0,
    "8000x1230": 32.0,
    "10000x600": 31.0,
    "10000x750": 31.0,
    "10000x910": 31.0,
    "10000x1050": 31.0,
    "10000x1230": 32.0,
    "12500x600": 31.0,
    "12500x750": 31.0,
    "12500x910": 31.0,
    "12500x1050": 31.0,
    "12500x1230": 32.0,
    "15500x600": 31.0,
    "15500x750": 31.0,
    "15500x910": 31.0,
    "15500x1050": 31.0,
    "15500x1230": 32.0,
    "20000x600": 41.0,
    "20000x750": 41.0,
    "20000x910": 41.0,
    "20000x1050": 41.0,
    "20000x1230": 41.0,
    "25000x600": 41.0,
    "25000x750": 41.0,
    "25000x910": 41.0,
    "25000x1050": 41.0,
    "25000x1230": 41.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 51.0,
    "35000x1050": 51.0,
    "35000x1230": 51.0,
    "40000x600": 51.0,
    "40000x750": 51.0,
    "40000x910": 51.0,
    "40000x1050": 51.0,
    "40000x1230": 51.0,
    "50000x600": 51.0,
    "50000x750": 51.0,
    "50000x910": 51.0,
    "50000x1050": 51.0,
    "50000x1230": 51.0,
    "60000x600": 61.0,
    "60000x750": 61.0,
    "60000x910": 61.0,
    "60000x1050": 61.0,
    "60000x1230": 61.0,
  },
  "1000x2500": {
    "1000x600": 2.0,
    "1000x750": 2.0,
    "1000x910": 2.0,
    "1000x1050": 2.0,
    "1000x1230": 12.0,
    "2000x600": 12.0,
    "2000x750": 12.0,
    "2000x910": 12.0,
    "2000x1050": 12.0,
    "2000x1230": 12.0,
    "3000x600": 12.0,
    "3000x750": 12.0,
    "3000x910": 12.0,
    "3000x1050": 12.0,
    "3000x1230": 12.0,
    "4000x600": 22.0,
    "4000x750": 22.0,
    "4000x910": 22.0,
    "4000x1050": 22.0,
    "4000x1230": 22.0,
    "5000x600": 22.0,
    "5000x750": 22.0,
    "5000x910": 22.0,
    "5000x1050": 22.0,
    "5000x1230": 22.0,
    "6500x600": 31.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 32.0,
    "6500x1230": 32.0,
    "8000x600": 31.0,
    "8000x750": 32.0,
    "8000x910": 32.0,
    "8000x1050": 32.0,
    "8000x1230": 32.0,
    "10000x600": 31.0,
    "10000x750": 32.0,
    "10000x910": 32.0,
    "10000x1050": 32.0,
    "10000x1230": 32.0,
    "12500x600": 31.0,
    "12500x750": 31.0,
    "12500x910": 32.0,
    "12500x1050": 32.0,
    "12500x1230": 32.0,
    "15500x600": 31.0,
    "15500x750": 31.0,
    "15500x910": 32.0,
    "15500x1050": 32.0,
    "15500x1230": 32.0,
    "20000x600": 41.0,
    "20000x750": 41.0,
    "20000x910": 41.0,
    "20000x1050": 42.0,
    "20000x1230": 42.0,
    "25000x600": 41.0,
    "25000x750": 41.0,
    "25000x910": 41.0,
    "25000x1050": 42.0,
    "25000x1230": 42.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 51.0,
    "35000x1050": 51.0,
    "35000x1230": 52.0,
    "40000x600": 51.0,
    "40000x750": 51.0,
    "40000x910": 51.0,
    "40000x1050": 51.0,
    "40000x1230": 52.0,
    "50000x600": 51.0,
    "50000x750": 51.0,
    "50000x910": 51.0,
    "50000x1050": 51.0,
    "50000x1230": 52.0,
    "60000x600": 61.0,
    "60000x750": 61.0,
    "60000x910": 61.0,
    "60000x1050": 61.0,
    "60000x1230": 61.0,
  },
  "1000x3000": {
    "1000x600": 22.0,
    "1000x750": 22.0,
    "1000x910": 22.0,
    "1000x1050": 22.0,
    "1000x1230": 23.0,
    "2000x600": 12.0,
    "2000x750": 12.0,
    "2000x910": 12.0,
    "2000x1050": 22.0,
    "2000x1230": 22.0,
    "3000x600": 12.0,
    "3000x750": 12.0,
    "3000x910": 12.0,
    "3000x1050": 22.0,
    "3000x1230": 22.0,
    "4000x600": 22.0,
    "4000x750": 22.0,
    "4000x910": 22.0,
    "4000x1050": 22.0,
    "4000x1230": 22.0,
    "5000x600": 22.0,
    "5000x750": 22.0,
    "5000x910": 22.0,
    "5000x1050": 22.0,
    "5000x1230": 22.0,
    "6500x600": 32.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 32.0,
    "6500x1230": 32.0,
    "8000x600": 32.0,
    "8000x750": 32.0,
    "8000x910": 32.0,
    "8000x1050": 32.0,
    "8000x1230": 32.0,
    "10000x600": 32.0,
    "10000x750": 32.0,
    "10000x910": 32.0,
    "10000x1050": 32.0,
    "10000x1230": 32.0,
    "12500x600": 32.0,
    "12500x750": 32.0,
    "12500x910": 32.0,
    "12500x1050": 32.0,
    "12500x1230": 32.0,
    "15500x600": 32.0,
    "15500x750": 32.0,
    "15500x910": 32.0,
    "15500x1050": 32.0,
    "15500x1230": 32.0,
    "20000x600": 41.0,
    "20000x750": 42.0,
    "20000x910": 42.0,
    "20000x1050": 42.0,
    "20000x1230": 42.0,
    "25000x600": 41.0,
    "25000x750": 42.0,
    "25000x910": 42.0,
    "25000x1050": 42.0,
    "25000x1230": 42.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 52.0,
    "30000x1230": 52.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 52.0,
    "35000x1050": 52.0,
    "35000x1230": 52.0,
    "40000x600": 51.0,
    "40000x750": 51.0,
    "40000x910": 52.0,
    "40000x1050": 52.0,
    "40000x1230": 52.0,
    "50000x600": 51.0,
    "50000x750": 51.0,
    "50000x910": 52.0,
    "50000x1050": 52.0,
    "50000x1230": 52.0,
    "60000x600": 61.0,
    "60000x750": 61.0,
    "60000x910": 61.0,
    "60000x1050": 61.0,
    "60000x1230": 62.0,
  },
  "1000x3500": {
    "1000x600": 32.0,
    "1000x750": 32.0,
    "1000x910": 32.0,
    "1000x1050": 32.0,
    "1000x1230": 32.0,
    "2000x600": 23.0,
    "2000x750": 23.0,
    "2000x910": 23.0,
    "2000x1050": 23.0,
    "2000x1230": 23.0,
    "3000x600": 23.0,
    "3000x750": 23.0,
    "3000x910": 23.0,
    "3000x1050": 23.0,
    "3000x1230": 23.0,
    "4000x600": 23.0,
    "4000x750": 23.0,
    "4000x910": 23.0,
    "4000x1050": 23.0,
    "4000x1230": 23.0,
    "5000x600": 23.0,
    "5000x750": 23.0,
    "5000x910": 23.0,
    "5000x1050": 23.0,
    "5000x1230": 23.0,
    "6500x600": 32.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 32.0,
    "6500x1230": 32.0,
    "8000x600": 32.0,
    "8000x750": 32.0,
    "8000x910": 32.0,
    "8000x1050": 32.0,
    "8000x1230": 32.0,
    "10000x600": 32.0,
    "10000x750": 32.0,
    "10000x910": 32.0,
    "10000x1050": 32.0,
    "10000x1230": 32.0,
    "12500x600": 32.0,
    "12500x750": 32.0,
    "12500x910": 32.0,
    "12500x1050": 32.0,
    "12500x1230": 33.0,
    "15500x600": 32.0,
    "15500x750": 32.0,
    "15500x910": 32.0,
    "15500x1050": 32.0,
    "15500x1230": 33.0,
    "20000x600": 42.0,
    "20000x750": 42.0,
    "20000x910": 42.0,
    "20000x1050": 42.0,
    "20000x1230": 42.0,
    "25000x600": 42.0,
    "25000x750": 42.0,
    "25000x910": 42.0,
    "25000x1050": 42.0,
    "25000x1230": 42.0,
    "30000x600": 52.0,
    "30000x750": 52.0,
    "30000x910": 52.0,
    "30000x1050": 52.0,
    "30000x1230": 52.0,
    "35000x600": 52.0,
    "35000x750": 52.0,
    "35000x910": 52.0,
    "35000x1050": 52.0,
    "35000x1230": 52.0,
    "40000x600": 52.0,
    "40000x750": 52.0,
    "40000x910": 52.0,
    "40000x1050": 52.0,
    "40000x1230": 53.0,
    "50000x600": 52.0,
    "50000x750": 52.0,
    "50000x910": 52.0,
    "50000x1050": 52.0,
    "50000x1230": 52.0,
    "60000x600": 61.0,
    "60000x750": 61.0,
    "60000x910": 62.0,
    "60000x1050": 62.0,
    "60000x1230": 62.0,
  },
  "1000x4000": {
    "1000x600": 42.0,
    "1000x750": 42.0,
    "1000x910": 42.0,
    "1000x1050": 43.0,
    "1000x1230": 44.0,
    "2000x600": 42.0,
    "2000x750": 42.0,
    "2000x910": 42.0,
    "2000x1050": 43.0,
    "2000x1230": 44.0,
    "3000x600": 42.0,
    "3000x750": 42.0,
    "3000x910": 42.0,
    "3000x1050": 43.0,
    "3000x1230": 44.0,
    "4000x600": 42.0,
    "4000x750": 42.0,
    "4000x910": 42.0,
    "4000x1050": 43.0,
    "4000x1230": 44.0,
    "5000x600": 42.0,
    "5000x750": 42.0,
    "5000x910": 42.0,
    "5000x1050": 43.0,
    "5000x1230": 44.0,
    "6500x600": 42.0,
    "6500x750": 42.0,
    "6500x910": 42.0,
    "6500x1050": 43.0,
    "6500x1230": 44.0,
    "8000x600": 42.0,
    "8000x750": 42.0,
    "8000x910": 42.0,
    "8000x1050": 43.0,
    "8000x1230": 44.0,
    "10000x600": 42.0,
    "10000x750": 42.0,
    "10000x910": 42.0,
    "10000x1050": 43.0,
    "10000x1230": 44.0,
    "12500x600": 42.0,
    "12500x750": 42.0,
    "12500x910": 42.0,
    "12500x1050": 43.0,
    "12500x1230": 44.0,
    "15500x600": 42.0,
    "15500x750": 42.0,
    "15500x910": 42.0,
    "15500x1050": 43.0,
    "15500x1230": 44.0,
    "20000x600": 42.0,
    "20000x750": 42.0,
    "20000x910": 42.0,
    "20000x1050": 43.0,
    "20000x1230": 44.0,
    "25000x600": 42.0,
    "25000x750": 42.0,
    "25000x910": 42.0,
    "25000x1050": 43.0,
    "25000x1230": 44.0,
    "30000x600": 52.0,
    "30000x750": 52.0,
    "30000x910": 52.0,
    "30000x1050": 52.0,
    "30000x1230": 52.0,
    "35000x600": 52.0,
    "35000x750": 52.0,
    "35000x910": 52.0,
    "35000x1050": 52.0,
    "35000x1230": 53.0,
    "40000x600": 52.0,
    "40000x750": 52.0,
    "40000x910": 52.0,
    "40000x1050": 52.0,
    "40000x1230": 53.0,
    "50000x600": 52.0,
    "50000x750": 52.0,
    "50000x910": 52.0,
    "50000x1050": 52.0,
    "50000x1230": 53.0,
    "60000x600": 62.0,
    "60000x750": 62.0,
    "60000x910": 62.0,
    "60000x1050": 62.0,
    "60000x1230": 62.0,
  },
  "1000x5000": {
    "1000x600": 45.0,
    "1000x750": 45.0,
    "1000x910": 45.0,
    "1000x1050": 45.0,
    "1000x1230": 54.0,
    "2000x600": 45.0,
    "2000x750": 45.0,
    "2000x910": 45.0,
    "2000x1050": 45.0,
    "2000x1230": 54.0,
    "3000x600": 45.0,
    "3000x750": 45.0,
    "3000x910": 45.0,
    "3000x1050": 45.0,
    "3000x1230": 54.0,
    "4000x600": 45.0,
    "4000x750": 45.0,
    "4000x910": 45.0,
    "4000x1050": 45.0,
    "4000x1230": 54.0,
    "5000x600": 45.0,
    "5000x750": 45.0,
    "5000x910": 45.0,
    "5000x1050": 45.0,
    "5000x1230": 54.0,
    "6500x600": 45.0,
    "6500x750": 45.0,
    "6500x910": 45.0,
    "6500x1050": 45.0,
    "6500x1230": 54.0,
    "8000x600": 45.0,
    "8000x750": 45.0,
    "8000x910": 45.0,
    "8000x1050": 45.0,
    "8000x1230": 54.0,
    "10000x600": 45.0,
    "10000x750": 45.0,
    "10000x910": 45.0,
    "10000x1050": 45.0,
    "10000x1230": 54.0,
    "12500x600": 45.0,
    "12500x750": 45.0,
    "12500x910": 45.0,
    "12500x1050": 45.0,
    "12500x1230": 54.0,
    "15500x600": 45.0,
    "15500x750": 45.0,
    "15500x910": 45.0,
    "15500x1050": 45.0,
    "15500x1230": 54.0,
    "20000x600": 45.0,
    "20000x750": 45.0,
    "20000x910": 45.0,
    "20000x1050": 45.0,
    "20000x1230": 54.0,
    "25000x600": 45.0,
    "25000x750": 45.0,
    "25000x910": 45.0,
    "25000x1050": 45.0,
    "25000x1230": 54.0,
    "30000x600": 54.0,
    "30000x750": 54.0,
    "30000x910": 54.0,
    "30000x1050": 54.0,
    "30000x1230": 54.0,
    "35000x600": 54.0,
    "35000x750": 54.0,
    "35000x910": 54.0,
    "35000x1050": 54.0,
    "35000x1230": 54.0,
    "40000x600": 54.0,
    "40000x750": 54.0,
    "40000x910": 54.0,
    "40000x1050": 54.0,
    "40000x1230": 54.0,
    "50000x600": 54.0,
    "50000x750": 54.0,
    "50000x910": 54.0,
    "50000x1050": 54.0,
    "50000x1230": 54.0,
    "60000x600": 63.0,
    "60000x750": 63.0,
    "60000x910": 63.0,
    "60000x1050": 64.0,
    "60000x1230": 64.0,
  },
  "1500x1000": {
    "1000x600": 1.0,
    "1000x750": 1.0,
    "1000x910": 1.0,
    "1000x1050": 1.0,
    "1000x1230": 11.0,
    "2000x600": 11.0,
    "2000x750": 11.0,
    "2000x910": 11.0,
    "2000x1050": 11.0,
    "2000x1230": 11.0,
    "3000x600": 21.0,
    "3000x750": 21.0,
    "3000x910": 21.0,
    "3000x1050": 21.0,
    "3000x1230": 21.0,
    "4000x600": 21.0,
    "4000x750": 21.0,
    "4000x910": 21.0,
    "4000x1050": 21.0,
    "4000x1230": 21.0,
    "5000x600": 21.0,
    "5000x750": 21.0,
    "5000x910": 21.0,
    "5000x1050": 21.0,
    "5000x1230": 21.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 31.0,
    "6500x1050": 31.0,
    "6500x1230": 31.0,
    "8000x600": 31.0,
    "8000x750": 31.0,
    "8000x910": 31.0,
    "8000x1050": 31.0,
    "8000x1230": 31.0,
    "10000x600": 31.0,
    "10000x750": 31.0,
    "10000x910": 31.0,
    "10000x1050": 31.0,
    "10000x1230": 31.0,
    "12500x600": 31.0,
    "12500x750": 31.0,
    "12500x910": 31.0,
    "12500x1050": 31.0,
    "12500x1230": 31.0,
    "15500x600": 41.0,
    "15500x750": 41.0,
    "15500x910": 41.0,
    "15500x1050": 41.0,
    "15500x1230": 41.0,
    "20000x600": 41.0,
    "20000x750": 41.0,
    "20000x910": 41.0,
    "20000x1050": 41.0,
    "20000x1230": 41.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 51.0,
    "25000x1230": 51.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 51.0,
    "35000x1050": 51.0,
    "35000x1230": 51.0,
    "40000x600": 51.0,
    "40000x750": 51.0,
    "40000x910": 51.0,
    "40000x1050": 51.0,
    "40000x1230": 52.0,
    "50000x600": 51.0,
    "50000x750": 51.0,
    "50000x910": 52.0,
    "50000x1050": 51.0,
    "50000x1230": 52.0,
    "60000x600": 61.0,
    "60000x750": 61.0,
    "60000x910": 61.0,
    "60000x1050": 61.0,
    "60000x1230": 61.0,
  },
  "1500x1500": {
    "1000x600": 2.0,
    "1000x750": 2.0,
    "1000x910": 2.0,
    "1000x1050": 2.0,
    "1000x1230": 2.0,
    "2000x600": 11.0,
    "2000x750": 12.0,
    "2000x910": 12.0,
    "2000x1050": 12.0,
    "2000x1230": 12.0,
    "3000x600": 21.0,
    "3000x750": 21.0,
    "3000x910": 21.0,
    "3000x1050": 21.0,
    "3000x1230": 22.0,
    "4000x600": 21.0,
    "4000x750": 21.0,
    "4000x910": 21.0,
    "4000x1050": 21.0,
    "4000x1230": 22.0,
    "5000x600": 31.0,
    "5000x750": 31.0,
    "5000x910": 31.0,
    "5000x1050": 31.0,
    "5000x1230": 31.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 31.0,
    "6500x1050": 31.0,
    "6500x1230": 31.0,
    "8000x600": 31.0,
    "8000x750": 31.0,
    "8000x910": 31.0,
    "8000x1050": 31.0,
    "8000x1230": 31.0,
    "10000x600": 31.0,
    "10000x750": 31.0,
    "10000x910": 31.0,
    "10000x1050": 31.0,
    "10000x1230": 31.0,
    "12500x600": 31.0,
    "12500x750": 31.0,
    "12500x910": 31.0,
    "12500x1050": 31.0,
    "12500x1230": 31.0,
    "15500x600": 41.0,
    "15500x750": 41.0,
    "15500x910": 41.0,
    "15500x1050": 41.0,
    "15500x1230": 41.0,
    "20000x600": 41.0,
    "20000x750": 41.0,
    "20000x910": 41.0,
    "20000x1050": 41.0,
    "20000x1230": 41.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 51.0,
    "25000x1230": 51.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 51.0,
    "35000x1050": 51.0,
    "35000x1230": 51.0,
    "40000x600": 51.0,
    "40000x750": 51.0,
    "40000x910": 51.0,
    "40000x1050": 51.0,
    "40000x1230": 52.0,
    "50000x600": 51.0,
    "50000x750": 51.0,
    "50000x910": 52.0,
    "50000x1050": 51.0,
    "50000x1230": 52.0,
    "60000x600": 61.0,
    "60000x750": 61.0,
    "60000x910": 61.0,
    "60000x1050": 61.0,
    "60000x1230": 61.0,
  },
  "1500x2000": {
    "1000x600": 2.0,
    "1000x750": 2.0,
    "1000x910": 2.0,
    "1000x1050": 2.0,
    "1000x1230": 12.0,
    "2000x600": 12.0,
    "2000x750": 12.0,
    "2000x910": 12.0,
    "2000x1050": 12.0,
    "2000x1230": 12.0,
    "3000x600": 22.0,
    "3000x750": 22.0,
    "3000x910": 22.0,
    "3000x1050": 22.0,
    "3000x1230": 22.0,
    "4000x600": 22.0,
    "4000x750": 22.0,
    "4000x910": 22.0,
    "4000x1050": 22.0,
    "4000x1230": 22.0,
    "5000x600": 31.0,
    "5000x750": 31.0,
    "5000x910": 31.0,
    "5000x1050": 31.0,
    "5000x1230": 32.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 31.0,
    "6500x1050": 31.0,
    "6500x1230": 32.0,
    "8000x600": 31.0,
    "8000x750": 31.0,
    "8000x910": 31.0,
    "8000x1050": 31.0,
    "8000x1230": 32.0,
    "10000x600": 31.0,
    "10000x750": 31.0,
    "10000x910": 31.0,
    "10000x1050": 31.0,
    "10000x1230": 32.0,
    "12500x600": 31.0,
    "12500x750": 31.0,
    "12500x910": 32.0,
    "12500x1050": 32.0,
    "12500x1230": 32.0,
    "15500x600": 41.0,
    "15500x750": 41.0,
    "15500x910": 41.0,
    "15500x1050": 42.0,
    "15500x1230": 42.0,
    "20000x600": 41.0,
    "20000x750": 41.0,
    "20000x910": 41.0,
    "20000x1050": 42.0,
    "20000x1230": 42.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 51.0,
    "25000x1230": 51.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 51.0,
    "35000x1050": 51.0,
    "35000x1230": 52.0,
    "40000x600": 51.0,
    "40000x750": 51.0,
    "40000x910": 51.0,
    "40000x1050": 51.0,
    "40000x1230": 52.0,
    "50000x600": 51.0,
    "50000x750": 51.0,
    "50000x910": 52.0,
    "50000x1050": 51.0,
    "50000x1230": 52.0,
    "60000x600": 61.0,
    "60000x750": 61.0,
    "60000x910": 61.0,
    "60000x1050": 61.0,
    "60000x1230": 61.0,
  },
  "1500x2500": {
    "1000x600": 12.0,
    "1000x750": 12.0,
    "1000x910": 22.0,
    "1000x1050": 22.0,
    "1000x1230": 23.0,
    "2000x600": 12.0,
    "2000x750": 12.0,
    "2000x910": 12.0,
    "2000x1050": 22.0,
    "2000x1230": 22.0,
    "3000x600": 22.0,
    "3000x750": 22.0,
    "3000x910": 22.0,
    "3000x1050": 22.0,
    "3000x1230": 22.0,
    "4000x600": 22.0,
    "4000x750": 22.0,
    "4000x910": 22.0,
    "4000x1050": 22.0,
    "4000x1230": 22.0,
    "5000x600": 31.0,
    "5000x750": 32.0,
    "5000x910": 32.0,
    "5000x1050": 32.0,
    "5000x1230": 32.0,
    "6500x600": 31.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 32.0,
    "6500x1230": 32.0,
    "8000x600": 31.0,
    "8000x750": 32.0,
    "8000x910": 32.0,
    "8000x1050": 32.0,
    "8000x1230": 32.0,
    "10000x600": 31.0,
    "10000x750": 32.0,
    "10000x910": 32.0,
    "10000x1050": 32.0,
    "10000x1230": 32.0,
    "12500x600": 32.0,
    "12500x750": 32.0,
    "12500x910": 32.0,
    "12500x1050": 32.0,
    "12500x1230": 32.0,
    "15500x600": 42.0,
    "15500x750": 42.0,
    "15500x910": 42.0,
    "15500x1050": 42.0,
    "15500x1230": 42.0,
    "20000x600": 41.0,
    "20000x750": 42.0,
    "20000x910": 42.0,
    "20000x1050": 42.0,
    "20000x1230": 42.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 52.0,
    "25000x1230": 52.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 52.0,
    "30000x1230": 52.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 52.0,
    "35000x1050": 52.0,
    "35000x1230": 52.0,
    "40000x600": 51.0,
    "40000x750": 51.0,
    "40000x910": 52.0,
    "40000x1050": 52.0,
    "40000x1230": 52.0,
    "50000x600": 51.0,
    "50000x750": 51.0,
    "50000x910": 52.0,
    "50000x1050": 52.0,
    "50000x1230": 52.0,
    "60000x600": 61.0,
    "60000x750": 61.0,
    "60000x910": 61.0,
    "60000x1050": 62.0,
    "60000x1230": 62.0,
  },
  "1500x3000": {
    "1000x600": 22.0,
    "1000x750": 22.0,
    "1000x910": 23.0,
    "1000x1050": 32.0,
    "1000x1230": 33.0,
    "2000x600": 12.0,
    "2000x750": 22.0,
    "2000x910": 23.0,
    "2000x1050": 23.0,
    "2000x1230": 32.0,
    "3000x600": 22.0,
    "3000x750": 22.0,
    "3000x910": 23.0,
    "3000x1050": 23.0,
    "3000x1230": 32.0,
    "4000x600": 22.0,
    "4000x750": 22.0,
    "4000x910": 23.0,
    "4000x1050": 23.0,
    "4000x1230": 32.0,
    "5000x600": 32.0,
    "5000x750": 32.0,
    "5000x910": 32.0,
    "5000x1050": 32.0,
    "5000x1230": 32.0,
    "6500x600": 32.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 32.0,
    "6500x1230": 32.0,
    "8000x600": 32.0,
    "8000x750": 32.0,
    "8000x910": 32.0,
    "8000x1050": 32.0,
    "8000x1230": 32.0,
    "10000x600": 32.0,
    "10000x750": 32.0,
    "10000x910": 32.0,
    "10000x1050": 32.0,
    "10000x1230": 32.0,
    "12500x600": 32.0,
    "12500x750": 32.0,
    "12500x910": 32.0,
    "12500x1050": 33.0,
    "12500x1230": 34.0,
    "15500x600": 42.0,
    "15500x750": 42.0,
    "15500x910": 42.0,
    "15500x1050": 42.0,
    "15500x1230": 43.0,
    "20000x600": 42.0,
    "20000x750": 42.0,
    "20000x910": 42.0,
    "20000x1050": 42.0,
    "20000x1230": 43.0,
    "25000x600": 51.0,
    "25000x750": 52.0,
    "25000x910": 52.0,
    "25000x1050": 52.0,
    "25000x1230": 52.0,
    "30000x600": 51.0,
    "30000x750": 52.0,
    "30000x910": 52.0,
    "30000x1050": 52.0,
    "30000x1230": 52.0,
    "35000x600": 52.0,
    "35000x750": 52.0,
    "35000x910": 52.0,
    "35000x1050": 52.0,
    "35000x1230": 52.0,
    "40000x600": 52.0,
    "40000x750": 52.0,
    "40000x910": 52.0,
    "40000x1050": 52.0,
    "40000x1230": 52.0,
    "50000x600": 52.0,
    "50000x750": 52.0,
    "50000x910": 52.0,
    "50000x1050": 52.0,
    "50000x1230": 52.0,
    "60000x600": 61.0,
    "60000x750": 62.0,
    "60000x910": 62.0,
    "60000x1050": 62.0,
    "60000x1230": 62.0,
  },
  "1500x3500": {
    "1000x600": 32.0,
    "1000x750": 32.0,
    "1000x910": 33.0,
    "1000x1050": 33.0,
    "1000x1230": 43.0,
    "2000x600": 23.0,
    "2000x750": 23.0,
    "2000x910": 32.0,
    "2000x1050": 33.0,
    "2000x1230": 34.0,
    "3000x600": 23.0,
    "3000x750": 23.0,
    "3000x910": 32.0,
    "3000x1050": 33.0,
    "3000x1230": 34.0,
    "4000x600": 32.0,
    "4000x750": 32.0,
    "4000x910": 32.0,
    "4000x1050": 33.0,
    "4000x1230": 34.0,
    "5000x600": 32.0,
    "5000x750": 32.0,
    "5000x910": 32.0,
    "5000x1050": 33.0,
    "5000x1230": 34.0,
    "6500x600": 32.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 33.0,
    "6500x1230": 34.0,
    "8000x600": 32.0,
    "8000x750": 32.0,
    "8000x910": 32.0,
    "8000x1050": 33.0,
    "8000x1230": 34.0,
    "10000x600": 32.0,
    "10000x750": 32.0,
    "10000x910": 32.0,
    "10000x1050": 33.0,
    "10000x1230": 34.0,
    "12500x600": 32.0,
    "12500x750": 33.0,
    "12500x910": 34.0,
    "12500x1050": 34.0,
    "12500x1230": 44.0,
    "15500x600": 42.0,
    "15500x750": 42.0,
    "15500x910": 43.0,
    "15500x1050": 44.0,
    "15500x1230": 44.0,
    "20000x600": 42.0,
    "20000x750": 42.0,
    "20000x910": 43.0,
    "20000x1050": 44.0,
    "20000x1230": 44.0,
    "25000x600": 52.0,
    "25000x750": 52.0,
    "25000x910": 52.0,
    "25000x1050": 52.0,
    "25000x1230": 53.0,
    "30000x600": 52.0,
    "30000x750": 52.0,
    "30000x910": 52.0,
    "30000x1050": 52.0,
    "30000x1230": 53.0,
    "35000x600": 52.0,
    "35000x750": 52.0,
    "35000x910": 52.0,
    "35000x1050": 53.0,
    "35000x1230": 54.0,
    "40000x600": 52.0,
    "40000x750": 52.0,
    "40000x910": 52.0,
    "40000x1050": 53.0,
    "40000x1230": 54.0,
    "50000x600": 52.0,
    "50000x750": 52.0,
    "50000x910": 53.0,
    "50000x1050": 53.0,
    "50000x1230": 54.0,
    "60000x600": 62.0,
    "60000x750": 62.0,
    "60000x910": 62.0,
    "60000x1050": 62.0,
    "60000x1230": 63.0,
  },
  "1500x4000": {
    "1000x600": 42.0,
    "1000x750": 43.0,
    "1000x910": 44.0,
    "1000x1050": 44.0,
    "1000x1230": 44.0,
    "2000x600": 42.0,
    "2000x750": 43.0,
    "2000x910": 44.0,
    "2000x1050": 44.0,
    "2000x1230": 44.0,
    "3000x600": 42.0,
    "3000x750": 43.0,
    "3000x910": 44.0,
    "3000x1050": 44.0,
    "3000x1230": 44.0,
    "4000x600": 42.0,
    "4000x750": 43.0,
    "4000x910": 44.0,
    "4000x1050": 44.0,
    "4000x1230": 44.0,
    "5000x600": 42.0,
    "5000x750": 43.0,
    "5000x910": 44.0,
    "5000x1050": 44.0,
    "5000x1230": 44.0,
    "6500x600": 42.0,
    "6500x750": 43.0,
    "6500x910": 44.0,
    "6500x1050": 44.0,
    "6500x1230": 44.0,
    "8000x600": 42.0,
    "8000x750": 43.0,
    "8000x910": 44.0,
    "8000x1050": 44.0,
    "8000x1230": 44.0,
    "10000x600": 42.0,
    "10000x750": 43.0,
    "10000x910": 44.0,
    "10000x1050": 44.0,
    "10000x1230": 44.0,
    "12500x600": 42.0,
    "12500x750": 43.0,
    "12500x910": 44.0,
    "12500x1050": 44.0,
    "12500x1230": 44.0,
    "15500x600": 42.0,
    "15500x750": 43.0,
    "15500x910": 44.0,
    "15500x1050": 44.0,
    "15500x1230": 44.0,
    "20000x600": 42.0,
    "20000x750": 43.0,
    "20000x910": 44.0,
    "20000x1050": 44.0,
    "20000x1230": 45.0,
    "25000x600": 52.0,
    "25000x750": 52.0,
    "25000x910": 53.0,
    "25000x1050": 54.0,
    "25000x1230": 54.0,
    "30000x600": 52.0,
    "30000x750": 52.0,
    "30000x910": 53.0,
    "30000x1050": 54.0,
    "30000x1230": 54.0,
    "35000x600": 52.0,
    "35000x750": 53.0,
    "35000x910": 54.0,
    "35000x1050": 54.0,
    "35000x1230": 54.0,
    "40000x600": 52.0,
    "40000x750": 53.0,
    "40000x910": 54.0,
    "40000x1050": 54.0,
    "40000x1230": 54.0,
    "50000x600": 52.0,
    "50000x750": 53.0,
    "50000x910": 54.0,
    "50000x1050": 54.0,
    "50000x1230": 54.0,
    "60000x600": 62.0,
    "60000x750": 62.0,
    "60000x910": 63.0,
    "60000x1050": 64.0,
    "60000x1230": 64.0,
  },
  "1500x5000": {
    "1000x600": 45.0,
    "1000x750": 45.0,
    "1000x910": 54.0,
    "1000x1050": 54.0,
    "1000x1230": 54.0,
    "2000x600": 45.0,
    "2000x750": 45.0,
    "2000x910": 54.0,
    "2000x1050": 54.0,
    "2000x1230": 54.0,
    "3000x600": 45.0,
    "3000x750": 45.0,
    "3000x910": 54.0,
    "3000x1050": 54.0,
    "3000x1230": 54.0,
    "4000x600": 45.0,
    "4000x750": 45.0,
    "4000x910": 54.0,
    "4000x1050": 54.0,
    "4000x1230": 54.0,
    "5000x600": 45.0,
    "5000x750": 45.0,
    "5000x910": 54.0,
    "5000x1050": 54.0,
    "5000x1230": 54.0,
    "6500x600": 45.0,
    "6500x750": 45.0,
    "6500x910": 54.0,
    "6500x1050": 54.0,
    "6500x1230": 54.0,
    "8000x600": 45.0,
    "8000x750": 45.0,
    "8000x910": 54.0,
    "8000x1050": 54.0,
    "8000x1230": 54.0,
    "10000x600": 45.0,
    "10000x750": 45.0,
    "10000x910": 54.0,
    "10000x1050": 54.0,
    "10000x1230": 54.0,
    "12500x600": 45.0,
    "12500x750": 45.0,
    "12500x910": 54.0,
    "12500x1050": 54.0,
    "12500x1230": 54.0,
    "15500x600": 45.0,
    "15500x750": 45.0,
    "15500x910": 54.0,
    "15500x1050": 54.0,
    "15500x1230": 54.0,
    "20000x600": 45.0,
    "20000x750": 45.0,
    "20000x910": 54.0,
    "20000x1050": 54.0,
    "20000x1230": 66.0,
    "25000x600": 54.0,
    "25000x750": 54.0,
    "25000x910": 54.0,
    "25000x1050": 54.0,
    "25000x1230": 66.0,
    "30000x600": 54.0,
    "30000x750": 54.0,
    "30000x910": 54.0,
    "30000x1050": 54.0,
    "30000x1230": 66.0,
    "35000x600": 54.0,
    "35000x750": 54.0,
    "35000x910": 54.0,
    "35000x1050": 66.0,
    "35000x1230": 66.0,
    "40000x600": 54.0,
    "40000x750": 54.0,
    "40000x910": 54.0,
    "40000x1050": 66.0,
    "40000x1230": 66.0,
    "50000x600": 63.0,
    "50000x750": 64.0,
    "50000x910": 65.0,
    "50000x1050": 66.0,
    "50000x1230": 66.0,
    "60000x600": 63.0,
    "60000x750": 64.0,
    "60000x910": 65.0,
    "60000x1050": 66.0,
    "60000x1230": 66.0,
  },
  "2000x1000": {
    "1000x600": 1.0,
    "1000x750": 1.0,
    "1000x910": 11.0,
    "1000x1050": 12.0,
    "1000x1230": 12.0,
    "2000x600": 21.0,
    "2000x750": 21.0,
    "2000x910": 21.0,
    "2000x1050": 21.0,
    "2000x1230": 21.0,
    "3000x600": 21.0,
    "3000x750": 21.0,
    "3000x910": 21.0,
    "3000x1050": 21.0,
    "3000x1230": 21.0,
    "4000x600": 31.0,
    "4000x750": 31.0,
    "4000x910": 31.0,
    "4000x1050": 31.0,
    "4000x1230": 31.0,
    "5000x600": 31.0,
    "5000x750": 31.0,
    "5000x910": 31.0,
    "5000x1050": 31.0,
    "5000x1230": 31.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 31.0,
    "6500x1050": 31.0,
    "6500x1230": 31.0,
    "8000x600": 31.0,
    "8000x750": 31.0,
    "8000x910": 31.0,
    "8000x1050": 31.0,
    "8000x1230": 31.0,
    "10000x600": 41.0,
    "10000x750": 41.0,
    "10000x910": 41.0,
    "10000x1050": 41.0,
    "10000x1230": 41.0,
    "12500x600": 41.0,
    "12500x750": 41.0,
    "12500x910": 41.0,
    "12500x1050": 41.0,
    "12500x1230": 41.0,
    "15500x600": 41.0,
    "15500x750": 41.0,
    "15500x910": 41.0,
    "15500x1050": 41.0,
    "15500x1230": 41.0,
    "20000x600": 51.0,
    "20000x750": 51.0,
    "20000x910": 51.0,
    "20000x1050": 51.0,
    "20000x1230": 51.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 51.0,
    "25000x1230": 51.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 51.0,
    "35000x1050": 51.0,
    "35000x1230": 51.0,
    "40000x600": 61.0,
    "40000x750": 61.0,
    "40000x910": 61.0,
    "40000x1050": 61.0,
    "40000x1230": 62.0,
    "50000x600": 61.0,
    "50000x750": 61.0,
    "50000x910": 61.0,
    "50000x1050": 61.0,
    "50000x1230": 62.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "2000x1500": {
    "1000x600": 2.0,
    "1000x750": 2.0,
    "1000x910": 2.0,
    "1000x1050": 2.0,
    "1000x1230": 12.0,
    "2000x600": 21.0,
    "2000x750": 21.0,
    "2000x910": 22.0,
    "2000x1050": 22.0,
    "2000x1230": 22.0,
    "3000x600": 21.0,
    "3000x750": 21.0,
    "3000x910": 22.0,
    "3000x1050": 22.0,
    "3000x1230": 22.0,
    "4000x600": 31.0,
    "4000x750": 31.0,
    "4000x910": 31.0,
    "4000x1050": 31.0,
    "4000x1230": 31.0,
    "5000x600": 31.0,
    "5000x750": 31.0,
    "5000x910": 31.0,
    "5000x1050": 31.0,
    "5000x1230": 31.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 31.0,
    "6500x1050": 31.0,
    "6500x1230": 31.0,
    "8000x600": 31.0,
    "8000x750": 31.0,
    "8000x910": 31.0,
    "8000x1050": 31.0,
    "8000x1230": 31.0,
    "10000x600": 41.0,
    "10000x750": 41.0,
    "10000x910": 41.0,
    "10000x1050": 41.0,
    "10000x1230": 41.0,
    "12500x600": 41.0,
    "12500x750": 41.0,
    "12500x910": 41.0,
    "12500x1050": 41.0,
    "12500x1230": 41.0,
    "15500x600": 41.0,
    "15500x750": 41.0,
    "15500x910": 41.0,
    "15500x1050": 41.0,
    "15500x1230": 41.0,
    "20000x600": 51.0,
    "20000x750": 51.0,
    "20000x910": 51.0,
    "20000x1050": 51.0,
    "20000x1230": 51.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 51.0,
    "25000x1230": 51.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 51.0,
    "35000x1050": 51.0,
    "35000x1230": 51.0,
    "40000x600": 61.0,
    "40000x750": 61.0,
    "40000x910": 61.0,
    "40000x1050": 61.0,
    "40000x1230": 62.0,
    "50000x600": 61.0,
    "50000x750": 61.0,
    "50000x910": 61.0,
    "50000x1050": 61.0,
    "50000x1230": 62.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "2000x2000": {
    "1000x600": 2.0,
    "1000x750": 2.0,
    "1000x910": 12.0,
    "1000x1050": 22.0,
    "1000x1230": 22.0,
    "2000x600": 22.0,
    "2000x750": 22.0,
    "2000x910": 22.0,
    "2000x1050": 22.0,
    "2000x1230": 22.0,
    "3000x600": 22.0,
    "3000x750": 22.0,
    "3000x910": 22.0,
    "3000x1050": 22.0,
    "3000x1230": 22.0,
    "4000x600": 31.0,
    "4000x750": 31.0,
    "4000x910": 32.0,
    "4000x1050": 32.0,
    "4000x1230": 32.0,
    "5000x600": 31.0,
    "5000x750": 31.0,
    "5000x910": 32.0,
    "5000x1050": 32.0,
    "5000x1230": 32.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 32.0,
    "6500x1050": 32.0,
    "6500x1230": 32.0,
    "8000x600": 31.0,
    "8000x750": 31.0,
    "8000x910": 32.0,
    "8000x1050": 32.0,
    "8000x1230": 32.0,
    "10000x600": 41.0,
    "10000x750": 41.0,
    "10000x910": 41.0,
    "10000x1050": 42.0,
    "10000x1230": 42.0,
    "12500x600": 41.0,
    "12500x750": 41.0,
    "12500x910": 42.0,
    "12500x1050": 42.0,
    "12500x1230": 42.0,
    "15500x600": 41.0,
    "15500x750": 41.0,
    "15500x910": 42.0,
    "15500x1050": 42.0,
    "15500x1230": 42.0,
    "20000x600": 51.0,
    "20000x750": 51.0,
    "20000x910": 51.0,
    "20000x1050": 51.0,
    "20000x1230": 52.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 51.0,
    "25000x1230": 52.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 52.0,
    "35000x600": 51.0,
    "35000x750": 51.0,
    "35000x910": 52.0,
    "35000x1050": 52.0,
    "35000x1230": 52.0,
    "40000x600": 61.0,
    "40000x750": 61.0,
    "40000x910": 61.0,
    "40000x1050": 61.0,
    "40000x1230": 62.0,
    "50000x600": 61.0,
    "50000x750": 61.0,
    "50000x910": 61.0,
    "50000x1050": 61.0,
    "50000x1230": 62.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "2000x2500": {
    "1000x600": 12.0,
    "1000x750": 22.0,
    "1000x910": 23.0,
    "1000x1050": 23.0,
    "1000x1230": 32.0,
    "2000x600": 22.0,
    "2000x750": 22.0,
    "2000x910": 22.0,
    "2000x1050": 23.0,
    "2000x1230": 23.0,
    "3000x600": 22.0,
    "3000x750": 22.0,
    "3000x910": 22.0,
    "3000x1050": 23.0,
    "3000x1230": 32.0,
    "4000x600": 32.0,
    "4000x750": 32.0,
    "4000x910": 32.0,
    "4000x1050": 32.0,
    "4000x1230": 32.0,
    "5000x600": 32.0,
    "5000x750": 32.0,
    "5000x910": 32.0,
    "5000x1050": 32.0,
    "5000x1230": 32.0,
    "6500x600": 32.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 32.0,
    "6500x1230": 32.0,
    "8000x600": 32.0,
    "8000x750": 32.0,
    "8000x910": 32.0,
    "8000x1050": 32.0,
    "8000x1230": 32.0,
    "10000x600": 41.0,
    "10000x750": 42.0,
    "10000x910": 42.0,
    "10000x1050": 42.0,
    "10000x1230": 42.0,
    "12500x600": 42.0,
    "12500x750": 42.0,
    "12500x910": 42.0,
    "12500x1050": 42.0,
    "12500x1230": 42.0,
    "15500x600": 42.0,
    "15500x750": 42.0,
    "15500x910": 42.0,
    "15500x1050": 42.0,
    "15500x1230": 42.0,
    "20000x600": 51.0,
    "20000x750": 52.0,
    "20000x910": 52.0,
    "20000x1050": 52.0,
    "20000x1230": 52.0,
    "25000x600": 51.0,
    "25000x750": 52.0,
    "25000x910": 52.0,
    "25000x1050": 52.0,
    "25000x1230": 52.0,
    "30000x600": 51.0,
    "30000x750": 52.0,
    "30000x910": 52.0,
    "30000x1050": 52.0,
    "30000x1230": 52.0,
    "35000x600": 52.0,
    "35000x750": 52.0,
    "35000x910": 52.0,
    "35000x1050": 52.0,
    "35000x1230": 52.0,
    "40000x600": 61.0,
    "40000x750": 61.0,
    "40000x910": 62.0,
    "40000x1050": 62.0,
    "40000x1230": 62.0,
    "50000x600": 61.0,
    "50000x750": 61.0,
    "50000x910": 62.0,
    "50000x1050": 62.0,
    "50000x1230": 62.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "2000x3000": {
    "1000x600": 23.0,
    "1000x750": 23.0,
    "1000x910": 33.0,
    "1000x1050": 33.0,
    "1000x1230": 34.0,
    "2000x600": 22.0,
    "2000x750": 23.0,
    "2000x910": 32.0,
    "2000x1050": 33.0,
    "2000x1230": 34.0,
    "3000x600": 22.0,
    "3000x750": 23.0,
    "3000x910": 32.0,
    "3000x1050": 33.0,
    "3000x1230": 34.0,
    "4000x600": 32.0,
    "4000x750": 32.0,
    "4000x910": 32.0,
    "4000x1050": 33.0,
    "4000x1230": 34.0,
    "5000x600": 32.0,
    "5000x750": 32.0,
    "5000x910": 32.0,
    "5000x1050": 33.0,
    "5000x1230": 34.0,
    "6500x600": 32.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 33.0,
    "6500x1230": 34.0,
    "8000x600": 32.0,
    "8000x750": 32.0,
    "8000x910": 32.0,
    "8000x1050": 33.0,
    "8000x1230": 34.0,
    "10000x600": 42.0,
    "10000x750": 42.0,
    "10000x910": 42.0,
    "10000x1050": 42.0,
    "10000x1230": 43.0,
    "12500x600": 42.0,
    "12500x750": 42.0,
    "12500x910": 43.0,
    "12500x1050": 43.0,
    "12500x1230": 44.0,
    "15500x600": 42.0,
    "15500x750": 42.0,
    "15500x910": 43.0,
    "15500x1050": 44.0,
    "15500x1230": 44.0,
    "20000x600": 52.0,
    "20000x750": 52.0,
    "20000x910": 52.0,
    "20000x1050": 52.0,
    "20000x1230": 53.0,
    "25000x600": 52.0,
    "25000x750": 52.0,
    "25000x910": 52.0,
    "25000x1050": 52.0,
    "25000x1230": 53.0,
    "30000x600": 52.0,
    "30000x750": 52.0,
    "30000x910": 52.0,
    "30000x1050": 52.0,
    "30000x1230": 53.0,
    "35000x600": 52.0,
    "35000x750": 52.0,
    "35000x910": 52.0,
    "35000x1050": 53.0,
    "35000x1230": 54.0,
    "40000x600": 62.0,
    "40000x750": 62.0,
    "40000x910": 62.0,
    "40000x1050": 62.0,
    "40000x1230": 63.0,
    "50000x600": 62.0,
    "50000x750": 62.0,
    "50000x910": 62.0,
    "50000x1050": 62.0,
    "50000x1230": 63.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "2000x3500": {
    "1000x600": 32.0,
    "1000x750": 33.0,
    "1000x910": 34.0,
    "1000x1050": 34.0,
    "1000x1230": 44.0,
    "2000x600": 33.0,
    "2000x750": 33.0,
    "2000x910": 34.0,
    "2000x1050": 34.0,
    "2000x1230": 34.0,
    "3000x600": 32.0,
    "3000x750": 33.0,
    "3000x910": 34.0,
    "3000x1050": 34.0,
    "3000x1230": 34.0,
    "4000x600": 33.0,
    "4000x750": 33.0,
    "4000x910": 34.0,
    "4000x1050": 34.0,
    "4000x1230": 34.0,
    "5000x600": 33.0,
    "5000x750": 33.0,
    "5000x910": 34.0,
    "5000x1050": 34.0,
    "5000x1230": 34.0,
    "6500x600": 32.0,
    "6500x750": 33.0,
    "6500x910": 34.0,
    "6500x1050": 34.0,
    "6500x1230": 34.0,
    "8000x600": 32.0,
    "8000x750": 33.0,
    "8000x910": 34.0,
    "8000x1050": 34.0,
    "8000x1230": 34.0,
    "10000x600": 42.0,
    "10000x750": 42.0,
    "10000x910": 43.0,
    "10000x1050": 43.0,
    "10000x1230": 44.0,
    "12500x600": 42.0,
    "12500x750": 43.0,
    "12500x910": 44.0,
    "12500x1050": 44.0,
    "12500x1230": 54.0,
    "15500x600": 42.0,
    "15500x750": 43.0,
    "15500x910": 44.0,
    "15500x1050": 54.0,
    "15500x1230": 54.0,
    "20000x600": 52.0,
    "20000x750": 52.0,
    "20000x910": 53.0,
    "20000x1050": 54.0,
    "20000x1230": 54.0,
    "25000x600": 52.0,
    "25000x750": 52.0,
    "25000x910": 53.0,
    "25000x1050": 54.0,
    "25000x1230": 54.0,
    "30000x600": 52.0,
    "30000x750": 52.0,
    "30000x910": 53.0,
    "30000x1050": 54.0,
    "30000x1230": 54.0,
    "35000x600": 52.0,
    "35000x750": 53.0,
    "35000x910": 54.0,
    "35000x1050": 54.0,
    "35000x1230": 54.0,
    "40000x600": 62.0,
    "40000x750": 62.0,
    "40000x910": 63.0,
    "40000x1050": 64.0,
    "40000x1230": 64.0,
    "50000x600": 62.0,
    "50000x750": 62.0,
    "50000x910": 63.0,
    "50000x1050": 64.0,
    "50000x1230": 64.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 72.0,
  },
  "2000x4000": {
    "1000x600": 52.0,
    "1000x750": 53.0,
    "1000x910": 54.0,
    "1000x1050": 54.0,
    "1000x1230": 54.0,
    "2000x600": 52.0,
    "2000x750": 53.0,
    "2000x910": 54.0,
    "2000x1050": 54.0,
    "2000x1230": 54.0,
    "3000x600": 52.0,
    "3000x750": 53.0,
    "3000x910": 54.0,
    "3000x1050": 54.0,
    "3000x1230": 54.0,
    "4000x600": 52.0,
    "4000x750": 53.0,
    "4000x910": 54.0,
    "4000x1050": 54.0,
    "4000x1230": 54.0,
    "5000x600": 52.0,
    "5000x750": 53.0,
    "5000x910": 54.0,
    "5000x1050": 54.0,
    "5000x1230": 54.0,
    "6500x600": 52.0,
    "6500x750": 53.0,
    "6500x910": 54.0,
    "6500x1050": 54.0,
    "6500x1230": 54.0,
    "8000x600": 52.0,
    "8000x750": 53.0,
    "8000x910": 54.0,
    "8000x1050": 54.0,
    "8000x1230": 54.0,
    "10000x600": 52.0,
    "10000x750": 53.0,
    "10000x910": 54.0,
    "10000x1050": 54.0,
    "10000x1230": 54.0,
    "12500x600": 52.0,
    "12500x750": 53.0,
    "12500x910": 54.0,
    "12500x1050": 54.0,
    "12500x1230": 54.0,
    "15500x600": 52.0,
    "15500x750": 53.0,
    "15500x910": 54.0,
    "15500x1050": 54.0,
    "15500x1230": 54.0,
    "20000x600": 52.0,
    "20000x750": 53.0,
    "20000x910": 54.0,
    "20000x1050": 54.0,
    "20000x1230": 54.0,
    "25000x600": 52.0,
    "25000x750": 53.0,
    "25000x910": 54.0,
    "25000x1050": 54.0,
    "25000x1230": 54.0,
    "30000x600": 52.0,
    "30000x750": 53.0,
    "30000x910": 54.0,
    "30000x1050": 54.0,
    "30000x1230": 54.0,
    "35000x600": 53.0,
    "35000x750": 54.0,
    "35000x910": 54.0,
    "35000x1050": 54.0,
    "35000x1230": 66.0,
    "40000x600": 62.0,
    "40000x750": 63.0,
    "40000x910": 64.0,
    "40000x1050": 65.0,
    "40000x1230": 66.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 72.0,
    "50000x1050": 73.0,
    "50000x1230": 73.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 72.0,
    "60000x1050": 73.0,
    "60000x1230": 73.0,
  },
  "2000x5000": {
    "1000x600": 54.0,
    "1000x750": 54.0,
    "1000x910": 66.0,
    "1000x1050": 66.0,
    "1000x1230": 73.0,
    "2000x600": 54.0,
    "2000x750": 54.0,
    "2000x910": 66.0,
    "2000x1050": 66.0,
    "2000x1230": 73.0,
    "3000x600": 54.0,
    "3000x750": 54.0,
    "3000x910": 66.0,
    "3000x1050": 66.0,
    "3000x1230": 73.0,
    "4000x600": 54.0,
    "4000x750": 54.0,
    "4000x910": 66.0,
    "4000x1050": 66.0,
    "4000x1230": 73.0,
    "5000x600": 54.0,
    "5000x750": 54.0,
    "5000x910": 66.0,
    "5000x1050": 66.0,
    "5000x1230": 73.0,
    "6500x600": 54.0,
    "6500x750": 54.0,
    "6500x910": 66.0,
    "6500x1050": 66.0,
    "6500x1230": 73.0,
    "8000x600": 54.0,
    "8000x750": 54.0,
    "8000x910": 66.0,
    "8000x1050": 66.0,
    "8000x1230": 73.0,
    "10000x600": 54.0,
    "10000x750": 54.0,
    "10000x910": 66.0,
    "10000x1050": 66.0,
    "10000x1230": 73.0,
    "12500x600": 54.0,
    "12500x750": 54.0,
    "12500x910": 66.0,
    "12500x1050": 66.0,
    "12500x1230": 73.0,
    "15500x600": 54.0,
    "15500x750": 54.0,
    "15500x910": 66.0,
    "15500x1050": 66.0,
    "15500x1230": 73.0,
    "20000x600": 54.0,
    "20000x750": 54.0,
    "20000x910": 66.0,
    "20000x1050": 66.0,
    "20000x1230": 73.0,
    "25000x600": 54.0,
    "25000x750": 54.0,
    "25000x910": 66.0,
    "25000x1050": 66.0,
    "25000x1230": 73.0,
    "30000x600": 54.0,
    "30000x750": 54.0,
    "30000x910": 66.0,
    "30000x1050": 66.0,
    "30000x1230": 73.0,
    "35000x600": 54.0,
    "35000x750": 66.0,
    "35000x910": 66.0,
    "35000x1050": 73.0,
    "35000x1230": 73.0,
    "40000x600": 66.0,
    "40000x750": 66.0,
    "40000x910": 73.0,
    "40000x1050": 73.0,
    "40000x1230": 73.0,
    "50000x600": 72.0,
    "50000x750": 73.0,
    "50000x910": 73.0,
    "50000x1050": 73.0,
    "50000x1230": 73.0,
    "60000x600": 72.0,
    "60000x750": 73.0,
    "60000x910": 73.0,
    "60000x1050": 73.0,
    "60000x1230": 73.0,
  },
  "2500x1000": {
    "1000x600": 21.0,
    "1000x750": 21.0,
    "1000x910": 21.0,
    "1000x1050": 21.0,
    "1000x1230": 22.0,
    "2000x600": 21.0,
    "2000x750": 21.0,
    "2000x910": 21.0,
    "2000x1050": 21.0,
    "2000x1230": 31.0,
    "3000x600": 31.0,
    "3000x750": 31.0,
    "3000x910": 31.0,
    "3000x1050": 31.0,
    "3000x1230": 31.0,
    "4000x600": 31.0,
    "4000x750": 31.0,
    "4000x910": 31.0,
    "4000x1050": 31.0,
    "4000x1230": 31.0,
    "5000x600": 31.0,
    "5000x750": 31.0,
    "5000x910": 31.0,
    "5000x1050": 31.0,
    "5000x1230": 31.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 31.0,
    "6500x1050": 31.0,
    "6500x1230": 31.0,
    "8000x600": 41.0,
    "8000x750": 41.0,
    "8000x910": 41.0,
    "8000x1050": 41.0,
    "8000x1230": 41.0,
    "10000x600": 41.0,
    "10000x750": 41.0,
    "10000x910": 41.0,
    "10000x1050": 41.0,
    "10000x1230": 41.0,
    "12500x600": 41.0,
    "12500x750": 41.0,
    "12500x910": 41.0,
    "12500x1050": 41.0,
    "12500x1230": 41.0,
    "15500x600": 51.0,
    "15500x750": 51.0,
    "15500x910": 51.0,
    "15500x1050": 51.0,
    "15500x1230": 51.0,
    "20000x600": 51.0,
    "20000x750": 51.0,
    "20000x910": 51.0,
    "20000x1050": 51.0,
    "20000x1230": 51.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 51.0,
    "25000x1230": 51.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 61.0,
    "35000x750": 61.0,
    "35000x910": 61.0,
    "35000x1050": 61.0,
    "35000x1230": 61.0,
    "40000x600": 61.0,
    "40000x750": 61.0,
    "40000x910": 62.0,
    "40000x1050": 62.0,
    "40000x1230": 62.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "2500x1500": {
    "1000x600": 22.0,
    "1000x750": 22.0,
    "1000x910": 22.0,
    "1000x1050": 22.0,
    "1000x1230": 22.0,
    "2000x600": 21.0,
    "2000x750": 22.0,
    "2000x910": 22.0,
    "2000x1050": 22.0,
    "2000x1230": 22.0,
    "3000x600": 31.0,
    "3000x750": 31.0,
    "3000x910": 31.0,
    "3000x1050": 31.0,
    "3000x1230": 32.0,
    "4000x600": 31.0,
    "4000x750": 31.0,
    "4000x910": 31.0,
    "4000x1050": 31.0,
    "4000x1230": 32.0,
    "5000x600": 31.0,
    "5000x750": 31.0,
    "5000x910": 31.0,
    "5000x1050": 31.0,
    "5000x1230": 32.0,
    "6500x600": 31.0,
    "6500x750": 31.0,
    "6500x910": 31.0,
    "6500x1050": 31.0,
    "6500x1230": 32.0,
    "8000x600": 41.0,
    "8000x750": 41.0,
    "8000x910": 41.0,
    "8000x1050": 41.0,
    "8000x1230": 41.0,
    "10000x600": 41.0,
    "10000x750": 41.0,
    "10000x910": 41.0,
    "10000x1050": 41.0,
    "10000x1230": 41.0,
    "12500x600": 41.0,
    "12500x750": 41.0,
    "12500x910": 41.0,
    "12500x1050": 42.0,
    "12500x1230": 42.0,
    "15500x600": 51.0,
    "15500x750": 51.0,
    "15500x910": 51.0,
    "15500x1050": 51.0,
    "15500x1230": 51.0,
    "20000x600": 51.0,
    "20000x750": 51.0,
    "20000x910": 51.0,
    "20000x1050": 51.0,
    "20000x1230": 51.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 51.0,
    "25000x1230": 51.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 51.0,
    "30000x1050": 51.0,
    "30000x1230": 51.0,
    "35000x600": 61.0,
    "35000x750": 61.0,
    "35000x910": 61.0,
    "35000x1050": 61.0,
    "35000x1230": 62.0,
    "40000x600": 61.0,
    "40000x750": 61.0,
    "40000x910": 62.0,
    "40000x1050": 62.0,
    "40000x1230": 62.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "2500x2000": {
    "1000x600": 22.0,
    "1000x750": 22.0,
    "1000x910": 22.0,
    "1000x1050": 22.0,
    "1000x1230": 23.0,
    "2000x600": 22.0,
    "2000x750": 22.0,
    "2000x910": 22.0,
    "2000x1050": 22.0,
    "2000x1230": 32.0,
    "3000x600": 31.0,
    "3000x750": 32.0,
    "3000x910": 32.0,
    "3000x1050": 32.0,
    "3000x1230": 32.0,
    "4000x600": 31.0,
    "4000x750": 32.0,
    "4000x910": 32.0,
    "4000x1050": 32.0,
    "4000x1230": 32.0,
    "5000x600": 32.0,
    "5000x750": 32.0,
    "5000x910": 32.0,
    "5000x1050": 32.0,
    "5000x1230": 32.0,
    "6500x600": 31.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 32.0,
    "6500x1230": 32.0,
    "8000x600": 41.0,
    "8000x750": 41.0,
    "8000x910": 42.0,
    "8000x1050": 42.0,
    "8000x1230": 42.0,
    "10000x600": 41.0,
    "10000x750": 41.0,
    "10000x910": 42.0,
    "10000x1050": 42.0,
    "10000x1230": 42.0,
    "12500x600": 41.0,
    "12500x750": 42.0,
    "12500x910": 42.0,
    "12500x1050": 42.0,
    "12500x1230": 42.0,
    "15500x600": 51.0,
    "15500x750": 51.0,
    "15500x910": 52.0,
    "15500x1050": 52.0,
    "15500x1230": 52.0,
    "20000x600": 51.0,
    "20000x750": 51.0,
    "20000x910": 52.0,
    "20000x1050": 52.0,
    "20000x1230": 52.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 52.0,
    "25000x1050": 52.0,
    "25000x1230": 52.0,
    "30000x600": 51.0,
    "30000x750": 51.0,
    "30000x910": 52.0,
    "30000x1050": 52.0,
    "30000x1230": 52.0,
    "35000x600": 61.0,
    "35000x750": 61.0,
    "35000x910": 62.0,
    "35000x1050": 62.0,
    "35000x1230": 62.0,
    "40000x600": 61.0,
    "40000x750": 61.0,
    "40000x910": 62.0,
    "40000x1050": 62.0,
    "40000x1230": 62.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "2500x2500": {
    "1000x600": 22.0,
    "1000x750": 23.0,
    "1000x910": 32.0,
    "1000x1050": 33.0,
    "1000x1230": 33.0,
    "2000x600": 22.0,
    "2000x750": 22.0,
    "2000x910": 23.0,
    "2000x1050": 32.0,
    "2000x1230": 33.0,
    "3000x600": 32.0,
    "3000x750": 32.0,
    "3000x910": 32.0,
    "3000x1050": 32.0,
    "3000x1230": 33.0,
    "4000x600": 32.0,
    "4000x750": 32.0,
    "4000x910": 32.0,
    "4000x1050": 32.0,
    "4000x1230": 33.0,
    "5000x600": 32.0,
    "5000x750": 32.0,
    "5000x910": 32.0,
    "5000x1050": 32.0,
    "5000x1230": 33.0,
    "6500x600": 32.0,
    "6500x750": 32.0,
    "6500x910": 32.0,
    "6500x1050": 32.0,
    "6500x1230": 33.0,
    "8000x600": 42.0,
    "8000x750": 42.0,
    "8000x910": 42.0,
    "8000x1050": 42.0,
    "8000x1230": 42.0,
    "10000x600": 42.0,
    "10000x750": 42.0,
    "10000x910": 42.0,
    "10000x1050": 42.0,
    "10000x1230": 42.0,
    "12500x600": 42.0,
    "12500x750": 42.0,
    "12500x910": 42.0,
    "12500x1050": 43.0,
    "12500x1230": 44.0,
    "15500x600": 52.0,
    "15500x750": 52.0,
    "15500x910": 52.0,
    "15500x1050": 52.0,
    "15500x1230": 52.0,
    "20000x600": 52.0,
    "20000x750": 52.0,
    "20000x910": 52.0,
    "20000x1050": 52.0,
    "20000x1230": 52.0,
    "25000x600": 52.0,
    "25000x750": 52.0,
    "25000x910": 52.0,
    "25000x1050": 52.0,
    "25000x1230": 52.0,
    "30000x600": 52.0,
    "30000x750": 52.0,
    "30000x910": 52.0,
    "30000x1050": 52.0,
    "30000x1230": 52.0,
    "35000x600": 61.0,
    "35000x750": 62.0,
    "35000x910": 62.0,
    "35000x1050": 62.0,
    "35000x1230": 63.0,
    "40000x600": 61.0,
    "40000x750": 62.0,
    "40000x910": 62.0,
    "40000x1050": 62.0,
    "40000x1230": 63.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "2500x3000": {
    "1000x600": 32.0,
    "1000x750": 33.0,
    "1000x910": 34.0,
    "1000x1050": 34.0,
    "1000x1230": 34.0,
    "2000x600": 32.0,
    "2000x750": 32.0,
    "2000x910": 33.0,
    "2000x1050": 34.0,
    "2000x1230": 34.0,
    "3000x600": 32.0,
    "3000x750": 32.0,
    "3000x910": 33.0,
    "3000x1050": 34.0,
    "3000x1230": 34.0,
    "4000x600": 32.0,
    "4000x750": 32.0,
    "4000x910": 33.0,
    "4000x1050": 34.0,
    "4000x1230": 34.0,
    "5000x600": 32.0,
    "5000x750": 32.0,
    "5000x910": 33.0,
    "5000x1050": 34.0,
    "5000x1230": 34.0,
    "6500x600": 32.0,
    "6500x750": 32.0,
    "6500x910": 33.0,
    "6500x1050": 34.0,
    "6500x1230": 34.0,
    "8000x600": 42.0,
    "8000x750": 42.0,
    "8000x910": 42.0,
    "8000x1050": 43.0,
    "8000x1230": 44.0,
    "10000x600": 42.0,
    "10000x750": 42.0,
    "10000x910": 42.0,
    "10000x1050": 43.0,
    "10000x1230": 44.0,
    "12500x600": 42.0,
    "12500x750": 43.0,
    "12500x910": 44.0,
    "12500x1050": 44.0,
    "12500x1230": 45.0,
    "15500x600": 52.0,
    "15500x750": 52.0,
    "15500x910": 53.0,
    "15500x1050": 53.0,
    "15500x1230": 54.0,
    "20000x600": 52.0,
    "20000x750": 52.0,
    "20000x910": 52.0,
    "20000x1050": 53.0,
    "20000x1230": 54.0,
    "25000x600": 52.0,
    "25000x750": 52.0,
    "25000x910": 52.0,
    "25000x1050": 53.0,
    "25000x1230": 54.0,
    "30000x600": 52.0,
    "30000x750": 52.0,
    "30000x910": 52.0,
    "30000x1050": 53.0,
    "30000x1230": 54.0,
    "35000x600": 62.0,
    "35000x750": 62.0,
    "35000x910": 62.0,
    "35000x1050": 63.0,
    "35000x1230": 64.0,
    "40000x600": 62.0,
    "40000x750": 62.0,
    "40000x910": 62.0,
    "40000x1050": 64.0,
    "40000x1230": 64.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 72.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 72.0,
  },
  "2500x3500": {
    "1000x600": 42.0,
    "1000x750": 44.0,
    "1000x910": 44.0,
    "1000x1050": 45.0,
    "1000x1230": 54.0,
    "2000x600": 42.0,
    "2000x750": 43.0,
    "2000x910": 44.0,
    "2000x1050": 44.0,
    "2000x1230": 45.0,
    "3000x600": 42.0,
    "3000x750": 43.0,
    "3000x910": 44.0,
    "3000x1050": 44.0,
    "3000x1230": 45.0,
    "4000x600": 42.0,
    "4000x750": 43.0,
    "4000x910": 44.0,
    "4000x1050": 44.0,
    "4000x1230": 45.0,
    "5000x600": 42.0,
    "5000x750": 43.0,
    "5000x910": 44.0,
    "5000x1050": 44.0,
    "5000x1230": 45.0,
    "6500x600": 42.0,
    "6500x750": 43.0,
    "6500x910": 44.0,
    "6500x1050": 44.0,
    "6500x1230": 45.0,
    "8000x600": 42.0,
    "8000x750": 43.0,
    "8000x910": 44.0,
    "8000x1050": 44.0,
    "8000x1230": 45.0,
    "10000x600": 42.0,
    "10000x750": 43.0,
    "10000x910": 44.0,
    "10000x1050": 44.0,
    "10000x1230": 45.0,
    "12500x600": 43.0,
    "12500x750": 44.0,
    "12500x910": 45.0,
    "12500x1050": 64.0,
    "12500x1230": 64.0,
    "15500x600": 52.0,
    "15500x750": 53.0,
    "15500x910": 54.0,
    "15500x1050": 54.0,
    "15500x1230": 54.0,
    "20000x600": 52.0,
    "20000x750": 53.0,
    "20000x910": 54.0,
    "20000x1050": 54.0,
    "20000x1230": 54.0,
    "25000x600": 52.0,
    "25000x750": 53.0,
    "25000x910": 54.0,
    "25000x1050": 54.0,
    "25000x1230": 54.0,
    "30000x600": 52.0,
    "30000x750": 53.0,
    "30000x910": 54.0,
    "30000x1050": 54.0,
    "30000x1230": 54.0,
    "35000x600": 62.0,
    "35000x750": 63.0,
    "35000x910": 64.0,
    "35000x1050": 65.0,
    "35000x1230": 66.0,
    "40000x600": 62.0,
    "40000x750": 63.0,
    "40000x910": 64.0,
    "40000x1050": 65.0,
    "40000x1230": 66.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 72.0,
    "50000x1050": 73.0,
    "50000x1230": 73.0,
    "60000x600": 71.0,
    "60000x750": 72.0,
    "60000x910": 73.0,
    "60000x1050": 73.0,
    "60000x1230": 73.0,
  },
  "2500x4000": {
    "1000x600": 53.0,
    "1000x750": 54.0,
    "1000x910": 54.0,
    "1000x1050": 54.0,
    "1000x1230": 66.0,
    "2000x600": 53.0,
    "2000x750": 54.0,
    "2000x910": 54.0,
    "2000x1050": 54.0,
    "2000x1230": 66.0,
    "3000x600": 53.0,
    "3000x750": 54.0,
    "3000x910": 54.0,
    "3000x1050": 54.0,
    "3000x1230": 66.0,
    "4000x600": 53.0,
    "4000x750": 54.0,
    "4000x910": 54.0,
    "4000x1050": 54.0,
    "4000x1230": 66.0,
    "5000x600": 53.0,
    "5000x750": 54.0,
    "5000x910": 54.0,
    "5000x1050": 54.0,
    "5000x1230": 66.0,
    "6500x600": 53.0,
    "6500x750": 54.0,
    "6500x910": 54.0,
    "6500x1050": 54.0,
    "6500x1230": 66.0,
    "8000x600": 53.0,
    "8000x750": 54.0,
    "8000x910": 54.0,
    "8000x1050": 54.0,
    "8000x1230": 66.0,
    "10000x600": 53.0,
    "10000x750": 54.0,
    "10000x910": 54.0,
    "10000x1050": 54.0,
    "10000x1230": 66.0,
    "12500x600": 53.0,
    "12500x750": 54.0,
    "12500x910": 54.0,
    "12500x1050": 54.0,
    "12500x1230": 66.0,
    "15500x600": 53.0,
    "15500x750": 54.0,
    "15500x910": 54.0,
    "15500x1050": 54.0,
    "15500x1230": 66.0,
    "20000x600": 53.0,
    "20000x750": 54.0,
    "20000x910": 54.0,
    "20000x1050": 54.0,
    "20000x1230": 66.0,
    "25000x600": 53.0,
    "25000x750": 54.0,
    "25000x910": 54.0,
    "25000x1050": 54.0,
    "25000x1230": 66.0,
    "30000x600": 53.0,
    "30000x750": 54.0,
    "30000x910": 54.0,
    "30000x1050": 54.0,
    "30000x1230": 66.0,
    "35000x600": 64.0,
    "35000x750": 65.0,
    "35000x910": 66.0,
    "35000x1050": 66.0,
    "35000x1230": 73.0,
    "40000x600": 64.0,
    "40000x750": 65.0,
    "40000x910": 66.0,
    "40000x1050": 66.0,
    "40000x1230": 73.0,
    "50000x600": 71.0,
    "50000x750": 72.0,
    "50000x910": 73.0,
    "50000x1050": 73.0,
    "50000x1230": 73.0,
    "60000x600": 71.0,
    "60000x750": 72.0,
    "60000x910": 73.0,
    "60000x1050": 73.0,
    "60000x1230": 73.0,
  },
  "2500x5000": {
    "1000x600": 54.0,
    "1000x750": 66.0,
    "1000x910": 66.0,
    "1000x1050": 73.0,
    "1000x1230": 73.0,
    "2000x600": 54.0,
    "2000x750": 66.0,
    "2000x910": 66.0,
    "2000x1050": 73.0,
    "2000x1230": 73.0,
    "3000x600": 54.0,
    "3000x750": 66.0,
    "3000x910": 66.0,
    "3000x1050": 73.0,
    "3000x1230": 73.0,
    "4000x600": 54.0,
    "4000x750": 66.0,
    "4000x910": 66.0,
    "4000x1050": 73.0,
    "4000x1230": 73.0,
    "5000x600": 54.0,
    "5000x750": 66.0,
    "5000x910": 66.0,
    "5000x1050": 73.0,
    "5000x1230": 73.0,
    "6500x600": 54.0,
    "6500x750": 66.0,
    "6500x910": 66.0,
    "6500x1050": 73.0,
    "6500x1230": 73.0,
    "8000x600": 54.0,
    "8000x750": 66.0,
    "8000x910": 66.0,
    "8000x1050": 73.0,
    "8000x1230": 73.0,
    "10000x600": 54.0,
    "10000x750": 66.0,
    "10000x910": 66.0,
    "10000x1050": 73.0,
    "10000x1230": 73.0,
    "12500x600": 54.0,
    "12500x750": 66.0,
    "12500x910": 66.0,
    "12500x1050": 73.0,
    "12500x1230": 73.0,
    "15500x600": 54.0,
    "15500x750": 66.0,
    "15500x910": 66.0,
    "15500x1050": 73.0,
    "15500x1230": 73.0,
    "20000x600": 54.0,
    "20000x750": 66.0,
    "20000x910": 66.0,
    "20000x1050": 73.0,
    "20000x1230": 73.0,
    "25000x600": 54.0,
    "25000x750": 66.0,
    "25000x910": 66.0,
    "25000x1050": 73.0,
    "25000x1230": 73.0,
    "30000x600": 54.0,
    "30000x750": 66.0,
    "30000x910": 66.0,
    "30000x1050": 73.0,
    "30000x1230": 73.0,
    "35000x600": 66.0,
    "35000x750": 66.0,
    "35000x910": 73.0,
    "35000x1050": 83.0,
    "35000x1230": 83.0,
    "40000x600": 66.0,
    "40000x750": 66.0,
    "40000x910": 73.0,
    "40000x1050": 83.0,
    "40000x1230": 83.0,
    "50000x600": 73.0,
    "50000x750": 73.0,
    "50000x910": 73.0,
    "50000x1050": 83.0,
    "50000x1230": 83.0,
    "60000x600": 73.0,
    "60000x750": 73.0,
    "60000x910": 73.0,
    "60000x1050": 83.0,
    "60000x1230": 83.0,
  },
  "3000x1000": {
    "1000x600": 21,
    "1000x750": 21,
    "1000x910": 22,
    "1000x1050": 22,
    "1000x1230": 22,
    "2000x600": 31,
    "2000x750": 31,
    "2000x910": 31,
    "2000x1050": 31,
    "2000x1230": 31,
    "3000x600": 31,
    "3000x750": 31,
    "3000x910": 31,
    "3000x1050": 31,
    "3000x1230": 31,
    "4000x600": 31,
    "4000x750": 31,
    "4000x910": 31,
    "4000x1050": 31,
    "4000x1230": 31,
    "5000x600": 41,
    "5000x750": 41,
    "5000x910": 41,
    "5000x1050": 41,
    "5000x1230": 41,
    "6500x600": 41,
    "6500x750": 41,
    "6500x910": 41,
    "6500x1050": 41,
    "6500x1230": 41,
    "8000x600": 41,
    "8000x750": 41,
    "8000x910": 41,
    "8000x1050": 41,
    "8000x1230": 41,
    "10000x600": 41,
    "10000x750": 41,
    "10000x910": 41,
    "10000x1050": 41,
    "10000x1230": 41,
    "12500x600": 51,
    "12500x750": 51,
    "12500x910": 51,
    "12500x1050": 51,
    "12500x1230": 51,
    "15500x600": 51,
    "15500x750": 51,
    "15500x910": 51,
    "15500x1050": 51,
    "15500x1230": 51,
    "20000x600": 51,
    "20000x750": 51,
    "20000x910": 51,
    "20000x1050": 51,
    "20000x1230": 51,
    "25000x600": 51,
    "25000x750": 51,
    "25000x910": 51,
    "25000x1050": 52,
    "25000x1230": 51,
    "30000x600": 61,
    "30000x750": 61,
    "30000x910": 61,
    "30000x1050": 61,
    "30000x1230": 61,
    "35000x600": 71,
    "35000x750": 71,
    "35000x910": 71,
    "35000x1050": 71,
    "35000x1230": 71,
    "40000x600": 71,
    "40000x750": 71,
    "40000x910": 71,
    "40000x1050": 71,
    "40000x1230": 71,
    "50000x600": 71,
    "50000x750": 71,
    "50000x910": 71,
    "50000x1050": 71,
    "50000x1230": 71,
    "60000x600": 71,
    "60000x750": 71,
    "60000x910": 71,
    "60000x1050": 71,
    "60000x1230": 71,
  },
  "3000x1500": {
    "1000x600": 22.0,
    "1000x750": 22.0,
    "1000x910": 22.0,
    "1000x1050": 22.0,
    "1000x1230": 22.0,
    "2000x600": 31.0,
    "2000x750": 31.0,
    "2000x910": 31.0,
    "2000x1050": 32.0,
    "2000x1230": 32.0,
    "3000x600": 31.0,
    "3000x750": 31.0,
    "3000x910": 31.0,
    "3000x1050": 32.0,
    "3000x1230": 32.0,
    "4000x600": 31.0,
    "4000x750": 31.0,
    "4000x910": 31.0,
    "4000x1050": 32.0,
    "4000x1230": 32.0,
    "5000x600": 41.0,
    "5000x750": 41.0,
    "5000x910": 41.0,
    "5000x1050": 41.0,
    "5000x1230": 42.0,
    "6500x600": 41.0,
    "6500x750": 41.0,
    "6500x910": 41.0,
    "6500x1050": 41.0,
    "6500x1230": 42.0,
    "8000x600": 41.0,
    "8000x750": 41.0,
    "8000x910": 41.0,
    "8000x1050": 41.0,
    "8000x1230": 42.0,
    "10000x600": 41.0,
    "10000x750": 41.0,
    "10000x910": 41.0,
    "10000x1050": 41.0,
    "10000x1230": 42.0,
    "12500x600": 51.0,
    "12500x750": 51.0,
    "12500x910": 51.0,
    "12500x1050": 51.0,
    "12500x1230": 52.0,
    "15500x600": 51.0,
    "15500x750": 51.0,
    "15500x910": 51.0,
    "15500x1050": 51.0,
    "15500x1230": 52.0,
    "20000x600": 51.0,
    "20000x750": 51.0,
    "20000x910": 51.0,
    "20000x1050": 51.0,
    "20000x1230": 52.0,
    "25000x600": 51.0,
    "25000x750": 51.0,
    "25000x910": 51.0,
    "25000x1050": 52.0,
    "25000x1230": 52.0,
    "30000x600": 61.0,
    "30000x750": 61.0,
    "30000x910": 61.0,
    "30000x1050": 61.0,
    "30000x1230": 62.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 71.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "3000x2000": {
    "1000x600": 22.0,
    "1000x750": 22.0,
    "1000x910": 23.0,
    "1000x1050": 23.0,
    "1000x1230": 32.0,
    "2000x600": 32.0,
    "2000x750": 32.0,
    "2000x910": 32.0,
    "2000x1050": 32.0,
    "2000x1230": 32.0,
    "3000x600": 32.0,
    "3000x750": 32.0,
    "3000x910": 32.0,
    "3000x1050": 32.0,
    "3000x1230": 32.0,
    "4000x600": 32.0,
    "4000x750": 32.0,
    "4000x910": 32.0,
    "4000x1050": 32.0,
    "4000x1230": 32.0,
    "5000x600": 41.0,
    "5000x750": 42.0,
    "5000x910": 42.0,
    "5000x1050": 42.0,
    "5000x1230": 42.0,
    "6500x600": 41.0,
    "6500x750": 42.0,
    "6500x910": 42.0,
    "6500x1050": 42.0,
    "6500x1230": 42.0,
    "8000x600": 41.0,
    "8000x750": 42.0,
    "8000x910": 42.0,
    "8000x1050": 42.0,
    "8000x1230": 42.0,
    "10000x600": 41.0,
    "10000x750": 42.0,
    "10000x910": 42.0,
    "10000x1050": 42.0,
    "10000x1230": 42.0,
    "12500x600": 51.0,
    "12500x750": 52.0,
    "12500x910": 52.0,
    "12500x1050": 52.0,
    "12500x1230": 52.0,
    "15500x600": 51.0,
    "15500x750": 52.0,
    "15500x910": 52.0,
    "15500x1050": 52.0,
    "15500x1230": 52.0,
    "20000x600": 51.0,
    "20000x750": 52.0,
    "20000x910": 52.0,
    "20000x1050": 52.0,
    "20000x1230": 52.0,
    "25000x600": 51.0,
    "25000x750": 52.0,
    "25000x910": 52.0,
    "25000x1050": 52.0,
    "25000x1230": 52.0,
    "30000x600": 61.0,
    "30000x750": 61.0,
    "30000x910": 61.0,
    "30000x1050": 62.0,
    "30000x1230": 62.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 71.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "3000x2500": {
    "1000x600": 32.0,
    "1000x750": 32.0,
    "1000x910": 33.0,
    "1000x1050": 33.0,
    "1000x1230": 34.0,
    "2000x600": 32.0,
    "2000x750": 32.0,
    "2000x910": 32.0,
    "2000x1050": 33.0,
    "2000x1230": 34.0,
    "3000x600": 32.0,
    "3000x750": 32.0,
    "3000x910": 32.0,
    "3000x1050": 33.0,
    "3000x1230": 34.0,
    "4000x600": 32.0,
    "4000x750": 32.0,
    "4000x910": 32.0,
    "4000x1050": 33.0,
    "4000x1230": 34.0,
    "5000x600": 42.0,
    "5000x750": 42.0,
    "5000x910": 42.0,
    "5000x1050": 42.0,
    "5000x1230": 43.0,
    "6500x600": 42.0,
    "6500x750": 42.0,
    "6500x910": 42.0,
    "6500x1050": 42.0,
    "6500x1230": 43.0,
    "8000x600": 42.0,
    "8000x750": 42.0,
    "8000x910": 42.0,
    "8000x1050": 42.0,
    "8000x1230": 43.0,
    "10000x600": 42.0,
    "10000x750": 42.0,
    "10000x910": 42.0,
    "10000x1050": 42.0,
    "10000x1230": 43.0,
    "12500x600": 52.0,
    "12500x750": 52.0,
    "12500x910": 52.0,
    "12500x1050": 52.0,
    "12500x1230": 53.0,
    "15500x600": 52.0,
    "15500x750": 52.0,
    "15500x910": 52.0,
    "15500x1050": 52.0,
    "15500x1230": 53.0,
    "20000x600": 52.0,
    "20000x750": 52.0,
    "20000x910": 52.0,
    "20000x1050": 52.0,
    "20000x1230": 53.0,
    "25000x600": 52.0,
    "25000x750": 52.0,
    "25000x910": 52.0,
    "25000x1050": 52.0,
    "25000x1230": 53.0,
    "30000x600": 61.0,
    "30000x750": 62.0,
    "30000x910": 62.0,
    "30000x1050": 62.0,
    "30000x1230": 62.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 71.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "3000x3000": {
    "1000x600": 33.0,
    "1000x750": 34.0,
    "1000x910": 34.0,
    "1000x1050": 44.0,
    "1000x1230": 45.0,
    "2000x600": 32.0,
    "2000x750": 33.0,
    "2000x910": 34.0,
    "2000x1050": 44.0,
    "2000x1230": 45.0,
    "3000x600": 32.0,
    "3000x750": 33.0,
    "3000x910": 34.0,
    "3000x1050": 44.0,
    "3000x1230": 45.0,
    "4000x600": 32.0,
    "4000x750": 33.0,
    "4000x910": 34.0,
    "4000x1050": 44.0,
    "4000x1230": 45.0,
    "5000x600": 42.0,
    "5000x750": 42.0,
    "5000x910": 43.0,
    "5000x1050": 44.0,
    "5000x1230": 45.0,
    "6500x600": 42.0,
    "6500x750": 42.0,
    "6500x910": 43.0,
    "6500x1050": 44.0,
    "6500x1230": 45.0,
    "8000x600": 42.0,
    "8000x750": 42.0,
    "8000x910": 43.0,
    "8000x1050": 44.0,
    "8000x1230": 45.0,
    "10000x600": 42.0,
    "10000x750": 42.0,
    "10000x910": 43.0,
    "10000x1050": 44.0,
    "10000x1230": 45.0,
    "12500x600": 52.0,
    "12500x750": 52.0,
    "12500x910": 53.0,
    "12500x1050": 54.0,
    "12500x1230": 54.0,
    "15500x600": 52.0,
    "15500x750": 52.0,
    "15500x910": 53.0,
    "15500x1050": 54.0,
    "15500x1230": 54.0,
    "20000x600": 52.0,
    "20000x750": 52.0,
    "20000x910": 53.0,
    "20000x1050": 54.0,
    "20000x1230": 54.0,
    "25000x600": 52.0,
    "25000x750": 52.0,
    "25000x910": 53.0,
    "25000x1050": 54.0,
    "25000x1230": 54.0,
    "30000x600": 62.0,
    "30000x750": 62.0,
    "30000x910": 62.0,
    "30000x1050": 63.0,
    "30000x1230": 64.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 72.0,
    "35000x1230": 73.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 72.0,
    "40000x1230": 73.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 72.0,
    "50000x1230": 73.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 72.0,
    "60000x1230": 73.0,
  },
  "3000x3500": {
    "1000x600": 52.0,
    "1000x750": 53.0,
    "1000x910": 54.0,
    "1000x1050": 54.0,
    "1000x1230": 54.0,
    "2000x600": 52.0,
    "2000x750": 52.0,
    "2000x910": 53.0,
    "2000x1050": 54.0,
    "2000x1230": 54.0,
    "3000x600": 52.0,
    "3000x750": 52.0,
    "3000x910": 53.0,
    "3000x1050": 54.0,
    "3000x1230": 54.0,
    "4000x600": 52.0,
    "4000x750": 52.0,
    "4000x910": 53.0,
    "4000x1050": 54.0,
    "4000x1230": 54.0,
    "5000x600": 52.0,
    "5000x750": 52.0,
    "5000x910": 53.0,
    "5000x1050": 54.0,
    "5000x1230": 54.0,
    "6500x600": 52.0,
    "6500x750": 52.0,
    "6500x910": 53.0,
    "6500x1050": 54.0,
    "6500x1230": 54.0,
    "8000x600": 52.0,
    "8000x750": 52.0,
    "8000x910": 53.0,
    "8000x1050": 54.0,
    "8000x1230": 54.0,
    "10000x600": 52.0,
    "10000x750": 52.0,
    "10000x910": 53.0,
    "10000x1050": 54.0,
    "10000x1230": 54.0,
    "12500x600": 53.0,
    "12500x750": 54.0,
    "12500x910": 54.0,
    "12500x1050": 54.0,
    "12500x1230": 66.0,
    "15500x600": 53.0,
    "15500x750": 54.0,
    "15500x910": 54.0,
    "15500x1050": 54.0,
    "15500x1230": 66.0,
    "20000x600": 53.0,
    "20000x750": 54.0,
    "20000x910": 54.0,
    "20000x1050": 54.0,
    "20000x1230": 66.0,
    "25000x600": 53.0,
    "25000x750": 54.0,
    "25000x910": 54.0,
    "25000x1050": 54.0,
    "25000x1230": 66.0,
    "30000x600": 62.0,
    "30000x750": 63.0,
    "30000x910": 64.0,
    "30000x1050": 65.0,
    "30000x1230": 66.0,
    "35000x600": 71.0,
    "35000x750": 72.0,
    "35000x910": 73.0,
    "35000x1050": 73.0,
    "35000x1230": 73.0,
    "40000x600": 71.0,
    "40000x750": 72.0,
    "40000x910": 73.0,
    "40000x1050": 73.0,
    "40000x1230": 73.0,
    "50000x600": 71.0,
    "50000x750": 72.0,
    "50000x910": 73.0,
    "50000x1050": 73.0,
    "50000x1230": 73.0,
    "60000x600": 71.0,
    "60000x750": 72.0,
    "60000x910": 73.0,
    "60000x1050": 73.0,
    "60000x1230": 73.0,
  },
  "3000x4000": {
    "1000x600": 54.0,
    "1000x750": 54.0,
    "1000x910": 66.0,
    "1000x1050": 66.0,
    "1000x1230": 66.0,
    "2000x600": 54.0,
    "2000x750": 54.0,
    "2000x910": 66.0,
    "2000x1050": 66.0,
    "2000x1230": 66.0,
    "3000x600": 54.0,
    "3000x750": 54.0,
    "3000x910": 66.0,
    "3000x1050": 66.0,
    "3000x1230": 66.0,
    "4000x600": 54.0,
    "4000x750": 54.0,
    "4000x910": 66.0,
    "4000x1050": 66.0,
    "4000x1230": 66.0,
    "5000x600": 54.0,
    "5000x750": 54.0,
    "5000x910": 66.0,
    "5000x1050": 66.0,
    "5000x1230": 66.0,
    "6500x600": 54.0,
    "6500x750": 54.0,
    "6500x910": 66.0,
    "6500x1050": 66.0,
    "6500x1230": 66.0,
    "8000x600": 54.0,
    "8000x750": 54.0,
    "8000x910": 66.0,
    "8000x1050": 66.0,
    "8000x1230": 66.0,
    "10000x600": 54.0,
    "10000x750": 54.0,
    "10000x910": 66.0,
    "10000x1050": 66.0,
    "10000x1230": 66.0,
    "12500x600": 54.0,
    "12500x750": 54.0,
    "12500x910": 66.0,
    "12500x1050": 66.0,
    "12500x1230": 66.0,
    "15500x600": 54.0,
    "15500x750": 54.0,
    "15500x910": 66.0,
    "15500x1050": 66.0,
    "15500x1230": 66.0,
    "20000x600": 54.0,
    "20000x750": 54.0,
    "20000x910": 66.0,
    "20000x1050": 66.0,
    "20000x1230": 66.0,
    "25000x600": 54.0,
    "25000x750": 54.0,
    "25000x910": 66.0,
    "25000x1050": 66.0,
    "25000x1230": 66.0,
    "30000x600": 63.0,
    "30000x750": 64.0,
    "30000x910": 66.0,
    "30000x1050": 66.0,
    "30000x1230": 66.0,
    "35000x600": 72.0,
    "35000x750": 73.0,
    "35000x910": 73.0,
    "35000x1050": 73.0,
    "35000x1230": 73.0,
    "40000x600": 72.0,
    "40000x750": 73.0,
    "40000x910": 73.0,
    "40000x1050": 73.0,
    "40000x1230": 73.0,
    "50000x600": 72.0,
    "50000x750": 73.0,
    "50000x910": 73.0,
    "50000x1050": 73.0,
    "50000x1230": 73.0,
    "60000x600": 72.0,
    "60000x750": 73.0,
    "60000x910": 73.0,
    "60000x1050": 73.0,
    "60000x1230": 73.0,
  },
  "3000x5000": {
    "1000x600": 66.0,
    "1000x750": 66.0,
    "1000x910": 73.0,
    "1000x1050": 73.0,
    "1000x1230": 83.0,
    "2000x600": 66.0,
    "2000x750": 66.0,
    "2000x910": 73.0,
    "2000x1050": 73.0,
    "2000x1230": 83.0,
    "3000x600": 66.0,
    "3000x750": 66.0,
    "3000x910": 73.0,
    "3000x1050": 73.0,
    "3000x1230": 83.0,
    "4000x600": 66.0,
    "4000x750": 66.0,
    "4000x910": 73.0,
    "4000x1050": 73.0,
    "4000x1230": 83.0,
    "5000x600": 66.0,
    "5000x750": 66.0,
    "5000x910": 73.0,
    "5000x1050": 73.0,
    "5000x1230": 83.0,
    "6500x600": 66.0,
    "6500x750": 66.0,
    "6500x910": 73.0,
    "6500x1050": 73.0,
    "6500x1230": 83.0,
    "8000x600": 66.0,
    "8000x750": 66.0,
    "8000x910": 73.0,
    "8000x1050": 73.0,
    "8000x1230": 83.0,
    "10000x600": 66.0,
    "10000x750": 66.0,
    "10000x910": 73.0,
    "10000x1050": 73.0,
    "10000x1230": 83.0,
    "12500x600": 66.0,
    "12500x750": 66.0,
    "12500x910": 73.0,
    "12500x1050": 73.0,
    "12500x1230": 83.0,
    "15500x600": 66.0,
    "15500x750": 66.0,
    "15500x910": 73.0,
    "15500x1050": 73.0,
    "15500x1230": 83.0,
    "20000x600": 66.0,
    "20000x750": 66.0,
    "20000x910": 73.0,
    "20000x1050": 73.0,
    "20000x1230": 83.0,
    "25000x600": 66.0,
    "25000x750": 66.0,
    "25000x910": 73.0,
    "25000x1050": 73.0,
    "25000x1230": 83.0,
    "30000x600": 66.0,
    "30000x750": 66.0,
    "30000x910": 73.0,
    "30000x1050": 73.0,
    "30000x1230": 83.0,
    "35000x600": 73.0,
    "35000x750": 73.0,
    "35000x910": 83.0,
    "35000x1050": 83.0,
    "35000x1230": 83.0,
    "40000x600": 73.0,
    "40000x750": 73.0,
    "40000x910": 83.0,
    "40000x1050": 83.0,
    "40000x1230": 83.0,
    "50000x600": 73.0,
    "50000x750": 73.0,
    "50000x910": 83.0,
    "50000x1050": 83.0,
    "50000x1230": 83.0,
    "60000x600": 73.0,
    "60000x750": 73.0,
    "60000x910": 83.0,
    "60000x1050": 83.0,
    "60000x1230": 83.0,
  },
  "3500x1000": {
    "1000x600": 31.0,
    "1000x750": 31.0,
    "1000x910": 31.0,
    "1000x1050": 31.0,
    "1000x1230": 32.0,
    "2000x600": 31.0,
    "2000x750": 31.0,
    "2000x910": 31.0,
    "2000x1050": 31.0,
    "2000x1230": 41.0,
    "3000x600": 31.0,
    "3000x750": 31.0,
    "3000x910": 31.0,
    "3000x1050": 31.0,
    "3000x1230": 41.0,
    "4000x600": 41.0,
    "4000x750": 41.0,
    "4000x910": 41.0,
    "4000x1050": 41.0,
    "4000x1230": 41.0,
    "5000x600": 41.0,
    "5000x750": 41.0,
    "5000x910": 41.0,
    "5000x1050": 41.0,
    "5000x1230": 41.0,
    "6500x600": 41.0,
    "6500x750": 41.0,
    "6500x910": 41.0,
    "6500x1050": 41.0,
    "6500x1230": 41.0,
    "8000x600": 41.0,
    "8000x750": 41.0,
    "8000x910": 41.0,
    "8000x1050": 41.0,
    "8000x1230": 41.0,
    "10000x600": 51.0,
    "10000x750": 51.0,
    "10000x910": 51.0,
    "10000x1050": 51.0,
    "10000x1230": 51.0,
    "12500x600": 51.0,
    "12500x750": 51.0,
    "12500x910": 51.0,
    "12500x1050": 51.0,
    "12500x1230": 51.0,
    "15500x600": 51.0,
    "15500x750": 51.0,
    "15500x910": 51.0,
    "15500x1050": 51.0,
    "15500x1230": 51.0,
    "20000x600": 51.0,
    "20000x750": 51.0,
    "20000x910": 51.0,
    "20000x1050": 51.0,
    "20000x1230": 51.0,
    "25000x600": 61.0,
    "25000x750": 61.0,
    "25000x910": 61.0,
    "25000x1050": 61.0,
    "25000x1230": 61.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 71.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "3500x1500": {
    "1000x600": 31.0,
    "1000x750": 32.0,
    "1000x910": 32.0,
    "1000x1050": 32.0,
    "1000x1230": 32.0,
    "2000x600": 31.0,
    "2000x750": 32.0,
    "2000x910": 32.0,
    "2000x1050": 32.0,
    "2000x1230": 32.0,
    "3000x600": 31.0,
    "3000x750": 32.0,
    "3000x910": 32.0,
    "3000x1050": 32.0,
    "3000x1230": 32.0,
    "4000x600": 41.0,
    "4000x750": 41.0,
    "4000x910": 41.0,
    "4000x1050": 42.0,
    "4000x1230": 42.0,
    "5000x600": 41.0,
    "5000x750": 41.0,
    "5000x910": 41.0,
    "5000x1050": 42.0,
    "5000x1230": 42.0,
    "6500x600": 41.0,
    "6500x750": 41.0,
    "6500x910": 41.0,
    "6500x1050": 42.0,
    "6500x1230": 42.0,
    "8000x600": 41.0,
    "8000x750": 41.0,
    "8000x910": 41.0,
    "8000x1050": 42.0,
    "8000x1230": 42.0,
    "10000x600": 51.0,
    "10000x750": 51.0,
    "10000x910": 51.0,
    "10000x1050": 51.0,
    "10000x1230": 51.0,
    "12500x600": 51.0,
    "12500x750": 51.0,
    "12500x910": 51.0,
    "12500x1050": 52.0,
    "12500x1230": 52.0,
    "15500x600": 51.0,
    "15500x750": 51.0,
    "15500x910": 51.0,
    "15500x1050": 52.0,
    "15500x1230": 52.0,
    "20000x600": 51.0,
    "20000x750": 51.0,
    "20000x910": 51.0,
    "20000x1050": 52.0,
    "20000x1230": 52.0,
    "25000x600": 61.0,
    "25000x750": 61.0,
    "25000x910": 61.0,
    "25000x1050": 61.0,
    "25000x1230": 62.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 71.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "3500x2000": {
    "1000x600": 32.0,
    "1000x750": 32.0,
    "1000x910": 32.0,
    "1000x1050": 32.0,
    "1000x1230": 33.0,
    "2000x600": 32.0,
    "2000x750": 32.0,
    "2000x910": 32.0,
    "2000x1050": 32.0,
    "2000x1230": 33.0,
    "3000x600": 32.0,
    "3000x750": 32.0,
    "3000x910": 32.0,
    "3000x1050": 32.0,
    "3000x1230": 33.0,
    "4000x600": 42.0,
    "4000x750": 42.0,
    "4000x910": 42.0,
    "4000x1050": 42.0,
    "4000x1230": 42.0,
    "5000x600": 42.0,
    "5000x750": 42.0,
    "5000x910": 42.0,
    "5000x1050": 42.0,
    "5000x1230": 42.0,
    "6500x600": 42.0,
    "6500x750": 42.0,
    "6500x910": 42.0,
    "6500x1050": 42.0,
    "6500x1230": 42.0,
    "8000x600": 42.0,
    "8000x750": 42.0,
    "8000x910": 42.0,
    "8000x1050": 42.0,
    "8000x1230": 42.0,
    "10000x600": 51.0,
    "10000x750": 51.0,
    "10000x910": 52.0,
    "10000x1050": 52.0,
    "10000x1230": 52.0,
    "12500x600": 51.0,
    "12500x750": 52.0,
    "12500x910": 52.0,
    "12500x1050": 52.0,
    "12500x1230": 52.0,
    "15500x600": 51.0,
    "15500x750": 52.0,
    "15500x910": 52.0,
    "15500x1050": 52.0,
    "15500x1230": 52.0,
    "20000x600": 51.0,
    "20000x750": 52.0,
    "20000x910": 52.0,
    "20000x1050": 52.0,
    "20000x1230": 52.0,
    "25000x600": 61.0,
    "25000x750": 61.0,
    "25000x910": 62.0,
    "25000x1050": 62.0,
    "25000x1230": 62.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 71.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 71.0,
  },
  "3500x2500": {
    "1000x600": 32.0,
    "1000x750": 33.0,
    "1000x910": 34.0,
    "1000x1050": 34.0,
    "1000x1230": 34.0,
    "2000x600": 32.0,
    "2000x750": 32.0,
    "2000x910": 33.0,
    "2000x1050": 34.0,
    "2000x1230": 34.0,
    "3000x600": 32.0,
    "3000x750": 32.0,
    "3000x910": 33.0,
    "3000x1050": 34.0,
    "3000x1230": 34.0,
    "4000x600": 42.0,
    "4000x750": 42.0,
    "4000x910": 42.0,
    "4000x1050": 43.0,
    "4000x1230": 44.0,
    "5000x600": 42.0,
    "5000x750": 42.0,
    "5000x910": 42.0,
    "5000x1050": 43.0,
    "5000x1230": 44.0,
    "6500x600": 42.0,
    "6500x750": 42.0,
    "6500x910": 42.0,
    "6500x1050": 43.0,
    "6500x1230": 44.0,
    "8000x600": 42.0,
    "8000x750": 42.0,
    "8000x910": 42.0,
    "8000x1050": 43.0,
    "8000x1230": 44.0,
    "10000x600": 52.0,
    "10000x750": 52.0,
    "10000x910": 52.0,
    "10000x1050": 52.0,
    "10000x1230": 52.0,
    "12500x600": 52.0,
    "12500x750": 52.0,
    "12500x910": 52.0,
    "12500x1050": 53.0,
    "12500x1230": 54.0,
    "15500x600": 52.0,
    "15500x750": 52.0,
    "15500x910": 52.0,
    "15500x1050": 53.0,
    "15500x1230": 54.0,
    "20000x600": 52.0,
    "20000x750": 52.0,
    "20000x910": 52.0,
    "20000x1050": 53.0,
    "20000x1230": 54.0,
    "25000x600": 62.0,
    "25000x750": 62.0,
    "25000x910": 62.0,
    "25000x1050": 62.0,
    "25000x1230": 64.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 72.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 72.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 72.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 71.0,
    "60000x1050": 71.0,
    "60000x1230": 72.0,
  },
  "3500x3000": {
    "1000x600": 33.0,
    "1000x750": 34.0,
    "1000x910": 44.0,
    "1000x1050": 45.0,
    "1000x1230": 54.0,
    "2000x600": 33.0,
    "2000x750": 34.0,
    "2000x910": 34.0,
    "2000x1050": 44.0,
    "2000x1230": 45.0,
    "3000x600": 33.0,
    "3000x750": 34.0,
    "3000x910": 34.0,
    "3000x1050": 44.0,
    "3000x1230": 45.0,
    "4000x600": 42.0,
    "4000x750": 43.0,
    "4000x910": 44.0,
    "4000x1050": 44.0,
    "4000x1230": 45.0,
    "5000x600": 42.0,
    "5000x750": 43.0,
    "5000x910": 44.0,
    "5000x1050": 44.0,
    "5000x1230": 45.0,
    "6500x600": 42.0,
    "6500x750": 43.0,
    "6500x910": 44.0,
    "6500x1050": 44.0,
    "6500x1230": 45.0,
    "8000x600": 42.0,
    "8000x750": 43.0,
    "8000x910": 44.0,
    "8000x1050": 44.0,
    "8000x1230": 45.0,
    "10000x600": 52.0,
    "10000x750": 52.0,
    "10000x910": 53.0,
    "10000x1050": 53.0,
    "10000x1230": 54.0,
    "12500x600": 52.0,
    "12500x750": 53.0,
    "12500x910": 54.0,
    "12500x1050": 54.0,
    "12500x1230": 54.0,
    "15500x600": 52.0,
    "15500x750": 53.0,
    "15500x910": 54.0,
    "15500x1050": 54.0,
    "15500x1230": 54.0,
    "20000x600": 52.0,
    "20000x750": 53.0,
    "20000x910": 54.0,
    "20000x1050": 54.0,
    "20000x1230": 54.0,
    "25000x600": 62.0,
    "25000x750": 62.0,
    "25000x910": 63.0,
    "25000x1050": 64.0,
    "25000x1230": 65.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 72.0,
    "30000x1230": 72.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 72.0,
    "35000x1050": 73.0,
    "35000x1230": 73.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 72.0,
    "40000x1050": 73.0,
    "40000x1230": 73.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 72.0,
    "50000x1050": 73.0,
    "50000x1230": 73.0,
    "60000x600": 71.0,
    "60000x750": 71.0,
    "60000x910": 72.0,
    "60000x1050": 73.0,
    "60000x1230": 73.0,
  },
  "3500x3500": {
    "1000x600": 53.0,
    "1000x750": 54.0,
    "1000x910": 54.0,
    "1000x1050": 54.0,
    "1000x1230": 54.0,
    "2000x600": 52.0,
    "2000x750": 53.0,
    "2000x910": 54.0,
    "2000x1050": 54.0,
    "2000x1230": 54.0,
    "3000x600": 52.0,
    "3000x750": 53.0,
    "3000x910": 54.0,
    "3000x1050": 54.0,
    "3000x1230": 54.0,
    "4000x600": 52.0,
    "4000x750": 53.0,
    "4000x910": 54.0,
    "4000x1050": 54.0,
    "4000x1230": 54.0,
    "5000x600": 52.0,
    "5000x750": 53.0,
    "5000x910": 54.0,
    "5000x1050": 54.0,
    "5000x1230": 54.0,
    "6500x600": 52.0,
    "6500x750": 53.0,
    "6500x910": 54.0,
    "6500x1050": 54.0,
    "6500x1230": 54.0,
    "8000x600": 52.0,
    "8000x750": 53.0,
    "8000x910": 54.0,
    "8000x1050": 54.0,
    "8000x1230": 54.0,
    "10000x600": 52.0,
    "10000x750": 53.0,
    "10000x910": 54.0,
    "10000x1050": 54.0,
    "10000x1230": 54.0,
    "12500x600": 54.0,
    "12500x750": 54.0,
    "12500x910": 54.0,
    "12500x1050": 66.0,
    "12500x1230": 66.0,
    "15500x600": 54.0,
    "15500x750": 54.0,
    "15500x910": 54.0,
    "15500x1050": 66.0,
    "15500x1230": 66.0,
    "20000x600": 54.0,
    "20000x750": 54.0,
    "20000x910": 54.0,
    "20000x1050": 66.0,
    "20000x1230": 66.0,
    "25000x600": 63.0,
    "25000x750": 64.0,
    "25000x910": 65.0,
    "25000x1050": 66.0,
    "25000x1230": 66.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 72.0,
    "30000x1050": 73.0,
    "30000x1230": 73.0,
    "35000x600": 71.0,
    "35000x750": 73.0,
    "35000x910": 73.0,
    "35000x1050": 73.0,
    "35000x1230": 73.0,
    "40000x600": 71.0,
    "40000x750": 73.0,
    "40000x910": 73.0,
    "40000x1050": 73.0,
    "40000x1230": 73.0,
    "50000x600": 71.0,
    "50000x750": 73.0,
    "50000x910": 73.0,
    "50000x1050": 73.0,
    "50000x1230": 73.0,
    "60000x600": 71.0,
    "60000x750": 73.0,
    "60000x910": 73.0,
    "60000x1050": 73.0,
    "60000x1230": 73.0,
  },
  "3500x4000": {
    "1000x600": 54.0,
    "1000x750": 54.0,
    "1000x910": 66.0,
    "1000x1050": 66.0,
    "1000x1230": 73.0,
    "2000x600": 54.0,
    "2000x750": 54.0,
    "2000x910": 66.0,
    "2000x1050": 66.0,
    "2000x1230": 73.0,
    "3000x600": 54.0,
    "3000x750": 54.0,
    "3000x910": 66.0,
    "3000x1050": 66.0,
    "3000x1230": 73.0,
    "4000x600": 54.0,
    "4000x750": 54.0,
    "4000x910": 66.0,
    "4000x1050": 66.0,
    "4000x1230": 73.0,
    "5000x600": 54.0,
    "5000x750": 54.0,
    "5000x910": 66.0,
    "5000x1050": 66.0,
    "5000x1230": 73.0,
    "6500x600": 54.0,
    "6500x750": 54.0,
    "6500x910": 66.0,
    "6500x1050": 66.0,
    "6500x1230": 73.0,
    "8000x600": 54.0,
    "8000x750": 54.0,
    "8000x910": 66.0,
    "8000x1050": 66.0,
    "8000x1230": 73.0,
    "10000x600": 54.0,
    "10000x750": 54.0,
    "10000x910": 66.0,
    "10000x1050": 66.0,
    "10000x1230": 73.0,
    "12500x600": 54.0,
    "12500x750": 54.0,
    "12500x910": 66.0,
    "12500x1050": 66.0,
    "12500x1230": 73.0,
    "15500x600": 54.0,
    "15500x750": 54.0,
    "15500x910": 66.0,
    "15500x1050": 66.0,
    "15500x1230": 73.0,
    "20000x600": 54.0,
    "20000x750": 54.0,
    "20000x910": 66.0,
    "20000x1050": 66.0,
    "20000x1230": 73.0,
    "25000x600": 64.0,
    "25000x750": 65.0,
    "25000x910": 66.0,
    "25000x1050": 66.0,
    "25000x1230": 73.0,
    "30000x600": 73.0,
    "30000x750": 73.0,
    "30000x910": 73.0,
    "30000x1050": 73.0,
    "30000x1230": 73.0,
    "35000x600": 73.0,
    "35000x750": 73.0,
    "35000x910": 73.0,
    "35000x1050": 73.0,
    "35000x1230": 83.0,
    "40000x600": 73.0,
    "40000x750": 73.0,
    "40000x910": 73.0,
    "40000x1050": 73.0,
    "40000x1230": 83.0,
    "50000x600": 73.0,
    "50000x750": 73.0,
    "50000x910": 73.0,
    "50000x1050": 73.0,
    "50000x1230": 83.0,
    "60000x600": 73.0,
    "60000x750": 73.0,
    "60000x910": 73.0,
    "60000x1050": 73.0,
    "60000x1230": 83.0,
  },
  "3500x5000": {
    "1000x600": 66.0,
    "1000x750": 73.0,
    "1000x910": 73.0,
    "1000x1050": 83.0,
    "1000x1230": 83.0,
    "2000x600": 66.0,
    "2000x750": 73.0,
    "2000x910": 73.0,
    "2000x1050": 83.0,
    "2000x1230": 83.0,
    "3000x600": 66.0,
    "3000x750": 73.0,
    "3000x910": 73.0,
    "3000x1050": 83.0,
    "3000x1230": 83.0,
    "4000x600": 66.0,
    "4000x750": 73.0,
    "4000x910": 73.0,
    "4000x1050": 83.0,
    "4000x1230": 83.0,
    "5000x600": 66.0,
    "5000x750": 73.0,
    "5000x910": 73.0,
    "5000x1050": 83.0,
    "5000x1230": 83.0,
    "6500x600": 66.0,
    "6500x750": 73.0,
    "6500x910": 73.0,
    "6500x1050": 83.0,
    "6500x1230": 83.0,
    "8000x600": 66.0,
    "8000x750": 73.0,
    "8000x910": 73.0,
    "8000x1050": 83.0,
    "8000x1230": 83.0,
    "10000x600": 66.0,
    "10000x750": 73.0,
    "10000x910": 73.0,
    "10000x1050": 83.0,
    "10000x1230": 83.0,
    "12500x600": 66.0,
    "12500x750": 73.0,
    "12500x910": 73.0,
    "12500x1050": 83.0,
    "12500x1230": 83.0,
    "15500x600": 66.0,
    "15500x750": 73.0,
    "15500x910": 73.0,
    "15500x1050": 83.0,
    "15500x1230": 83.0,
    "20000x600": 66.0,
    "20000x750": 73.0,
    "20000x910": 73.0,
    "20000x1050": 83.0,
    "20000x1230": 83.0,
    "25000x600": 73.0,
    "25000x750": 73.0,
    "25000x910": 73.0,
    "25000x1050": 83.0,
    "25000x1230": 83.0,
    "30000x600": 73.0,
    "30000x750": 73.0,
    "30000x910": 73.0,
    "30000x1050": 83.0,
    "30000x1230": 83.0,
    "35000x600": 73.0,
    "35000x750": 83.0,
    "35000x910": 83.0,
    "35000x1050": 83.0,
    "35000x1230": 93.0,
    "40000x600": 73.0,
    "40000x750": 83.0,
    "40000x910": 83.0,
    "40000x1050": 83.0,
    "40000x1230": 93.0,
    "50000x600": 73.0,
    "50000x750": 83.0,
    "50000x910": 83.0,
    "50000x1050": 83.0,
    "50000x1230": 93.0,
    "60000x600": 73.0,
    "60000x750": 83.0,
    "60000x910": 83.0,
    "60000x1050": 83.0,
    "60000x1230": 93.0,
  },
  "4000x1000": {
    "1000x600": 31.0,
    "1000x750": 31.0,
    "1000x910": 31.0,
    "1000x1050": 32.0,
    "1000x1230": 32.0,
    "2000x600": 31.0,
    "2000x750": 31.0,
    "2000x910": 31.0,
    "2000x1050": 31.0,
    "2000x1230": 32.0,
    "3000x600": 41.0,
    "3000x750": 41.0,
    "3000x910": 41.0,
    "3000x1050": 41.0,
    "3000x1230": 42.0,
    "4000x600": 41.0,
    "4000x750": 41.0,
    "4000x910": 41.0,
    "4000x1050": 41.0,
    "4000x1230": 41.0,
    "5000x600": 41.0,
    "5000x750": 41.0,
    "5000x910": 41.0,
    "5000x1050": 41.0,
    "5000x1230": 42.0,
    "6500x600": 41.0,
    "6500x750": 41.0,
    "6500x910": 41.0,
    "6500x1050": 41.0,
    "6500x1230": 41.0,
    "8000x600": 51.0,
    "8000x750": 51.0,
    "8000x910": 51.0,
    "8000x1050": 51.0,
    "8000x1230": 51.0,
    "10000x600": 51.0,
    "10000x750": 51.0,
    "10000x910": 51.0,
    "10000x1050": 51.0,
    "10000x1230": 51.0,
    "12500x600": 51.0,
    "12500x750": 51.0,
    "12500x910": 51.0,
    "12500x1050": 51.0,
    "12500x1230": 52.0,
    "15500x600": 51.0,
    "15500x750": 51.0,
    "15500x910": 51.0,
    "15500x1050": 51.0,
    "15500x1230": 52.0,
    "20000x600": 61.0,
    "20000x750": 61.0,
    "20000x910": 61.0,
    "20000x1050": 61.0,
    "20000x1230": 62.0,
    "25000x600": 71.0,
    "25000x750": 71.0,
    "25000x910": 71.0,
    "25000x1050": 71.0,
    "25000x1230": 71.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 71.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 91.0,
  },
  "4000x1500": {
    "1000x600": 31.0,
    "1000x750": 32.0,
    "1000x910": 32.0,
    "1000x1050": 32.0,
    "1000x1230": 32.0,
    "2000x600": 31.0,
    "2000x750": 32.0,
    "2000x910": 32.0,
    "2000x1050": 32.0,
    "2000x1230": 32.0,
    "3000x600": 41.0,
    "3000x750": 41.0,
    "3000x910": 42.0,
    "3000x1050": 42.0,
    "3000x1230": 42.0,
    "4000x600": 41.0,
    "4000x750": 41.0,
    "4000x910": 42.0,
    "4000x1050": 42.0,
    "4000x1230": 42.0,
    "5000x600": 41.0,
    "5000x750": 41.0,
    "5000x910": 42.0,
    "5000x1050": 42.0,
    "5000x1230": 42.0,
    "6500x600": 41.0,
    "6500x750": 41.0,
    "6500x910": 42.0,
    "6500x1050": 42.0,
    "6500x1230": 42.0,
    "8000x600": 51.0,
    "8000x750": 51.0,
    "8000x910": 51.0,
    "8000x1050": 51.0,
    "8000x1230": 52.0,
    "10000x600": 51.0,
    "10000x750": 51.0,
    "10000x910": 51.0,
    "10000x1050": 51.0,
    "10000x1230": 52.0,
    "12500x600": 51.0,
    "12500x750": 51.0,
    "12500x910": 52.0,
    "12500x1050": 52.0,
    "12500x1230": 52.0,
    "15500x600": 51.0,
    "15500x750": 51.0,
    "15500x910": 52.0,
    "15500x1050": 52.0,
    "15500x1230": 52.0,
    "20000x600": 61.0,
    "20000x750": 61.0,
    "20000x910": 61.0,
    "20000x1050": 62.0,
    "20000x1230": 62.0,
    "25000x600": 71.0,
    "25000x750": 71.0,
    "25000x910": 71.0,
    "25000x1050": 71.0,
    "25000x1230": 71.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 71.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 91.0,
  },
  "4000x2000": {
    "1000x600": 32.0,
    "1000x750": 32.0,
    "1000x910": 32.0,
    "1000x1050": 33.0,
    "1000x1230": 44.0,
    "2000x600": 32.0,
    "2000x750": 32.0,
    "2000x910": 32.0,
    "2000x1050": 32.0,
    "2000x1230": 33.0,
    "3000x600": 42.0,
    "3000x750": 42.0,
    "3000x910": 42.0,
    "3000x1050": 42.0,
    "3000x1230": 42.0,
    "4000x600": 42.0,
    "4000x750": 42.0,
    "4000x910": 42.0,
    "4000x1050": 42.0,
    "4000x1230": 43.0,
    "5000x600": 42.0,
    "5000x750": 42.0,
    "5000x910": 42.0,
    "5000x1050": 42.0,
    "5000x1230": 43.0,
    "6500x600": 42.0,
    "6500x750": 42.0,
    "6500x910": 42.0,
    "6500x1050": 42.0,
    "6500x1230": 43.0,
    "8000x600": 51.0,
    "8000x750": 52.0,
    "8000x910": 52.0,
    "8000x1050": 52.0,
    "8000x1230": 52.0,
    "10000x600": 51.0,
    "10000x750": 52.0,
    "10000x910": 52.0,
    "10000x1050": 52.0,
    "10000x1230": 52.0,
    "12500x600": 52.0,
    "12500x750": 52.0,
    "12500x910": 52.0,
    "12500x1050": 53.0,
    "12500x1230": 54.0,
    "15500x600": 52.0,
    "15500x750": 52.0,
    "15500x910": 52.0,
    "15500x1050": 52.0,
    "15500x1230": 53.0,
    "20000x600": 61.0,
    "20000x750": 62.0,
    "20000x910": 62.0,
    "20000x1050": 62.0,
    "20000x1230": 63.0,
    "25000x600": 71.0,
    "25000x750": 71.0,
    "25000x910": 71.0,
    "25000x1050": 71.0,
    "25000x1230": 71.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 71.0,
    "40000x1230": 71.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 71.0,
    "50000x1230": 71.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 91.0,
  },
  "4000x2500": {
    "1000x600": 32.0,
    "1000x750": 33.0,
    "1000x910": 34.0,
    "1000x1050": 34.0,
    "1000x1230": 44.0,
    "2000x600": 32.0,
    "2000x750": 33.0,
    "2000x910": 34.0,
    "2000x1050": 34.0,
    "2000x1230": 44.0,
    "3000x600": 42.0,
    "3000x750": 42.0,
    "3000x910": 43.0,
    "3000x1050": 44.0,
    "3000x1230": 44.0,
    "4000x600": 42.0,
    "4000x750": 42.0,
    "4000x910": 43.0,
    "4000x1050": 44.0,
    "4000x1230": 44.0,
    "5000x600": 42.0,
    "5000x750": 42.0,
    "5000x910": 43.0,
    "5000x1050": 44.0,
    "5000x1230": 44.0,
    "6500x600": 42.0,
    "6500x750": 42.0,
    "6500x910": 43.0,
    "6500x1050": 44.0,
    "6500x1230": 44.0,
    "8000x600": 52.0,
    "8000x750": 52.0,
    "8000x910": 52.0,
    "8000x1050": 52.0,
    "8000x1230": 53.0,
    "10000x600": 52.0,
    "10000x750": 52.0,
    "10000x910": 52.0,
    "10000x1050": 52.0,
    "10000x1230": 53.0,
    "12500x600": 52.0,
    "12500x750": 53.0,
    "12500x910": 54.0,
    "12500x1050": 54.0,
    "12500x1230": 54.0,
    "15500x600": 52.0,
    "15500x750": 52.0,
    "15500x910": 53.0,
    "15500x1050": 54.0,
    "15500x1230": 54.0,
    "20000x600": 62.0,
    "20000x750": 62.0,
    "20000x910": 62.0,
    "20000x1050": 63.0,
    "20000x1230": 64.0,
    "25000x600": 71.0,
    "25000x750": 71.0,
    "25000x910": 71.0,
    "25000x1050": 71.0,
    "25000x1230": 71.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 72.0,
    "35000x1230": 73.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 72.0,
    "40000x1230": 73.0,
    "50000x600": 71.0,
    "50000x750": 71.0,
    "50000x910": 71.0,
    "50000x1050": 72.0,
    "50000x1230": 73.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 91.0,
  },
  "4000x3000": {
    "1000x600": 43.0,
    "1000x750": 44.0,
    "1000x910": 45.0,
    "1000x1050": 54.0,
    "1000x1230": 54.0,
    "2000x600": 43.0,
    "2000x750": 44.0,
    "2000x910": 44.0,
    "2000x1050": 45.0,
    "2000x1230": 54.0,
    "3000x600": 43.0,
    "3000x750": 44.0,
    "3000x910": 44.0,
    "3000x1050": 45.0,
    "3000x1230": 54.0,
    "4000x600": 43.0,
    "4000x750": 44.0,
    "4000x910": 44.0,
    "4000x1050": 45.0,
    "4000x1230": 54.0,
    "5000x600": 52.0,
    "5000x750": 52.0,
    "5000x910": 53.0,
    "5000x1050": 54.0,
    "5000x1230": 54.0,
    "6500x600": 43.0,
    "6500x750": 44.0,
    "6500x910": 44.0,
    "6500x1050": 45.0,
    "6500x1230": 54.0,
    "8000x600": 52.0,
    "8000x750": 52.0,
    "8000x910": 53.0,
    "8000x1050": 54.0,
    "8000x1230": 54.0,
    "10000x600": 52.0,
    "10000x750": 52.0,
    "10000x910": 53.0,
    "10000x1050": 54.0,
    "10000x1230": 54.0,
    "12500x600": 53.0,
    "12500x750": 54.0,
    "12500x910": 54.0,
    "12500x1050": 54.0,
    "12500x1230": 66.0,
    "15500x600": 53.0,
    "15500x750": 54.0,
    "15500x910": 54.0,
    "15500x1050": 54.0,
    "15500x1230": 66.0,
    "20000x600": 62.0,
    "20000x750": 63.0,
    "20000x910": 64.0,
    "20000x1050": 65.0,
    "20000x1230": 66.0,
    "25000x600": 71.0,
    "25000x750": 71.0,
    "25000x910": 71.0,
    "25000x1050": 72.0,
    "25000x1230": 73.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 72.0,
    "30000x1230": 73.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 73.0,
    "35000x1050": 73.0,
    "35000x1230": 73.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 73.0,
    "40000x1050": 73.0,
    "40000x1230": 73.0,
    "50000x600": 71.0,
    "50000x750": 72.0,
    "50000x910": 73.0,
    "50000x1050": 73.0,
    "50000x1230": 73.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 91.0,
  },
  "4000x3500": {
    "1000x600": 44.0,
    "1000x750": 45.0,
    "1000x910": 54.0,
    "1000x1050": 54.0,
    "1000x1230": 66.0,
    "2000x600": 53.0,
    "2000x750": 54.0,
    "2000x910": 54.0,
    "2000x1050": 54.0,
    "2000x1230": 65.0,
    "3000x600": 53.0,
    "3000x750": 54.0,
    "3000x910": 54.0,
    "3000x1050": 54.0,
    "3000x1230": 65.0,
    "4000x600": 53.0,
    "4000x750": 54.0,
    "4000x910": 54.0,
    "4000x1050": 54.0,
    "4000x1230": 65.0,
    "5000x600": 53.0,
    "5000x750": 54.0,
    "5000x910": 54.0,
    "5000x1050": 54.0,
    "5000x1230": 65.0,
    "6500x600": 53.0,
    "6500x750": 54.0,
    "6500x910": 54.0,
    "6500x1050": 54.0,
    "6500x1230": 65.0,
    "8000x600": 53.0,
    "8000x750": 54.0,
    "8000x910": 54.0,
    "8000x1050": 54.0,
    "8000x1230": 65.0,
    "10000x600": 53.0,
    "10000x750": 54.0,
    "10000x910": 54.0,
    "10000x1050": 54.0,
    "10000x1230": 65.0,
    "12500x600": 54.0,
    "12500x750": 54.0,
    "12500x910": 66.0,
    "12500x1050": 66.0,
    "12500x1230": 73.0,
    "15500x600": 54.0,
    "15500x750": 54.0,
    "15500x910": 66.0,
    "15500x1050": 66.0,
    "15500x1230": 73.0,
    "20000x600": 63.0,
    "20000x750": 64.0,
    "20000x910": 66.0,
    "20000x1050": 66.0,
    "20000x1230": 73.0,
    "25000x600": 71.0,
    "25000x750": 72.0,
    "25000x910": 73.0,
    "25000x1050": 73.0,
    "25000x1230": 73.0,
    "30000x600": 71.0,
    "30000x750": 72.0,
    "30000x910": 73.0,
    "30000x1050": 73.0,
    "30000x1230": 73.0,
    "35000x600": 72.0,
    "35000x750": 73.0,
    "35000x910": 73.0,
    "35000x1050": 73.0,
    "35000x1230": 73.0,
    "40000x600": 72.0,
    "40000x750": 73.0,
    "40000x910": 73.0,
    "40000x1050": 73.0,
    "40000x1230": 73.0,
    "50000x600": 73.0,
    "50000x750": 73.0,
    "50000x910": 73.0,
    "50000x1050": 83.0,
    "50000x1230": 83.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 92.0,
    "60000x1230": 92.0,
  },
  "4000x4000": {
    "1000x600": 65.0,
    "1000x750": 66.0,
    "1000x910": 66.0,
    "1000x1050": 73.0,
    "1000x1230": 73.0,
    "2000x600": 65.0,
    "2000x750": 66.0,
    "2000x910": 66.0,
    "2000x1050": 73.0,
    "2000x1230": 73.0,
    "3000x600": 65.0,
    "3000x750": 66.0,
    "3000x910": 66.0,
    "3000x1050": 73.0,
    "3000x1230": 73.0,
    "4000x600": 65.0,
    "4000x750": 66.0,
    "4000x910": 66.0,
    "4000x1050": 73.0,
    "4000x1230": 73.0,
    "5000x600": 65.0,
    "5000x750": 66.0,
    "5000x910": 66.0,
    "5000x1050": 73.0,
    "5000x1230": 73.0,
    "6500x600": 65.0,
    "6500x750": 66.0,
    "6500x910": 66.0,
    "6500x1050": 73.0,
    "6500x1230": 73.0,
    "8000x600": 65.0,
    "8000x750": 66.0,
    "8000x910": 66.0,
    "8000x1050": 73.0,
    "8000x1230": 73.0,
    "10000x600": 65.0,
    "10000x750": 66.0,
    "10000x910": 66.0,
    "10000x1050": 73.0,
    "10000x1230": 73.0,
    "12500x600": 65.0,
    "12500x750": 66.0,
    "12500x910": 66.0,
    "12500x1050": 73.0,
    "12500x1230": 73.0,
    "15500x600": 65.0,
    "15500x750": 66.0,
    "15500x910": 66.0,
    "15500x1050": 73.0,
    "15500x1230": 73.0,
    "20000x600": 65.0,
    "20000x750": 66.0,
    "20000x910": 66.0,
    "20000x1050": 73.0,
    "20000x1230": 73.0,
    "25000x600": 73.0,
    "25000x750": 73.0,
    "25000x910": 73.0,
    "25000x1050": 73.0,
    "25000x1230": 73.0,
    "30000x600": 73.0,
    "30000x750": 73.0,
    "30000x910": 73.0,
    "30000x1050": 73.0,
    "30000x1230": 73.0,
    "35000x600": 73.0,
    "35000x750": 73.0,
    "35000x910": 73.0,
    "35000x1050": 83.0,
    "35000x1230": 83.0,
    "40000x600": 73.0,
    "40000x750": 73.0,
    "40000x910": 73.0,
    "40000x1050": 83.0,
    "40000x1230": 83.0,
    "50000x600": 73.0,
    "50000x750": 73.0,
    "50000x910": 73.0,
    "50000x1050": 83.0,
    "50000x1230": 83.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 92.0,
    "60000x1050": 92.0,
    "60000x1230": 92.0,
  },
  "4000x5000": {
    "1000x600": 73.0,
    "1000x750": 73.0,
    "1000x910": 83.0,
    "1000x1050": 83.0,
    "1000x1230": 93.0,
    "2000x600": 73.0,
    "2000x750": 73.0,
    "2000x910": 83.0,
    "2000x1050": 83.0,
    "2000x1230": 93.0,
    "3000x600": 73.0,
    "3000x750": 73.0,
    "3000x910": 83.0,
    "3000x1050": 83.0,
    "3000x1230": 93.0,
    "4000x600": 73.0,
    "4000x750": 73.0,
    "4000x910": 83.0,
    "4000x1050": 83.0,
    "4000x1230": 93.0,
    "5000x600": 73.0,
    "5000x750": 73.0,
    "5000x910": 83.0,
    "5000x1050": 83.0,
    "5000x1230": 93.0,
    "6500x600": 73.0,
    "6500x750": 73.0,
    "6500x910": 83.0,
    "6500x1050": 83.0,
    "6500x1230": 93.0,
    "8000x600": 73.0,
    "8000x750": 73.0,
    "8000x910": 83.0,
    "8000x1050": 83.0,
    "8000x1230": 93.0,
    "10000x600": 73.0,
    "10000x750": 73.0,
    "10000x910": 83.0,
    "10000x1050": 83.0,
    "10000x1230": 93.0,
    "12500x600": 73.0,
    "12500x750": 73.0,
    "12500x910": 83.0,
    "12500x1050": 83.0,
    "12500x1230": 93.0,
    "15500x600": 73.0,
    "15500x750": 73.0,
    "15500x910": 83.0,
    "15500x1050": 83.0,
    "15500x1230": 93.0,
    "20000x600": 73.0,
    "20000x750": 73.0,
    "20000x910": 83.0,
    "20000x1050": 83.0,
    "20000x1230": 93.0,
    "25000x600": 73.0,
    "25000x750": 83.0,
    "25000x910": 83.0,
    "25000x1050": 93.0,
    "25000x1230": 93.0,
    "30000x600": 73.0,
    "30000x750": 83.0,
    "30000x910": 83.0,
    "30000x1050": 93.0,
    "30000x1230": 93.0,
    "35000x600": 73.0,
    "35000x750": 83.0,
    "35000x910": 83.0,
    "35000x1050": 93.0,
    "35000x1230": 93.0,
    "40000x600": 73.0,
    "40000x750": 83.0,
    "40000x910": 83.0,
    "40000x1050": 93.0,
    "40000x1230": 93.0,
    "50000x600": 73.0,
    "50000x750": 83.0,
    "50000x910": 83.0,
    "50000x1050": 93.0,
    "50000x1230": 93.0,
    "60000x600": 92.0,
    "60000x750": 92.0,
    "60000x910": 93.0,
    "60000x1050": 93.0,
    "60000x1230": 93.0,
  },
  "5000x1000": {
    "1000x600": 31.0,
    "1000x750": 31.0,
    "1000x910": 32.0,
    "1000x1050": 32.0,
    "1000x1230": 32.0,
    "2000x600": 41.0,
    "2000x750": 41.0,
    "2000x910": 41.0,
    "2000x1050": 42.0,
    "2000x1230": 42.0,
    "3000x600": 41.0,
    "3000x750": 41.0,
    "3000x910": 41.0,
    "3000x1050": 42.0,
    "3000x1230": 42.0,
    "4000x600": 41.0,
    "4000x750": 41.0,
    "4000x910": 41.0,
    "4000x1050": 42.0,
    "4000x1230": 42.0,
    "5000x600": 41.0,
    "5000x750": 41.0,
    "5000x910": 41.0,
    "5000x1050": 42.0,
    "5000x1230": 42.0,
    "6500x600": 51.0,
    "6500x750": 51.0,
    "6500x910": 51.0,
    "6500x1050": 51.0,
    "6500x1230": 51.0,
    "8000x600": 51.0,
    "8000x750": 51.0,
    "8000x910": 51.0,
    "8000x1050": 51.0,
    "8000x1230": 51.0,
    "10000x600": 51.0,
    "10000x750": 51.0,
    "10000x910": 51.0,
    "10000x1050": 51.0,
    "10000x1230": 51.0,
    "12500x600": 51.0,
    "12500x750": 51.0,
    "12500x910": 52.0,
    "12500x1050": 52.0,
    "12500x1230": 52.0,
    "15500x600": 51.0,
    "15500x750": 51.0,
    "15500x910": 52.0,
    "15500x1050": 52.0,
    "15500x1230": 52.0,
    "20000x600": 71.0,
    "20000x750": 71.0,
    "20000x910": 71.0,
    "20000x1050": 71.0,
    "20000x1230": 71.0,
    "25000x600": 71.0,
    "25000x750": 71.0,
    "25000x910": 71.0,
    "25000x1050": 71.0,
    "25000x1230": 71.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 71.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 72.0,
    "40000x1230": 73.0,
    "50000x600": 91.0,
    "50000x750": 91.0,
    "50000x910": 91.0,
    "50000x1050": 91.0,
    "50000x1230": 91.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 91.0,
  },
  "5000x1500": {
    "1000x600": 32.0,
    "1000x750": 32.0,
    "1000x910": 32.0,
    "1000x1050": 32.0,
    "1000x1230": 33.0,
    "2000x600": 41.0,
    "2000x750": 42.0,
    "2000x910": 42.0,
    "2000x1050": 42.0,
    "2000x1230": 42.0,
    "3000x600": 41.0,
    "3000x750": 42.0,
    "3000x910": 42.0,
    "3000x1050": 42.0,
    "3000x1230": 42.0,
    "4000x600": 41.0,
    "4000x750": 42.0,
    "4000x910": 42.0,
    "4000x1050": 42.0,
    "4000x1230": 42.0,
    "5000x600": 41.0,
    "5000x750": 42.0,
    "5000x910": 42.0,
    "5000x1050": 42.0,
    "5000x1230": 42.0,
    "6500x600": 51.0,
    "6500x750": 51.0,
    "6500x910": 52.0,
    "6500x1050": 52.0,
    "6500x1230": 52.0,
    "8000x600": 51.0,
    "8000x750": 51.0,
    "8000x910": 52.0,
    "8000x1050": 52.0,
    "8000x1230": 52.0,
    "10000x600": 51.0,
    "10000x750": 51.0,
    "10000x910": 52.0,
    "10000x1050": 52.0,
    "10000x1230": 52.0,
    "12500x600": 51.0,
    "12500x750": 52.0,
    "12500x910": 52.0,
    "12500x1050": 52.0,
    "12500x1230": 54.0,
    "15500x600": 51.0,
    "15500x750": 52.0,
    "15500x910": 52.0,
    "15500x1050": 52.0,
    "15500x1230": 54.0,
    "20000x600": 71.0,
    "20000x750": 71.0,
    "20000x910": 71.0,
    "20000x1050": 71.0,
    "20000x1230": 71.0,
    "25000x600": 71.0,
    "25000x750": 71.0,
    "25000x910": 71.0,
    "25000x1050": 71.0,
    "25000x1230": 71.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 71.0,
    "30000x1230": 71.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 71.0,
    "35000x1230": 72.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 72.0,
    "40000x1230": 73.0,
    "50000x600": 91.0,
    "50000x750": 91.0,
    "50000x910": 91.0,
    "50000x1050": 91.0,
    "50000x1230": 91.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 91.0,
  },
  "5000x2000": {
    "1000x600": 32.0,
    "1000x750": 32.0,
    "1000x910": 33.0,
    "1000x1050": 34.0,
    "1000x1230": 44.0,
    "2000x600": 42.0,
    "2000x750": 42.0,
    "2000x910": 42.0,
    "2000x1050": 43.0,
    "2000x1230": 44.0,
    "3000x600": 42.0,
    "3000x750": 42.0,
    "3000x910": 42.0,
    "3000x1050": 43.0,
    "3000x1230": 44.0,
    "4000x600": 42.0,
    "4000x750": 42.0,
    "4000x910": 42.0,
    "4000x1050": 43.0,
    "4000x1230": 44.0,
    "5000x600": 42.0,
    "5000x750": 42.0,
    "5000x910": 42.0,
    "5000x1050": 43.0,
    "5000x1230": 44.0,
    "6500x600": 52.0,
    "6500x750": 52.0,
    "6500x910": 52.0,
    "6500x1050": 52.0,
    "6500x1230": 52.0,
    "8000x600": 52.0,
    "8000x750": 52.0,
    "8000x910": 52.0,
    "8000x1050": 52.0,
    "8000x1230": 52.0,
    "10000x600": 52.0,
    "10000x750": 52.0,
    "10000x910": 52.0,
    "10000x1050": 52.0,
    "10000x1230": 52.0,
    "12500x600": 52.0,
    "12500x750": 52.0,
    "12500x910": 53.0,
    "12500x1050": 54.0,
    "12500x1230": 54.0,
    "15500x600": 52.0,
    "15500x750": 52.0,
    "15500x910": 53.0,
    "15500x1050": 54.0,
    "15500x1230": 54.0,
    "20000x600": 71.0,
    "20000x750": 71.0,
    "20000x910": 71.0,
    "20000x1050": 71.0,
    "20000x1230": 71.0,
    "25000x600": 71.0,
    "25000x750": 71.0,
    "25000x910": 71.0,
    "25000x1050": 72.0,
    "25000x1230": 73.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 71.0,
    "30000x1050": 72.0,
    "30000x1230": 73.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 71.0,
    "35000x1050": 72.0,
    "35000x1230": 73.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 71.0,
    "40000x1050": 72.0,
    "40000x1230": 73.0,
    "50000x600": 91.0,
    "50000x750": 91.0,
    "50000x910": 91.0,
    "50000x1050": 91.0,
    "50000x1230": 91.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 91.0,
  },
  "5000x2500": {
    "1000x600": 33.0,
    "1000x750": 34.0,
    "1000x910": 44.0,
    "1000x1050": 54.0,
    "1000x1230": 54.0,
    "2000x600": 42.0,
    "2000x750": 43.0,
    "2000x910": 44.0,
    "2000x1050": 44.0,
    "2000x1230": 54.0,
    "3000x600": 42.0,
    "3000x750": 43.0,
    "3000x910": 44.0,
    "3000x1050": 44.0,
    "3000x1230": 54.0,
    "4000x600": 42.0,
    "4000x750": 43.0,
    "4000x910": 44.0,
    "4000x1050": 44.0,
    "4000x1230": 54.0,
    "5000x600": 42.0,
    "5000x750": 43.0,
    "5000x910": 44.0,
    "5000x1050": 44.0,
    "5000x1230": 54.0,
    "6500x600": 52.0,
    "6500x750": 52.0,
    "6500x910": 53.0,
    "6500x1050": 53.0,
    "6500x1230": 54.0,
    "8000x600": 52.0,
    "8000x750": 52.0,
    "8000x910": 53.0,
    "8000x1050": 53.0,
    "8000x1230": 54.0,
    "10000x600": 52.0,
    "10000x750": 52.0,
    "10000x910": 53.0,
    "10000x1050": 53.0,
    "10000x1230": 54.0,
    "12500x600": 52.0,
    "12500x750": 53.0,
    "12500x910": 54.0,
    "12500x1050": 54.0,
    "12500x1230": 66.0,
    "15500x600": 52.0,
    "15500x750": 53.0,
    "15500x910": 54.0,
    "15500x1050": 54.0,
    "15500x1230": 66.0,
    "20000x600": 71.0,
    "20000x750": 71.0,
    "20000x910": 71.0,
    "20000x1050": 72.0,
    "20000x1230": 73.0,
    "25000x600": 71.0,
    "25000x750": 71.0,
    "25000x910": 73.0,
    "25000x1050": 73.0,
    "25000x1230": 73.0,
    "30000x600": 71.0,
    "30000x750": 71.0,
    "30000x910": 73.0,
    "30000x1050": 73.0,
    "30000x1230": 73.0,
    "35000x600": 71.0,
    "35000x750": 71.0,
    "35000x910": 73.0,
    "35000x1050": 73.0,
    "35000x1230": 73.0,
    "40000x600": 71.0,
    "40000x750": 71.0,
    "40000x910": 73.0,
    "40000x1050": 73.0,
    "40000x1230": 73.0,
    "50000x600": 91.0,
    "50000x750": 91.0,
    "50000x910": 91.0,
    "50000x1050": 91.0,
    "50000x1230": 91.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 91.0,
  },
  "5000x3000": {
    "1000x600": 44.0,
    "1000x750": 45.0,
    "1000x910": 54.0,
    "1000x1050": 54.0,
    "1000x1230": 66.0,
    "2000x600": 52.0,
    "2000x750": 53.0,
    "2000x910": 54.0,
    "2000x1050": 54.0,
    "2000x1230": 54.0,
    "3000x600": 52.0,
    "3000x750": 53.0,
    "3000x910": 54.0,
    "3000x1050": 54.0,
    "3000x1230": 54.0,
    "4000x600": 52.0,
    "4000x750": 53.0,
    "4000x910": 54.0,
    "4000x1050": 54.0,
    "4000x1230": 54.0,
    "5000x600": 52.0,
    "5000x750": 53.0,
    "5000x910": 54.0,
    "5000x1050": 54.0,
    "5000x1230": 54.0,
    "6500x600": 52.0,
    "6500x750": 53.0,
    "6500x910": 54.0,
    "6500x1050": 54.0,
    "6500x1230": 54.0,
    "8000x600": 52.0,
    "8000x750": 53.0,
    "8000x910": 54.0,
    "8000x1050": 54.0,
    "8000x1230": 54.0,
    "10000x600": 52.0,
    "10000x750": 53.0,
    "10000x910": 54.0,
    "10000x1050": 54.0,
    "10000x1230": 54.0,
    "12500x600": 54.0,
    "12500x750": 54.0,
    "12500x910": 54.0,
    "12500x1050": 66.0,
    "12500x1230": 73.0,
    "15500x600": 54.0,
    "15500x750": 54.0,
    "15500x910": 54.0,
    "15500x1050": 66.0,
    "15500x1230": 73.0,
    "20000x600": 71.0,
    "20000x750": 72.0,
    "20000x910": 73.0,
    "20000x1050": 73.0,
    "20000x1230": 73.0,
    "25000x600": 72.0,
    "25000x750": 73.0,
    "25000x910": 73.0,
    "25000x1050": 73.0,
    "25000x1230": 73.0,
    "30000x600": 72.0,
    "30000x750": 73.0,
    "30000x910": 73.0,
    "30000x1050": 73.0,
    "30000x1230": 73.0,
    "35000x600": 71.0,
    "35000x750": 73.0,
    "35000x910": 73.0,
    "35000x1050": 73.0,
    "35000x1230": 83.0,
    "40000x600": 71.0,
    "40000x750": 73.0,
    "40000x910": 73.0,
    "40000x1050": 73.0,
    "40000x1230": 83.0,
    "50000x600": 91.0,
    "50000x750": 91.0,
    "50000x910": 91.0,
    "50000x1050": 91.0,
    "50000x1230": 92.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 91.0,
    "60000x1230": 92.0,
  },
  "5000x3500": {
    "1000x600": 54.0,
    "1000x750": 54.0,
    "1000x910": 65.0,
    "1000x1050": 66.0,
    "1000x1230": 66.0,
    "2000x600": 54.0,
    "2000x750": 54.0,
    "2000x910": 54.0,
    "2000x1050": 66.0,
    "2000x1230": 66.0,
    "3000x600": 54.0,
    "3000x750": 54.0,
    "3000x910": 54.0,
    "3000x1050": 66.0,
    "3000x1230": 66.0,
    "4000x600": 54.0,
    "4000x750": 54.0,
    "4000x910": 54.0,
    "4000x1050": 66.0,
    "4000x1230": 66.0,
    "5000x600": 54.0,
    "5000x750": 54.0,
    "5000x910": 54.0,
    "5000x1050": 66.0,
    "5000x1230": 66.0,
    "6500x600": 54.0,
    "6500x750": 54.0,
    "6500x910": 54.0,
    "6500x1050": 66.0,
    "6500x1230": 66.0,
    "8000x600": 54.0,
    "8000x750": 54.0,
    "8000x910": 54.0,
    "8000x1050": 66.0,
    "8000x1230": 66.0,
    "10000x600": 54.0,
    "10000x750": 54.0,
    "10000x910": 54.0,
    "10000x1050": 66.0,
    "10000x1230": 66.0,
    "12500x600": 54.0,
    "12500x750": 66.0,
    "12500x910": 66.0,
    "12500x1050": 73.0,
    "12500x1230": 73.0,
    "15500x600": 54.0,
    "15500x750": 66.0,
    "15500x910": 66.0,
    "15500x1050": 73.0,
    "15500x1230": 73.0,
    "20000x600": 72.0,
    "20000x750": 73.0,
    "20000x910": 73.0,
    "20000x1050": 73.0,
    "20000x1230": 73.0,
    "25000x600": 72.0,
    "25000x750": 73.0,
    "25000x910": 73.0,
    "25000x1050": 73.0,
    "25000x1230": 73.0,
    "30000x600": 72.0,
    "30000x750": 73.0,
    "30000x910": 73.0,
    "30000x1050": 73.0,
    "30000x1230": 73.0,
    "35000x600": 73.0,
    "35000x750": 73.0,
    "35000x910": 73.0,
    "35000x1050": 83.0,
    "35000x1230": 83.0,
    "40000x600": 73.0,
    "40000x750": 73.0,
    "40000x910": 73.0,
    "40000x1050": 83.0,
    "40000x1230": 83.0,
    "50000x600": 91.0,
    "50000x750": 91.0,
    "50000x910": 91.0,
    "50000x1050": 92.0,
    "50000x1230": 92.0,
    "60000x600": 91.0,
    "60000x750": 91.0,
    "60000x910": 91.0,
    "60000x1050": 92.0,
    "60000x1230": 92.0,
  },
  "5000x4000": {
    "1000x600": 73.0,
    "1000x750": 73.0,
    "1000x910": 73.0,
    "1000x1050": 83.0,
    "1000x1230": 83.0,
    "2000x600": 73.0,
    "2000x750": 73.0,
    "2000x910": 73.0,
    "2000x1050": 83.0,
    "2000x1230": 83.0,
    "3000x600": 73.0,
    "3000x750": 73.0,
    "3000x910": 73.0,
    "3000x1050": 83.0,
    "3000x1230": 83.0,
    "4000x600": 73.0,
    "4000x750": 73.0,
    "4000x910": 73.0,
    "4000x1050": 83.0,
    "4000x1230": 83.0,
    "5000x600": 73.0,
    "5000x750": 73.0,
    "5000x910": 73.0,
    "5000x1050": 83.0,
    "5000x1230": 83.0,
    "6500x600": 73.0,
    "6500x750": 73.0,
    "6500x910": 73.0,
    "6500x1050": 83.0,
    "6500x1230": 83.0,
    "8000x600": 73.0,
    "8000x750": 73.0,
    "8000x910": 73.0,
    "8000x1050": 83.0,
    "8000x1230": 83.0,
    "10000x600": 73.0,
    "10000x750": 73.0,
    "10000x910": 73.0,
    "10000x1050": 83.0,
    "10000x1230": 83.0,
    "12500x600": 73.0,
    "12500x750": 73.0,
    "12500x910": 73.0,
    "12500x1050": 83.0,
    "12500x1230": 83.0,
    "15500x600": 73.0,
    "15500x750": 73.0,
    "15500x910": 73.0,
    "15500x1050": 83.0,
    "15500x1230": 83.0,
    "20000x600": 73.0,
    "20000x750": 73.0,
    "20000x910": 73.0,
    "20000x1050": 83.0,
    "20000x1230": 83.0,
    "25000x600": 73.0,
    "25000x750": 73.0,
    "25000x910": 83.0,
    "25000x1050": 83.0,
    "25000x1230": 83.0,
    "30000x600": 73.0,
    "30000x750": 73.0,
    "30000x910": 83.0,
    "30000x1050": 83.0,
    "30000x1230": 83.0,
    "35000x600": 73.0,
    "35000x750": 73.0,
    "35000x910": 83.0,
    "35000x1050": 83.0,
    "35000x1230": 93.0,
    "40000x600": 73.0,
    "40000x750": 73.0,
    "40000x910": 83.0,
    "40000x1050": 83.0,
    "40000x1230": 93.0,
    "50000x600": 91.0,
    "50000x750": 92.0,
    "50000x910": 92.0,
    "50000x1050": 92.0,
    "50000x1230": 93.0,
    "60000x600": 91.0,
    "60000x750": 92.0,
    "60000x910": 92.0,
    "60000x1050": 92.0,
    "60000x1230": 93.0,
  },
  "5000x5000": {
    "1000x600": 73.0,
    "1000x750": 83.0,
    "1000x910": 83.0,
    "1000x1050": 93.0,
    "1000x1230": 93.0,
    "2000x600": 73.0,
    "2000x750": 83.0,
    "2000x910": 83.0,
    "2000x1050": 93.0,
    "2000x1230": 93.0,
    "3000x600": 73.0,
    "3000x750": 83.0,
    "3000x910": 83.0,
    "3000x1050": 93.0,
    "3000x1230": 93.0,
    "4000x600": 73.0,
    "4000x750": 83.0,
    "4000x910": 83.0,
    "4000x1050": 93.0,
    "4000x1230": 93.0,
    "5000x600": 73.0,
    "5000x750": 83.0,
    "5000x910": 83.0,
    "5000x1050": 93.0,
    "5000x1230": 93.0,
    "6500x600": 73.0,
    "6500x750": 83.0,
    "6500x910": 83.0,
    "6500x1050": 93.0,
    "6500x1230": 93.0,
    "8000x600": 73.0,
    "8000x750": 83.0,
    "8000x910": 83.0,
    "8000x1050": 93.0,
    "8000x1230": 93.0,
    "10000x600": 73.0,
    "10000x750": 83.0,
    "10000x910": 83.0,
    "10000x1050": 93.0,
    "10000x1230": 93.0,
    "12500x600": 73.0,
    "12500x750": 83.0,
    "12500x910": 83.0,
    "12500x1050": 93.0,
    "12500x1230": 93.0,
    "15500x600": 73.0,
    "15500x750": 83.0,
    "15500x910": 83.0,
    "15500x1050": 93.0,
    "15500x1230": 93.0,
    "20000x600": 73.0,
    "20000x750": 83.0,
    "20000x910": 83.0,
    "20000x1050": 93.0,
    "20000x1230": 93.0,
    "25000x600": 83.0,
    "25000x750": 83.0,
    "25000x910": 93.0,
    "25000x1050": 93.0,
    "25000x1230": 93.0,
    "30000x600": 83.0,
    "30000x750": 83.0,
    "30000x910": 93.0,
    "30000x1050": 93.0,
    "30000x1230": 93.0,
    "35000x600": 83.0,
    "35000x750": 83.0,
    "35000x910": 93.0,
    "35000x1050": 93.0,
    "35000x1230": 103.0,
    "40000x600": 83.0,
    "40000x750": 83.0,
    "40000x910": 93.0,
    "40000x1050": 93.0,
    "40000x1230": 103.0,
    "50000x600": 92.0,
    "50000x750": 93.0,
    "50000x910": 93.0,
    "50000x1050": 93.0,
    "50000x1230": 103.0,
    "60000x600": 92.0,
    "60000x750": 93.0,
    "60000x910": 93.0,
    "60000x1050": 93.0,
    "60000x1230": 103.0,
  },
};
