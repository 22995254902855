export function calcCombinationSign(
  nw: number,
  sideStrutsCenterSpace: number,
  signCalcOuterWidth: number,
  innerHeight: number
): number | string {
  if (!nw || !sideStrutsCenterSpace || !signCalcOuterWidth || !innerHeight) {
    return 999;
  }
  const firstKeys = Object.keys(MATRIX);
  const closestOuterWidth = findClosestKey(signCalcOuterWidth, firstKeys, 0);
  const closestInnerHeight = findClosestKey(innerHeight, firstKeys, 1);

  const selectedKey = `${closestOuterWidth}x${closestInnerHeight}`;

  if (!MATRIX[selectedKey]) {
    return 999;
  }

  const nwSpacingKeys = Object.keys(MATRIX[selectedKey]);
  const closestNW = findClosestKey(nw, nwSpacingKeys, 0);
  const closestSpacing = findClosestKey(
    sideStrutsCenterSpace,
    nwSpacingKeys,
    1
  );

  const weightSpacingKey = `${closestNW}x${closestSpacing}`;

  if (MATRIX[selectedKey][weightSpacingKey] !== undefined) {
    return MATRIX[selectedKey][weightSpacingKey];
  } else {
    return 999;
  }
}

function findClosestKey(value: number, keys: string[], index: number): string {
  const numericKeys = keys
    .map((key) => parseInt(key.split("x")[index]))
    .filter((key) => key >= value);
  if (numericKeys.length === 0) return "No matching key found.";
  const closestKey = Math.min(...numericKeys);
  return closestKey.toString();
}

// 枠組部材の組み合わせ符号選択表
// 1階層目のkeyは外寸幅mm x 内寸高さmm
// 2階層目のkeyは重量kg x 支柱中心間隔mm
interface MatrixData {
  [key: string]: {
    [key: string]: number;
  };
}

const MATRIX: MatrixData = {
  "1000x1000": {
    "1000x750": 1,
    "1000x950": 1,
    "1000x1050": 1,
    "1000x1250": 1,
    "2000x750": 1,
    "2000x950": 1,
    "2000x1050": 1,
    "2000x1250": 1,
    "3000x750": 1,
    "3000x950": 1,
    "3000x1050": 1,
    "3000x1250": 1,
    "4000x750": 1,
    "4000x950": 1,
    "4000x1050": 1,
    "4000x1250": 1,
    "5000x750": 1,
    "5000x950": 1,
    "5000x1050": 1,
    "5000x1250": 1,
    "7500x750": 10,
    "7500x950": 10,
    "7500x1050": 10,
    "7500x1250": 10,
    "10000x750": 10,
    "10000x950": 10,
    "10000x1050": 10,
    "10000x1250": 10,
  },
  "1000x1500": {
    "1000x750": 1,
    "1000x950": 1,
    "1000x1050": 1,
    "1000x1250": 1,
    "2000x750": 1,
    "2000x950": 1,
    "2000x1050": 1,
    "2000x1250": 1,
    "3000x750": 1,
    "3000x950": 1,
    "3000x1050": 1,
    "3000x1250": 1,
    "4000x750": 1,
    "4000x950": 1,
    "4000x1050": 1,
    "4000x1250": 1,
    "5000x750": 1,
    "5000x950": 1,
    "5000x1050": 1,
    "5000x1250": 1,
    "7500x750": 10,
    "7500x950": 10,
    "7500x1050": 10,
    "7500x1250": 10,
    "10000x750": 10,
    "10000x950": 10,
    "10000x1050": 10,
    "10000x1250": 10,
  },
  "1000x2000": {
    "1000x750": 3,
    "1000x950": 3,
    "1000x1050": 3,
    "1000x1250": 3,
    "2000x750": 3,
    "2000x950": 3,
    "2000x1050": 3,
    "2000x1250": 3,
    "3000x750": 3,
    "3000x950": 3,
    "3000x1050": 3,
    "3000x1250": 3,
    "4000x750": 10,
    "4000x950": 11,
    "4000x1050": 11,
    "4000x1250": 11,
    "5000x750": 10,
    "5000x950": 11,
    "5000x1050": 11,
    "5000x1250": 11,
    "7500x750": 10,
    "7500x950": 10,
    "7500x1050": 10,
    "7500x1250": 11,
    "10000x750": 10,
    "10000x950": 10,
    "10000x1050": 10,
    "10000x1250": 11,
  },
  "1000x2500": {
    "1000x750": 10,
    "1000x950": 10,
    "1000x1050": 10,
    "1000x1250": 10,
    "2000x750": 10,
    "2000x950": 10,
    "2000x1050": 10,
    "2000x1250": 10,
    "3000x750": 10,
    "3000x950": 10,
    "3000x1050": 10,
    "3000x1250": 10,
    "4000x750": 11,
    "4000x950": 11,
    "4000x1050": 11,
    "4000x1250": 11,
    "5000x750": 11,
    "5000x950": 11,
    "5000x1050": 11,
    "5000x1250": 11,
    "7500x750": 11,
    "7500x950": 12,
    "7500x1050": 12,
    "7500x1250": 13,
    "10000x750": 11,
    "10000x950": 12,
    "10000x1050": 12,
    "10000x1250": 13,
  },
  "1000x3000": {
    "1000x750": 21,
    "1000x950": 21,
    "1000x1050": 21,
    "1000x1250": 21,
    "2000x750": 21,
    "2000x950": 21,
    "2000x1050": 21,
    "2000x1250": 21,
    "3000x750": 21,
    "3000x950": 21,
    "3000x1050": 21,
    "3000x1250": 21,
    "4000x750": 21,
    "4000x950": 21,
    "4000x1050": 21,
    "4000x1250": 21,
    "5000x750": 21,
    "5000x950": 21,
    "5000x1050": 21,
    "5000x1250": 21,
    "7500x750": 13,
    "7500x950": 13,
    "7500x1050": 13,
    "7500x1250": 20,
    "10000x750": 13,
    "10000x950": 13,
    "10000x1050": 13,
    "10000x1250": 20,
  },
  "1000x3300": {
    "1000x750": 22,
    "1000x950": 22,
    "1000x1050": 22,
    "1000x1250": 22,
    "2000x750": 22,
    "2000x950": 22,
    "2000x1050": 22,
    "2000x1250": 22,
    "3000x750": 22,
    "3000x950": 22,
    "3000x1050": 22,
    "3000x1250": 22,
    "4000x750": 22,
    "4000x950": 22,
    "4000x1050": 22,
    "4000x1250": 22,
    "5000x750": 22,
    "5000x950": 22,
    "5000x1050": 22,
    "5000x1250": 22,
    "7500x750": 21,
    "7500x950": 21,
    "7500x1050": 21,
    "7500x1250": 22,
    "10000x750": 21,
    "10000x950": 21,
    "10000x1050": 21,
    "10000x1250": 22,
  },
  "1500x1000": {
    "1000x750": 1,
    "1000x950": 1,
    "1000x1050": 1,
    "1000x1250": 1,
    "2000x750": 1,
    "2000x950": 1,
    "2000x1050": 1,
    "2000x1250": 1,
    "3000x750": 1,
    "3000x950": 1,
    "3000x1050": 1,
    "3000x1250": 1,
    "4000x750": 1,
    "4000x950": 1,
    "4000x1050": 1,
    "4000x1250": 1,
    "5000x750": 1,
    "5000x950": 1,
    "5000x1050": 1,
    "5000x1250": 1,
    "7500x750": 10,
    "7500x950": 10,
    "7500x1050": 10,
    "7500x1250": 10,
    "10000x750": 10,
    "10000x950": 10,
    "10000x1050": 10,
    "10000x1250": 10,
  },
  "1500x1500": {
    "1000x750": 3,
    "1000x950": 3,
    "1000x1050": 3,
    "1000x1250": 4,
    "2000x750": 3,
    "2000x950": 3,
    "2000x1050": 3,
    "2000x1250": 4,
    "3000x750": 3,
    "3000x950": 3,
    "3000x1050": 3,
    "3000x1250": 4,
    "4000x750": 3,
    "4000x950": 3,
    "4000x1050": 3,
    "4000x1250": 4,
    "5000x750": 3,
    "5000x950": 3,
    "5000x1050": 3,
    "5000x1250": 4,
    "7500x750": 10,
    "7500x950": 10,
    "7500x1050": 10,
    "7500x1250": 10,
    "10000x750": 10,
    "10000x950": 10,
    "10000x1050": 10,
    "10000x1250": 10,
  },
  "1500x2000": {
    "1000x750": 4,
    "1000x950": 10,
    "1000x1050": 10,
    "1000x1250": 11,
    "2000x750": 4,
    "2000x950": 10,
    "2000x1050": 10,
    "2000x1250": 11,
    "3000x750": 11,
    "3000x950": 11,
    "3000x1050": 11,
    "3000x1250": 11,
    "4000x750": 11,
    "4000x950": 11,
    "4000x1050": 11,
    "4000x1250": 12,
    "5000x750": 11,
    "5000x950": 11,
    "5000x1050": 11,
    "5000x1250": 12,
    "7500x750": 10,
    "7500x950": 10,
    "7500x1050": 11,
    "7500x1250": 12,
    "10000x750": 10,
    "10000x950": 10,
    "10000x1050": 11,
    "10000x1250": 12,
  },
  "1500x2500": {
    "1000x750": 12,
    "1000x950": 13,
    "1000x1050": 20,
    "1000x1250": 21,
    "2000x750": 12,
    "2000x950": 13,
    "2000x1050": 20,
    "2000x1250": 21,
    "3000x750": 12,
    "3000x950": 13,
    "3000x1050": 20,
    "3000x1250": 21,
    "4000x750": 12,
    "4000x950": 13,
    "4000x1050": 20,
    "4000x1250": 21,
    "5000x750": 12,
    "5000x950": 13,
    "5000x1050": 20,
    "5000x1250": 21,
    "7500x750": 12,
    "7500x950": 13,
    "7500x1050": 20,
    "7500x1250": 21,
    "10000x750": 12,
    "10000x950": 13,
    "10000x1050": 20,
    "10000x1250": 21,
  },
  "1500x3000": {
    "1000x750": 21,
    "1000x950": 21,
    "1000x1050": 21,
    "1000x1250": 22,
    "2000x750": 21,
    "2000x950": 21,
    "2000x1050": 21,
    "2000x1250": 21,
    "3000x750": 21,
    "3000x950": 21,
    "3000x1050": 21,
    "3000x1250": 21,
    "4000x750": 21,
    "4000x950": 21,
    "4000x1050": 21,
    "4000x1250": 21,
    "5000x750": 21,
    "5000x950": 22,
    "5000x1050": 22,
    "5000x1250": 22,
    "7500x750": 13,
    "7500x950": 20,
    "7500x1050": 21,
    "7500x1250": 22,
    "10000x750": 13,
    "10000x950": 20,
    "10000x1050": 21,
    "10000x1250": 22,
  },
  "1500x3300": {
    "1000x750": 22,
    "1000x950": 30,
    "1000x1050": 31,
    "1000x1250": 32,
    "2000x750": 22,
    "2000x950": 30,
    "2000x1050": 31,
    "2000x1250": 32,
    "3000x750": 22,
    "3000x950": 30,
    "3000x1050": 31,
    "3000x1250": 32,
    "4000x750": 22,
    "4000x950": 30,
    "4000x1050": 31,
    "4000x1250": 32,
    "5000x750": 22,
    "5000x950": 30,
    "5000x1050": 31,
    "5000x1250": 32,
    "7500x750": 21,
    "7500x950": 21,
    "7500x1050": 22,
    "7500x1250": 22,
    "10000x750": 21,
    "10000x950": 21,
    "10000x1050": 22,
    "10000x1250": 22,
  },
  "2000x1000": {
    "1000x750": 1,
    "1000x950": 1,
    "1000x1050": 1,
    "1000x1250": 1,
    "2000x750": 1,
    "2000x950": 1,
    "2000x1050": 1,
    "2000x1250": 1,
    "3000x750": 1,
    "3000x950": 1,
    "3000x1050": 1,
    "3000x1250": 1,
    "4000x750": 1,
    "4000x950": 1,
    "4000x1050": 1,
    "4000x1250": 1,
    "5000x750": 1,
    "5000x950": 1,
    "5000x1050": 1,
    "5000x1250": 1,
    "7500x750": 10,
    "7500x950": 10,
    "7500x1050": 10,
    "7500x1250": 10,
    "10000x750": 10,
    "10000x950": 10,
    "10000x1050": 10,
    "10000x1250": 10,
  },
  "2000x1500": {
    "1000x750": 3,
    "1000x950": 4,
    "1000x1050": 4,
    "1000x1250": 10,
    "2000x750": 3,
    "2000x950": 4,
    "2000x1050": 4,
    "2000x1250": 10,
    "3000x750": 3,
    "3000x950": 4,
    "3000x1050": 4,
    "3000x1250": 10,
    "4000x750": 3,
    "4000x950": 4,
    "4000x1050": 4,
    "4000x1250": 10,
    "5000x750": 3,
    "5000x950": 4,
    "5000x1050": 4,
    "5000x1250": 10,
    "7500x750": 10,
    "7500x950": 10,
    "7500x1050": 10,
    "7500x1250": 10,
    "10000x750": 10,
    "10000x950": 10,
    "10000x1050": 10,
    "10000x1250": 10,
  },
  "2000x2000": {
    "1000x750": 10,
    "1000x950": 11,
    "1000x1050": 12,
    "1000x1250": 13,
    "2000x750": 10,
    "2000x950": 11,
    "2000x1050": 12,
    "2000x1250": 13,
    "3000x750": 10,
    "3000x950": 11,
    "3000x1050": 12,
    "3000x1250": 13,
    "4000x750": 10,
    "4000x950": 11,
    "4000x1050": 12,
    "4000x1250": 13,
    "5000x750": 10,
    "5000x950": 11,
    "5000x1050": 12,
    "5000x1250": 13,
    "7500x750": 10,
    "7500x950": 11,
    "7500x1050": 12,
    "7500x1250": 13,
    "10000x750": 10,
    "10000x950": 11,
    "10000x1050": 12,
    "10000x1250": 13,
  },
  "2000x2500": {
    "1000x750": 20,
    "1000x950": 21,
    "1000x1050": 21,
    "1000x1250": 22,
    "2000x750": 20,
    "2000x950": 21,
    "2000x1050": 21,
    "2000x1250": 22,
    "3000x750": 20,
    "3000x950": 21,
    "3000x1050": 21,
    "3000x1250": 22,
    "4000x750": 20,
    "4000x950": 21,
    "4000x1050": 21,
    "4000x1250": 22,
    "5000x750": 20,
    "5000x950": 21,
    "5000x1050": 21,
    "5000x1250": 22,
    "7500x750": 20,
    "7500x950": 21,
    "7500x1050": 21,
    "7500x1250": 22,
    "10000x750": 20,
    "10000x950": 21,
    "10000x1050": 21,
    "10000x1250": 22,
  },
  "2000x3000": {
    "1000x750": 30,
    "1000x950": 31,
    "1000x1050": 32,
    "1000x1250": 32,
    "2000x750": 30,
    "2000x950": 31,
    "2000x1050": 32,
    "2000x1250": 32,
    "3000x750": 30,
    "3000x950": 31,
    "3000x1050": 32,
    "3000x1250": 32,
    "4000x750": 30,
    "4000x950": 31,
    "4000x1050": 32,
    "4000x1250": 32,
    "5000x750": 30,
    "5000x950": 31,
    "5000x1050": 32,
    "5000x1250": 32,
    "7500x750": 30,
    "7500x950": 31,
    "7500x1050": 31,
    "7500x1250": 32,
    "10000x750": 30,
    "10000x950": 31,
    "10000x1050": 31,
    "10000x1250": 32,
  },
  "2000x3300": {
    "1000x750": 31,
    "1000x950": 32,
    "1000x1050": 32,
    "1000x1250": 33,
    "2000x750": 31,
    "2000x950": 32,
    "2000x1050": 32,
    "2000x1250": 33,
    "3000x750": 31,
    "3000x950": 32,
    "3000x1050": 32,
    "3000x1250": 33,
    "4000x750": 31,
    "4000x950": 32,
    "4000x1050": 32,
    "4000x1250": 33,
    "5000x750": 31,
    "5000x950": 32,
    "5000x1050": 32,
    "5000x1250": 33,
    "7500x750": 31,
    "7500x950": 32,
    "7500x1050": 33,
    "7500x1250": 33,
    "10000x750": 31,
    "10000x950": 32,
    "10000x1050": 33,
    "10000x1250": 33,
  },
  "2500x1000": {
    "1000x750": 1,
    "1000x950": 3,
    "1000x1050": 3,
    "1000x1250": 4,
    "2000x750": 1,
    "2000x950": 3,
    "2000x1050": 3,
    "2000x1250": 4,
    "3000x750": 1,
    "3000x950": 3,
    "3000x1050": 3,
    "3000x1250": 4,
    "4000x750": 1,
    "4000x950": 3,
    "4000x1050": 3,
    "4000x1250": 4,
    "5000x750": 1,
    "5000x950": 3,
    "5000x1050": 3,
    "5000x1250": 4,
    "7500x750": 11,
    "7500x950": 11,
    "7500x1050": 11,
    "7500x1250": 11,
    "10000x750": 11,
    "10000x950": 11,
    "10000x1050": 11,
    "10000x1250": 11,
  },
  "2500x1500": {
    "1000x750": 10,
    "1000x950": 11,
    "1000x1050": 11,
    "1000x1250": 12,
    "2000x750": 10,
    "2000x950": 11,
    "2000x1050": 11,
    "2000x1250": 12,
    "3000x750": 10,
    "3000x950": 11,
    "3000x1050": 11,
    "3000x1250": 12,
    "4000x750": 10,
    "4000x950": 11,
    "4000x1050": 11,
    "4000x1250": 12,
    "5000x750": 10,
    "5000x950": 11,
    "5000x1050": 11,
    "5000x1250": 12,
    "7500x750": 11,
    "7500x950": 11,
    "7500x1050": 11,
    "7500x1250": 13,
    "10000x750": 11,
    "10000x950": 11,
    "10000x1050": 11,
    "10000x1250": 13,
  },
  "2500x2000": {
    "1000x750": 12,
    "1000x950": 13,
    "1000x1050": 20,
    "1000x1250": 21,
    "2000x750": 12,
    "2000x950": 13,
    "2000x1050": 20,
    "2000x1250": 21,
    "3000x750": 12,
    "3000x950": 13,
    "3000x1050": 20,
    "3000x1250": 21,
    "4000x750": 12,
    "4000x950": 13,
    "4000x1050": 20,
    "4000x1250": 21,
    "5000x750": 12,
    "5000x950": 13,
    "5000x1050": 20,
    "5000x1250": 21,
    "7500x750": 12,
    "7500x950": 13,
    "7500x1050": 20,
    "7500x1250": 21,
    "10000x750": 12,
    "10000x950": 13,
    "10000x1050": 20,
    "10000x1250": 21,
  },
  "2500x2500": {
    "1000x750": 21,
    "1000x950": 22,
    "1000x1050": 30,
    "1000x1250": 31,
    "2000x750": 21,
    "2000x950": 22,
    "2000x1050": 30,
    "2000x1250": 31,
    "3000x750": 21,
    "3000x950": 22,
    "3000x1050": 30,
    "3000x1250": 31,
    "4000x750": 21,
    "4000x950": 22,
    "4000x1050": 30,
    "4000x1250": 31,
    "5000x750": 21,
    "5000x950": 22,
    "5000x1050": 30,
    "5000x1250": 31,
    "7500x750": 21,
    "7500x950": 22,
    "7500x1050": 30,
    "7500x1250": 31,
    "10000x750": 21,
    "10000x950": 22,
    "10000x1050": 30,
    "10000x1250": 31,
  },
  "2500x3000": {
    "1000x750": 31,
    "1000x950": 32,
    "1000x1050": 32,
    "1000x1250": 33,
    "2000x750": 31,
    "2000x950": 32,
    "2000x1050": 32,
    "2000x1250": 33,
    "3000x750": 31,
    "3000x950": 32,
    "3000x1050": 32,
    "3000x1250": 33,
    "4000x750": 31,
    "4000x950": 32,
    "4000x1050": 32,
    "4000x1250": 33,
    "5000x750": 31,
    "5000x950": 32,
    "5000x1050": 32,
    "5000x1250": 33,
    "7500x750": 31,
    "7500x950": 32,
    "7500x1050": 33,
    "7500x1250": 33,
    "10000x750": 31,
    "10000x950": 32,
    "10000x1050": 33,
    "10000x1250": 33,
  },
  "2500x3300": {
    "1000x750": 31,
    "1000x950": 32,
    "1000x1050": 33,
    "1000x1250": 33,
    "2000x750": 31,
    "2000x950": 32,
    "2000x1050": 33,
    "2000x1250": 33,
    "3000x750": 31,
    "3000x950": 32,
    "3000x1050": 33,
    "3000x1250": 33,
    "4000x750": 31,
    "4000x950": 32,
    "4000x1050": 33,
    "4000x1250": 33,
    "5000x750": 31,
    "5000x950": 32,
    "5000x1050": 33,
    "5000x1250": 33,
    "7500x750": 32,
    "7500x950": 33,
    "7500x1050": 33,
    "7500x1250": 34,
    "10000x750": 32,
    "10000x950": 33,
    "10000x1050": 33,
    "10000x1250": 34,
  },
  "3000x1000": {
    "1000x750": 3,
    "1000x950": 3,
    "1000x1050": 4,
    "1000x1250": 4,
    "2000x750": 3,
    "2000x950": 3,
    "2000x1050": 4,
    "2000x1250": 4,
    "3000x750": 3,
    "3000x950": 3,
    "3000x1050": 4,
    "3000x1250": 4,
    "4000x750": 3,
    "4000x950": 3,
    "4000x1050": 4,
    "4000x1250": 4,
    "5000x750": 3,
    "5000x950": 3,
    "5000x1050": 4,
    "5000x1250": 4,
    "7500x750": 11,
    "7500x950": 11,
    "7500x1050": 11,
    "7500x1250": 11,
    "10000x750": 11,
    "10000x950": 11,
    "10000x1050": 11,
    "10000x1250": 11,
  },
  "3000x1500": {
    "1000x750": 10,
    "1000x950": 11,
    "1000x1050": 12,
    "1000x1250": 13,
    "2000x750": 10,
    "2000x950": 11,
    "2000x1050": 12,
    "2000x1250": 13,
    "3000x750": 10,
    "3000x950": 11,
    "3000x1050": 12,
    "3000x1250": 13,
    "4000x750": 10,
    "4000x950": 11,
    "4000x1050": 12,
    "4000x1250": 13,
    "5000x750": 10,
    "5000x950": 11,
    "5000x1050": 12,
    "5000x1250": 13,
    "7500x750": 11,
    "7500x950": 11,
    "7500x1050": 12,
    "7500x1250": 13,
    "10000x750": 11,
    "10000x950": 11,
    "10000x1050": 12,
    "10000x1250": 13,
  },
  "3000x2000": {
    "1000x750": 13,
    "1000x950": 20,
    "1000x1050": 21,
    "1000x1250": 22,
    "2000x750": 13,
    "2000x950": 20,
    "2000x1050": 21,
    "2000x1250": 22,
    "3000x750": 13,
    "3000x950": 20,
    "3000x1050": 21,
    "3000x1250": 22,
    "4000x750": 13,
    "4000x950": 20,
    "4000x1050": 21,
    "4000x1250": 22,
    "5000x750": 13,
    "5000x950": 20,
    "5000x1050": 21,
    "5000x1250": 22,
    "7500x750": 20,
    "7500x950": 21,
    "7500x1050": 21,
    "7500x1250": 22,
    "10000x750": 20,
    "10000x950": 21,
    "10000x1050": 21,
    "10000x1250": 22,
  },
  "3000x2500": {
    "1000x750": 22,
    "1000x950": 30,
    "1000x1050": 31,
    "1000x1250": 32,
    "2000x750": 22,
    "2000x950": 30,
    "2000x1050": 31,
    "2000x1250": 32,
    "3000x750": 22,
    "3000x950": 30,
    "3000x1050": 31,
    "3000x1250": 32,
    "4000x750": 22,
    "4000x950": 30,
    "4000x1050": 31,
    "4000x1250": 32,
    "5000x750": 22,
    "5000x950": 30,
    "5000x1050": 31,
    "5000x1250": 32,
    "7500x750": 30,
    "7500x950": 31,
    "7500x1050": 32,
    "7500x1250": 33,
    "10000x750": 30,
    "10000x950": 31,
    "10000x1050": 32,
    "10000x1250": 33,
  },
  "3000x3000": {
    "1000x750": 31,
    "1000x950": 32,
    "1000x1050": 33,
    "1000x1250": 33,
    "2000x750": 31,
    "2000x950": 32,
    "2000x1050": 33,
    "2000x1250": 33,
    "3000x750": 31,
    "3000x950": 32,
    "3000x1050": 33,
    "3000x1250": 33,
    "4000x750": 31,
    "4000x950": 32,
    "4000x1050": 33,
    "4000x1250": 33,
    "5000x750": 31,
    "5000x950": 32,
    "5000x1050": 33,
    "5000x1250": 33,
    "7500x750": 32,
    "7500x950": 33,
    "7500x1050": 34,
    "7500x1250": 34,
    "10000x750": 32,
    "10000x950": 33,
    "10000x1050": 34,
    "10000x1250": 34,
  },
  "3000x3300": {
    "1000x750": 32,
    "1000x950": 33,
    "1000x1050": 33,
    "1000x1250": 34,
    "2000x750": 32,
    "2000x950": 33,
    "2000x1050": 33,
    "2000x1250": 34,
    "3000x750": 32,
    "3000x950": 33,
    "3000x1050": 33,
    "3000x1250": 34,
    "4000x750": 32,
    "4000x950": 33,
    "4000x1050": 33,
    "4000x1250": 34,
    "5000x750": 32,
    "5000x950": 33,
    "5000x1050": 33,
    "5000x1250": 34,
    "7500x750": 33,
    "7500x950": 34,
    "7500x1050": 34,
    "7500x1250": 35,
    "10000x750": 33,
    "10000x950": 34,
    "10000x1050": 34,
    "10000x1250": 35,
  },
  "3300x1000": {
    "1000x750": 10,
    "1000x950": 10,
    "1000x1050": 11,
    "1000x1250": 11,
    "2000x750": 10,
    "2000x950": 10,
    "2000x1050": 11,
    "2000x1250": 11,
    "3000x750": 10,
    "3000x950": 10,
    "3000x1050": 11,
    "3000x1250": 11,
    "4000x750": 10,
    "4000x950": 10,
    "4000x1050": 11,
    "4000x1250": 11,
    "5000x750": 10,
    "5000x950": 10,
    "5000x1050": 11,
    "5000x1250": 11,
    "7500x750": 11,
    "7500x950": 11,
    "7500x1050": 11,
    "7500x1250": 11,
    "10000x750": 11,
    "10000x950": 11,
    "10000x1050": 11,
    "10000x1250": 11,
  },
  "3300x1500": {
    "1000x750": 11,
    "1000x950": 12,
    "1000x1050": 12,
    "1000x1250": 13,
    "2000x750": 11,
    "2000x950": 12,
    "2000x1050": 12,
    "2000x1250": 13,
    "3000x750": 11,
    "3000x950": 12,
    "3000x1050": 12,
    "3000x1250": 13,
    "4000x750": 11,
    "4000x950": 12,
    "4000x1050": 12,
    "4000x1250": 13,
    "5000x750": 11,
    "5000x950": 12,
    "5000x1050": 12,
    "5000x1250": 13,
    "7500x750": 12,
    "7500x950": 13,
    "7500x1050": 13,
    "7500x1250": 20,
    "10000x750": 12,
    "10000x950": 13,
    "10000x1050": 13,
    "10000x1250": 20,
  },
  "3300x2000": {
    "1000x750": 20,
    "1000x950": 21,
    "1000x1050": 22,
    "1000x1250": 22,
    "2000x750": 20,
    "2000x950": 21,
    "2000x1050": 22,
    "2000x1250": 22,
    "3000x750": 20,
    "3000x950": 21,
    "3000x1050": 22,
    "3000x1250": 22,
    "4000x750": 20,
    "4000x950": 21,
    "4000x1050": 22,
    "4000x1250": 22,
    "5000x750": 20,
    "5000x950": 21,
    "5000x1050": 22,
    "5000x1250": 22,
    "7500x750": 20,
    "7500x950": 21,
    "7500x1050": 22,
    "7500x1250": 22,
    "10000x750": 22,
    "10000x950": 24,
    "10000x1050": 24,
    "10000x1250": 31,
  },
  "3300x2500": {
    "1000x750": 30,
    "1000x950": 31,
    "1000x1050": 32,
    "1000x1250": 32,
    "2000x750": 30,
    "2000x950": 31,
    "2000x1050": 32,
    "2000x1250": 32,
    "3000x750": 30,
    "3000x950": 31,
    "3000x1050": 32,
    "3000x1250": 32,
    "4000x750": 30,
    "4000x950": 31,
    "4000x1050": 32,
    "4000x1250": 32,
    "5000x750": 30,
    "5000x950": 31,
    "5000x1050": 32,
    "5000x1250": 32,
    "7500x750": 31,
    "7500x950": 32,
    "7500x1050": 33,
    "7500x1250": 33,
    "10000x750": 31,
    "10000x950": 32,
    "10000x1050": 33,
    "10000x1250": 33,
  },
  "3300x3000": {
    "1000x750": 32,
    "1000x950": 33,
    "1000x1050": 34,
    "1000x1250": 34,
    "2000x750": 32,
    "2000x950": 33,
    "2000x1050": 34,
    "2000x1250": 34,
    "3000x750": 32,
    "3000x950": 33,
    "3000x1050": 34,
    "3000x1250": 34,
    "4000x750": 32,
    "4000x950": 33,
    "4000x1050": 34,
    "4000x1250": 34,
    "5000x750": 32,
    "5000x950": 33,
    "5000x1050": 34,
    "5000x1250": 34,
    "7500x750": 33,
    "7500x950": 33,
    "7500x1050": 34,
    "7500x1250": 35,
    "10000x750": 33,
    "10000x950": 33,
    "10000x1050": 34,
    "10000x1250": 35,
  },
  "3300x3300": {
    "1000x750": 32,
    "1000x950": 33,
    "1000x1050": 34,
    "1000x1250": 35,
    "2000x750": 32,
    "2000x950": 33,
    "2000x1050": 34,
    "2000x1250": 35,
    "3000x750": 32,
    "3000x950": 33,
    "3000x1050": 34,
    "3000x1250": 35,
    "4000x750": 32,
    "4000x950": 33,
    "4000x1050": 34,
    "4000x1250": 35,
    "5000x750": 32,
    "5000x950": 33,
    "5000x1050": 34,
    "5000x1250": 35,
    "7500x750": 33,
    "7500x950": 34,
    "7500x1050": 35,
    "7500x1250": 35,
    "10000x750": 33,
    "10000x950": 34,
    "10000x1050": 35,
    "10000x1250": 35,
  },
};
