import { useReducer, Reducer, useEffect, useMemo } from "react";
import _ from "lodash";
import { API } from "aws-amplify";

import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import { useAlerts } from "contexts/alerts";

export interface GraphQLVariables {
  [key: string]: any;
}

export interface GraphQLInput {
  id: string;
  [key: string]: any;
}

interface QueryProps {
  object: string;
  variables?: GraphQLVariables | undefined;
}

interface QueryState {
  data: any;
  loading: boolean;
  updating: boolean;
  nextToken: string | null;
  error: string | boolean;
  refetch: () => null;
  loadNext: () => null;
  update: () => null;
  remove: () => null;
  set: () => null;
}

interface QueryAction {
  type: string;
  payload?: any;
}

interface GraphQLResult {
  data: {
    [key: string]: object[];
  };
  errors: any;
}

export interface LoadOptions {
  progress: boolean;
}

const initialState = {
  data: undefined,
  loading: false,
  updating: false,
  error: false,
  nextToken: null,
  refetch: () => null,
  loadNext: () => null,
  update: () => null,
  remove: () => null,
  set: () => null,
};

const reducer: Reducer<QueryState, QueryAction> = (
  state: QueryState,
  action: QueryAction
) => {
  switch (action.type) {
    case "LOAD_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "DATA_LOADED":
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case "SET_DATA":
      return {
        ...state,
        data: action.payload.data,
      };
    case "SET_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_DATA":
      return {
        ...state,
        updating: true,
        error: false,
      };
    case "DATA_UPDATED":
      return {
        ...state,
        updating: false,
        error: false,
        data: action.payload.data,
      };
    case "DELETE_DATA":
      return {
        ...state,
        updating: true,
        error: false,
      };
    case "DATA_DELETED":
      return {
        ...state,
        updating: false,
        error: false,
        data: {},
      };
    default:
      return initialState;
  }
};

function cap(str: string) {
  return `${str[0].toUpperCase()}${str.substring(1)}`;
}

const loadOptions = {
  progress: true,
};

//TODO: Learn proper way to use useReducer
export default function useData({ object, variables }: QueryProps) {
  const [result, dispatch] = useReducer(reducer, initialState);
  const { addAlert } = useAlerts();

  // triggers data fetch when initialized
  useEffect(() => {
    getData(variables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (
    gqlVariables: GraphQLVariables | undefined,
    options: LoadOptions | undefined = loadOptions
  ) => {
    try {
      const query = `get${cap(object)}`;
      if (!!options.progress) {
        dispatch({ type: "LOAD_DATA" });
      }
      const res = (await API.graphql({
        query: _.get(queries, query),
        variables: gqlVariables,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as GraphQLResult;

      dispatch({ type: "DATA_LOADED", payload: { data: res.data[query] } });
    } catch (err: any) {
      if (typeof err === "string") {
        addAlert({ message: err, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        addAlert({ message: err.message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.message });
      }
      if (err.errors) {
        addAlert({ message: err.errors[0].message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.errors });
      }
      throw err;
    }
  };

  const update = async (input: GraphQLInput | undefined) => {
    try {
      const mutation = `update${cap(object)}`;
      dispatch({ type: "UPDATE_DATA" });
      const res = (await API.graphql({
        query: _.get(mutations, mutation),
        variables: {
          input,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as GraphQLResult;

      dispatch({ type: "DATA_UPDATED", payload: { data: res.data[mutation] } });
      addAlert({ message: "更新されました", severity: "success" });
    } catch (err: any) {
      if (typeof err === "string") {
        addAlert({ message: err, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        addAlert({ message: err.message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.message });
      }
      if (err.errors) {
        addAlert({ message: err.errors[0].message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.errors[0].message });
      }
      throw err;
    }
  };

  const remove = async (input: GraphQLInput | undefined) => {
    try {
      const mutation = `delete${cap(object)}`;
      dispatch({ type: "DELETE_DATA" });
      const res = (await API.graphql({
        query: _.get(mutations, mutation),
        variables: {
          input,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as GraphQLResult;

      dispatch({ type: "DATA_DELETED", payload: { data: res.data[mutation] } });
      addAlert({ message: "削除されました", severity: "success" });
    } catch (err: any) {
      if (typeof err === "string") {
        addAlert({ message: err, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        addAlert({ message: err.message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.message });
      }
      if (err.errors) {
        addAlert({ message: err.errors[0].message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.errors });
      }
      throw err;
    }
  };

  const set = (data: any) => {
    dispatch({ type: "SET_DATA", payload: { data } });
  };

  // refetch the same dataset
  // can refetch with new variables
  const refetch = async (
    customVariables: GraphQLVariables | undefined = undefined,
    options?: LoadOptions
  ) => {
    await getData(customVariables || variables, options);
  };

  // useMemo to prevent force re-render components that are reading these values
  const values = useMemo(
    () => ({ ...result, refetch, update, remove, set }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result, refetch, update]
  );

  return values;
}
