import { Element } from "API";

export const getStyle = (e: Element) => {
  if (!e) return "";
  if (e.overwriteValue && e.overwriteValue !== e.value) {
    return "w-full text-yellow-500";
  }
  if (e.expr) {
    return "w-full text-green-500";
  }
  return "";
};
