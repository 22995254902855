import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { Tooltip } from "@mui/material";

import { MainListItems, SecondaryListItems } from "./NavItems";

import { useProject } from "contexts/project";

interface NavigationProps {
  open?: boolean;
  toggleDrawer: () => void;
  drawerWidth: number;
}

export function ProjectNavs({
  open,
  toggleDrawer,
  drawerWidth,
}: NavigationProps) {
  const { project } = useProject();
  const [openDrawer, setOpenDrawer] = useState(true);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  return (
    <Box
      component="nav"
      sx={{
        width: openDrawer ? { sm: drawerWidth } : 0,
        flexShrink: { sm: 0 },
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="persistent"
        anchor="left"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open={openDrawer}
      >
        <Toolbar
          variant="dense"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={project?.name} placement="right">
            <Typography
              variant="subtitle2"
              color="inherit"
              noWrap
              sx={{ textOverflow: "fade", cursor: "help" }}
            >
              {project?.name}
            </Typography>
          </Tooltip>
          <IconButton onClick={handleDrawerClose} sx={{ marginRight: "-10%" }}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <List component="nav">
          <MainListItems toggleDrawer={toggleDrawer} />
          <Divider sx={{ my: 1 }} />
          <SecondaryListItems toggleDrawer={toggleDrawer} />
        </List>
      </Drawer>
      <Toolbar
        variant="dense"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          visibility: openDrawer ? "hidden" : "visible",
        }}
      >
        <IconButton
          onClick={handleDrawerOpen}
          sx={{
            visibility: openDrawer ? "hidden" : "visible",
            zIndex: 10000,
            color: "white",
            alignItems: "center",
          }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer
        variant="temporary"
        open={open}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Toolbar
          variant="dense"
          sx={{
            display: "flex",
            alignItems: "center",
            px: [1],
          }}
        >
          <Typography variant="subtitle2" color="inherit" noWrap>
            {project?.name}
          </Typography>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <MainListItems toggleDrawer={toggleDrawer} />
          <Divider sx={{ my: 1 }} />
          <SecondaryListItems toggleDrawer={toggleDrawer} />
        </List>
      </Drawer>
    </Box>
  );
}
